import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import ModalContainer from "../../../Components/Modal/Modal";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import NoImage from "../../../Assets/no-image.jpg";
import axios from "axios";
import { useAlert } from "react-alert";
import { Pagination } from "../../../Components/Pagination/Pagination";

const BoxQuantityAddition = (props) => {
  const alert = useAlert();
  const [sessionIds, setSessionIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedSession, setSelectionSession] = useState("0");
  const [sessionData, setSessionData] = useState({});
  const [boxName, setBoxName] = useState("");
  const [serialCode, setSerialCode] = useState("");
  const [serialData, setSerialData] = useState("");
  const [serialMsg, setSerialMsg] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState("");
  const [serialerror, setSerialError] = useState("");

  const [boxpage, setBoxPage] = useState(1);
  const [totalBoxPage, setTotalBoxPage] = useState(0);

  useEffect(() => {
    const productsArray = Object.entries(box1Data?.products || {}).map(
      ([product_id, product]) => ({
        product_id,
        details: "Box",
        box_id: box1Data?.box_info?.id,
        ...product,
      })
    );
    setTotalBoxPage(Math.ceil(productsArray.length / 6));
  }, [box1Data]);

  let boxPageData = Object.entries(box1Data?.products || {}).map(
    ([product_id, product]) => ({
      product_id,
      details: "Box",
      box_id: box1Data?.box_info?.id,
      ...product,
    })
  );
  boxPageData = boxPageData.slice((boxpage - 1) * 6, boxpage * 6);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          setBox1Data(data.data);
          setError("");
          setCounter(0);
        } else {
          setError(data.msg);
          setBox1Data({});
          setCounter(0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const submitSerialCode = useCallback(
    async (serial_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "merge_bbsc_to_box",
            token,
            box_no: boxName,
            serial_code,
            session_id: Number(selectedSession),
          }
        );
        if (data.status === 1) {
          if (data.data) {
            setSerialData(data?.data);
            setCounter((prev) => prev + 1);
            setSerialMsg(data.msg);
            alert.success("Serial Code Merged Successfully");
            setSerialCode("");
          }
          setSerialError("");
          if (data.msg && !data.data) {
            alert.error(data.msg);
            setSerialCode("");
          }
        } else {
          alert.error(data.msg);
          setSerialCode("");
        }
        if (boxName === "") {
          alert.error("Box not found");
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, boxName, alert, selectedSession]
  );

  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {
      if (name === "boxName") {
        if (boxName !== "") {
          fetchBoxDetails(boxName);
        } else {
          setBox1Data({});
          setError("");
          setCounter(0);
        }
      } else {
        if (serialCode !== "") {
          if (selectedSession === "0" || selectedSession === "") {
            alert.error("Please select the session ID.");
          } else {
            submitSerialCode(serialCode);
          }
        } else {
          setSerialData("");
          setSerialMsg("");
          setSerialError("");
        }
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else {
      setSerialCode(value);
    }
  };

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const fetchSessionIds = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_all_list_sessions",
          token,
        }
      );
      if (data.status === 1) {
        setSessionIds(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert]);

  useEffect(() => {
    fetchSessionIds();
  }, [fetchSessionIds, props.sessionCounter]);

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(props.selectedSession, selectedOption)) {
      setSelectedOption(props.selectedSession);
      setSelectionSession(props.selectedSession.id);
    }
  }, [props.selectedSession, selectedOption]);

  const fetchSessionRecord = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_session_record",
          token,
          id: Number(selectedSession),
        }
      );
      if (data.status === 1) {
        setSessionData(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert, selectedSession]);

  const handleSession = (option) => {
    setSelectionSession(option.id);
    props.setSelectedSession(option);
  };

  useEffect(() => {
    if (selectedSession !== "0") {
      fetchSessionRecord();
    }
  }, [selectedSession, fetchSessionRecord]);

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Select Session Id`}
              options={
                sessionIds &&
                sessionIds.map((option) => ({
                  value: option.name,
                  label: option.name,
                  id: option.id,
                }))
              }
              className="select"
              id="courierName"
              value={
                Object.keys(selectedOption).length === 0 ? null : selectedOption
              }
              styles={customStylesSKU}
              onChange={(selectedOption) => handleSession(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.UserName}
              label="Created By"
              placeholder="Select Session Id"
              id="created_by"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.createdOn}
              label="Start Date and Time"
              placeholder="Select Session Id"
              id="datetime"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={boxName}
              label="Scan Box Id"
              placeholder="Scan Box Id"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              field={"boxName"}
              error={boxName !== "" && error}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Location",
                value: box1Data?.box_info?.Location
                  ? box1Data?.box_info?.Location
                  : "Scan Box First",
              },
              {
                key: "Sub Location",
                value: box1Data?.box_info?.SubLocation
                  ? box1Data?.box_info?.SubLocation
                  : "Scan Box First",
              },
              {
                key: "Current Quantity",
                value:
                  box1Data?.box_info?.current_qty ||
                  box1Data?.box_info?.current_qty === 0
                    ? box1Data?.box_info?.current_qty
                    : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value:
                  box1Data?.box_info?.qty || box1Data?.box_info?.qty === 0
                    ? box1Data?.box_info?.qty
                    : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box1Data?.box_info?.SubLocation
                  ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box1Data?.products &&
              Object?.keys(box1Data?.products)?.length > 0 && (
                <DataTable
                  header={["SKU Code", "Qty", "Action"]}
                  boxboxtransfer={boxPageData}
                  onData={handleModal}
                  onDetail={setModalDetail}
                  onHeadingIndex={setModalBoxId}
                  assign_Picklist_id={setModalProductId}
                />
              )}
          </div>
        </div>
        {box1Data?.products && Object?.keys(box1Data?.products)?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`Boxproduct-${boxpage}`}
              page={boxpage}
              isLoading={false}
              totalPage={totalBoxPage}
              onPageChange={(page) => setBoxPage(page)}
            />
          </div>
        )}
      </div>
      <div className="summary-box mt-3">
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={serialCode}
              label="Add Serial Code"
              placeholder="Add Serial Code"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              field={"serialCode"}
              error={serialCode !== "" && serialerror}
            />
          </div>

          <div className="text-center mb-3">
            <img
              src={serialData ? serialData : NoImage}
              alt="pic"
              style={{ maxWidth: "160px" }}
            />
            <p className="para">
              {serialMsg ? serialMsg : "Scan Serial Code First"}
            </p>
          </div>

          <div>
            <div className="card card1">
              <h1 className="title">
                {box1Data?.box_info?.current_qty ||
                box1Data?.box_info?.current_qty === 0
                  ? Number(box1Data?.box_info?.current_qty) + counter
                  : "Scan Box First"}
              </h1>
              <p className="description">Current Box Qty</p>
            </div>
            <div className="card card2 mt-3">
              <h1 className="title">{counter}</h1>
              <p className="description">Total Quantity Newly Scanned</p>
            </div>
          </div>
        </div>
        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{ margin: "0", maxWidth: "200px" }}
            onClick={() => {
              setSerialCode("");
              setSerialMsg("");
              setBoxName("");
              setBox1Data({});
              setSerialData("");
              setCounter(0);
            }}
          >
            Clear
          </button>
          <button
            className="submit-btn"
            style={{
              backgroundColor: "#26A6FE",
              margin: "0",
              maxWidth: "200px",
            }}
            disabled={boxName === ""}
            onClick={() => fetchBoxLabel(boxName)}
          >
            Create Box Label
          </button>
        </div>
      </div>
    </>
  );
};

export default BoxQuantityAddition;
