import React from "react";
import { Link } from "react-router-dom";

const tabData = ["Single Order Label Generator", "Multi Order Label Generator"];
const Settings = ["Settings"];
const OrderHandover = ["Select Delivery partner", "Order Handover"];
const reverse_order = ["Create Reverse Handover", "Scan Orders"];
const TabModule = ({
  onData,
  single,
  ind,
  handover_id,
  reverse,
  label,
  id,
  singleOne,
}) => {
  return (
    <div className="tab-module-grid-row tab-update-grid-row">
      {single &&
        OrderHandover &&
        OrderHandover.map((i, index) => (
          <Link
            to={`${
              index === 0
                ? "/delivery-partner"
                : index === 1 && handover_id
                ? "/order-handover"
                : "/delivery-partner"
            }`}
            className={`${ind === index ? "activeTab" : "inActiveTab"}`}
            key={i}
          >
            <p>{i}</p>
          </Link>
        ))}

      <>
        {singleOne &&
          Settings &&
          Settings.map((i, index) => (
            <Link className={"lactiveTab"} key={i}>
              <p>{i}</p>
            </Link>
          ))}
      </>

      <>
        {label &&
          tabData &&
          tabData.map((i, index) => (
            <Link
              to={`${
                index === 0
                  ? "/single-label-generator"
                  : index === 1 && "/multi-label-generator"
              }`}
              className={`${ind === index ? "lactiveTab" : "linActiveTab"}`}
              key={i}
            >
              <p>{i}</p>
            </Link>
          ))}
      </>

      <>
        {reverse &&
          reverse_order &&
          reverse_order.map((i, index) => (
            <Link
              to={`${
                index === 0
                  ? "/create-reverse-handover"
                  : index === 1 && id
                  ? "/scan-orders"
                  : "/create-reverse-handover"
              }`}
              className={`${ind === index ? "activeTab" : "inActiveTab"}`}
              key={i}
            >
              <p>{i}</p>
            </Link>
          ))}
      </>
    </div>
  );
};

export default TabModule;
