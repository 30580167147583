import React, { Fragment, useState } from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import DataTable from "../../Components/Table/DataTable";
import Headline from "../../Components/HeadlineSpecial/Headline";
import {
  BypassOrderSummaryTableArr,
  BypassOrderSummaryTableData,
} from "../../DummyArray/TableArray/TableData";
import { Pagination } from "../../Components/Pagination/Pagination";
import Dropdown from "../../Components/Dropdown/Dropdown";

const BypassorderSumarry = () => {
  const [isToggle, setIsToggle] = useState(false);

  const [page] = useState(1);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };
  return (
    <Fragment>
      <MetaData title={`ByPass Order Summary`} />
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            {/* {loading ? (
              <LoaderAlone />
            ) : (
              <>
                {log_status === 0 ? (
                  <NoDataFromApi
                    height={true}
                    title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                  />
                ) : ( */}
            <>
              <Headline
                title="Bypass Order Summary (Wrong Product Send)"
                btnTitle="Export CSV"
              />
              {/* Dropdown grid row starts here */}
              <div className="dropdown-grid-row">
                <div>
                  <Dropdown placeholder="Order Number" />
                </div>
                <div>
                  <Dropdown placeholder="AWB Number" />
                </div>
                <div>
                  <Dropdown placeholder="Portal Name" />
                </div>
                <div>
                  <Dropdown placeholder="Courier Name" />
                </div>
                <div>
                  <Dropdown placeholder="Order Type" />
                </div>
                <div>
                  <Dropdown placeholder="Bypass By" />
                </div>
                <div>
                  <Dropdown placeholder="Bypass Date" />
                </div>
                <div>
                  <Dropdown placeholder="Current Status" />
                </div>
                <div>
                  <Dropdown placeholder="Total SKU’s Qty" />
                </div>
                <div>
                  <Dropdown placeholder="Correctly SKU’s Sent Qty" />
                </div>
                <div>
                  <Dropdown placeholder="Wrong SKU’s Sent Qty" />
                </div>
                <div>
                  <Dropdown placeholder="Label Generate" />
                </div>
              </div>
              {/* Dropdown grid row ends here */}
              {/* tote table */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  {/* {isLoading ? (
                    <LoaderAlone />
                  ) : ( */}
                  <DataTable
                    header={BypassOrderSummaryTableArr}
                    bypass_summary={BypassOrderSummaryTableData}
                  />
                  {/* )} */}
                </div>
              </div>
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenBypass-${page}`}
                  page={page}
                  //   isLoading={isLoading}
                  totalPage={1}
                  //   onPageChange={handlePage}
                />
              </div>
            </>
            {/* )} */}
            {/* </> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BypassorderSumarry;
