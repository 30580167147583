import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import { CommunicationAlert_header } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCommunicationAlertActions,
  // getAllCommunicationAlertCountActions,
  getAllFiltersPickingModuleActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import ModalContainer from "../../Components/Modal/Modal";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useData } from "../../DataContext/DataContext";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";

const CommunicationAlert = () => {
  const alert = useAlert();
  const { user, setUser } = useData();
  let id_user = user && user.id_user;

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { communication_list, loading, status } = useSelector(
    (state) => state.communication
  );

  const [pagination, setPagination] = useState(0);

  const [filtersTrue, setFilterTrue] = useState(false);

  // useEffect(() => {

  // }, [dispatch, token]);

  //   pagination code
  const [communication, setCommunication] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let limit = 10;

  const fetchData = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "communication_alerts",
          token,
          page,
          limit,
        })
        .then((response) => {
          if (response?.data?.status === 1) {
            setCommunication(response?.data?.data);
            setIsLoading(false);
          } else {
            alert.error(response?.data?.msg);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          setIsLoading(false);
        });
    },
    [alert, limit, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetchData(page);
      }
    },
    [filtersTrue, fetchData, pagination]
  );

  const fetchCommunication = useCallback(() => {
    communication_list &&
      setCommunication(communication_list && communication_list?.data);
  }, [communication_list]);

  useEffect(() => {
    fetchCommunication();
  }, [fetchCommunication]);

  const [isIndex, setIsIndex] = useState(null);
  const [imageuri, setImage_uri] = useState("");

  const handleImageGallery = (image) => {
    setImage_uri(image);
    setIsIndex(4);
  };

  const [problem, setProblem] = useState("");
  const [mssg, setMssg] = useState("");
  const [id, setId] = useState("");
  const handleMessage = (problem, mssg, id) => {
    setProblem(problem);
    setMssg(mssg);
    setIsIndex(5);
    // console.log("Communication Id: ", id);
  };

  const handleCommunicationId = (id) => {
    setId(id);
  };

  const [audio, setAudio] = useState("");

  const handleAudio = (audio) => {
    setAudio(audio);
    setIsIndex(6);
  };

  const handleDesc = (mssg) => {
    setMssg(mssg);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          imageuri={imageuri}
          problem={problem}
          message={mssg}
          audio={audio}
          id={id}
        />
      );
    }
    return null;
  };

  // filter code starts here
  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [user_name, setUser_name] = useState("");

  const handlePickers = (val) => {
    setUser(null);
    setFilterTrue(true);
    setUser_id(val?.id);
    setUser_name(val?.value);
    handlePage(1, false);
  };

  let filters = useMemo(() => {
    const dataToSend = {
      user_id,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) => value !== "" && value !== null
      )
    );
    return filter_data;
  }, [user_id]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "communication_alerts",
        token,
        page,
        filters,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setCommunication(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert]);

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    }
    // if (
    //   (filtersTrue === false && pagination === 0 && id_user === null) ||
    //   id_user === ""
    // ) {
    //   dispatch(
    //     getAllCommunicationAlertActions("communication_alerts", token, 10, 1)
    //   );
    // }
  }, [filtersTrue, fetchFilters]);

  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let pickers_list = all_filters && all_filters?.pickers;

  useEffect(() => {
    if (!filtersTrue && pagination === 0) {
      dispatch(
        getAllFiltersPickingModuleActions("filters", token, All_Filters)
      );
    }
  }, [dispatch, token, All_Filters, filtersTrue, pagination]);

  const filterName = pickers_list && pickers_list.find((i) => i.id === id_user);

  useEffect(() => {
    if (id_user && id_user !== "") {
      setUser_id(id_user);
    }
    if (filterName && filterName !== "") {
      setUser_name(filterName?.name);
    }
    setTimeout(() => {
      if (id_user && id_user !== "") {
        setFilterTrue(true);
        fetchFilters();
      }
    }, 200);
  }, [id_user, fetchFilters, filterName]);

  useEffect(() => {
    // if (isIndex === null) {
    //   dispatch(
    //     getAllCommunicationAlertCountActions(
    //       "communication_alerts_count",
    //       token
    //     )
    //   );
    // }
    // if (filtersTrue === false && id_user === null && isIndex === null) {
    //   dispatch(
    //     getAllCommunicationAlertActions("communication_alerts", token, 10, 1)
    //   );
    //   setPage(1);
    // }

    if (
      (filtersTrue === false && id_user === null && isIndex === null) ||
      (filtersTrue === false && pagination === 0 && id_user === null) ||
      id_user === ""
    ) {
      dispatch(
        getAllCommunicationAlertActions("communication_alerts", token, 10, 1)
      );
      setPage(1);
    }
  }, [isIndex, dispatch, token, filtersTrue, id_user, pagination]);

  const resteFilter = () => {
    setUser_id("");
    setUser_name("");
    setUser(null);
  };

  // useEffect(() => {
  //   if (user_id || user_name || user) {
  //     handlePage(1);
  //   }
  // }, [user_id, user_name, user, handlePage]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <>
      <MetaData title={`Communication/Alerts`} />
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <div>
          <SideBar
            handleIsToggle={handleIsToggle}
            isIndexNull={isIndex === null}
          />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            {loading ? (
              <LoaderAlone />
            ) : (
              <>
                {status === 0 ? (
                  <NoDataFromApi
                    height={true}
                    title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                  />
                ) : (
                  <>
                    <Headline title="Communication/Alerts" hide={true} />

                    {/* filter dropdown code starts here */}
                    <div className="input-actions" style={{ marginTop: "5px" }}>
                      <div>
                        <Dropdown
                          placeholder="Picker Name"
                          dropdown={pickers_list && pickers_list}
                          value={user_name}
                          onData={handlePickers}
                          showId={true}
                          loading={allFilter_loading}
                          noShow={false}
                        />
                      </div>
                      <div>
                        <div className="clear-filter">
                          <Button onClick={() => resteFilter()}>
                            Reset Filter
                          </Button>
                        </div>
                      </div>
                      <div className="date_handover"></div>
                    </div>

                    <div className="table-wrapper">
                      <div className="table-container">
                        <DataTable
                          header={CommunicationAlert_header}
                          communication_list={communication && communication}
                          openGallery={handleImageGallery}
                          openMessage={handleMessage}
                          openDesc={handleDesc}
                          openAudio={handleAudio}
                          communication_id={handleCommunicationId}
                        />
                      </div>
                    </div>

                    <div className="rendering-pagination">
                      <Pagination
                        uniqueKey={`ScreenB-${page}`}
                        page={page}
                        isLoading={isLoading ? isLoading : filter_Loading}
                        totalPage={
                          filtersTrue
                            ? filterTotal_pages
                            : communication_list &&
                              communication_list?.total_pages
                        }
                        onPageChange={handlePage}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default CommunicationAlert;
