import React, { useEffect, useMemo } from "react";
import Headline from "../../Components/HeadlineSpecial/Headline";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DataTable from "../../Components/Table/DataTable";
import { orderHandoverSummaryTable } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import {
  editHandoverItemAction,
  getHandoverNameAction,
  // getOrderHandoverActions,ss
  getOrderHandoverSummaryActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useCallback } from "react";
import { useState } from "react";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { useData } from "../../DataContext/DataContext";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET } from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./OrderSummary.css";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";

const OrderSummary = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setHandoverData, setCarrierData, setOrder_Summary } = useData();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { handoverSumaary, loading, status } = useSelector(
    (state) => state.orderSummary
  );

  // courier name list state
  const { courierName, loading: courier_loading } = useSelector(
    (state) => state.carriers
  );
  const { handover_name_list, loading: handover_loading } = useSelector(
    (state) => state.handoverAllName
  );

  // edit handover item state
  const {
    id_item,
    loading: edit_handover_loading,
    isRedirected,
  } = useSelector((state) => state.editHandover);

  // useEffect(() => {}, [dispatch, token]);

  // pagination code starts here
  const [order_summary_list, setOrder_summary_list] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);

  const [filtersTrue, setFilterTrue] = useState(false);

  const fetchNextOrderSummary = (page) => {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}`, { service_name: "get_handovers_list", page, token })
      .then((response) => {
        setOrder_summary_list(response?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePage = (page) => {
    setPagination(page);
    setPage(page);
    if (!filtersTrue && pagination >= 0) {
      fetchNextOrderSummary(page);
    }
  };

  const fetchOrderSummary = useCallback(() => {
    handoverSumaary &&
      setOrder_summary_list(handoverSumaary && handoverSumaary?.data);
  }, [handoverSumaary]);

  useEffect(() => {
    fetchOrderSummary();
  }, [fetchOrderSummary]);

  useEffect(() => {
    // Get Courier name dispatch
    // dispatch(getOrderHandoverActions("get_handovername_list", token));
    // Get Handover Names
    dispatch(getHandoverNameAction("get_handovername_list", token));
  }, [dispatch, token]);

  let courier_name = courierName && courierName?.data;
  let handover_name = handover_name_list && handover_name_list?.data;

  //  filters code
  const [courier_name_list, setCourier_Name_list] = useState("");
  const [carier_id, setcarier_id] = useState();

  const [handover_name_lists, setHandover_name_lists] = useState(null);
  const [id, setId] = useState(null);
  const [handover_dates, setHandover_dates] = useState(null);

  let filters = useMemo(() => {
    let handover_date = moment(handover_dates).format("yyyy-MM-DD");
    const dataToSend = {
      carier_id,
      id,
      handover_date,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [carier_id, id, handover_dates]);

  const handleCourier = (val) => {
    setPage(1);
    setcarier_id(val?.id);
    setCourier_Name_list(val?.value);
    setFilterTrue(true);
  };

  const handleHandoverName = (val) => {
    setPage(1);
    setHandover_name_lists(val?.unique_handover_name);
    setId(val?.id);
    setFilterTrue(true);
  };

  const handleDate = (date) => {
    setPage(1);
    setHandover_dates(date);
    setFilterTrue(true);
  };

  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "get_handovers_list",
        token,
        page,
        filters,
      })
      .then((response) => {
        setFilter_total_pages(response?.data?.total_pages);
        setOrder_summary_list(response?.data?.data);
        setFilter_loading(false);
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters]);

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (filtersTrue === false && pagination === 0) {
      dispatch(getOrderHandoverSummaryActions("get_handovers_list", token));
    }
  }, [fetchFilters, filtersTrue, dispatch, token, pagination]);

  const handleEditHandover = (handover_id) => {
    dispatch(
      editHandoverItemAction("get_handover_edit_data", token, handover_id)
    );
  };

  useEffect(() => {
    if (id_item && id_item?.status === 1 && isRedirected) {
      const handoverData = { handover_id: id_item && id_item?.data?.id };
      setHandoverData(handoverData);
      const carrierData = { carier_id: id_item && id_item?.data?.carier_id };
      setCarrierData(carrierData);
      const order_Summary = { order: true };
      setOrder_Summary(order_Summary);
      navigate(`/order-handover`);
      dispatch({
        type: EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET,
      });
    }
    if (id_item && id_item?.status === 0 && isRedirected) {
      alert.error(id_item && id_item?.msg);
      dispatch({
        type: EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET,
      });
    }
  }, [
    dispatch,
    id_item,
    alert,
    navigate,
    setCarrierData,
    setHandoverData,
    setOrder_Summary,
    isRedirected,
  ]);

  // Download All Handover Summary CSV
  const [loadingCSV, setLoadingCSV] = useState(false);
  const handleCSVDownload = async () => {
    setLoadingCSV(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "get_handover_list_csv",
        token,
      });
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCSV(false);
    } catch (err) {
      alert.error(err);
      setLoadingCSV(false);
    }
  };

  // const { csv_handover_order, loading: downloadLoading } = useSelector(
  //   (state) => state.downloadCSVByid
  // );

  const [downloadLoading, setDownloadLoading] = useState(false);

  const takeHandoverId = async (handover_id) => {
    setDownloadLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "get_handover_csv",
        token,
        handover_id,
      });
      const csvFile = response?.data?.data?.download_url;
      // console.log(csvFile);

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setDownloadLoading(false);
    } catch (err) {
      alert.error(err);
      setDownloadLoading(false);
    }
    // dispatch(
    //   DownloadHandoverCSVByIdActions("get_handover_csv", token, handover_id)
    // );
  };

  // console.log(csv_handover_order);

  const resteFilter = () => {
    setPage(1);
    setcarier_id("");
    setCourier_Name_list("");
    setHandover_name_lists("");
    setId("");
    setHandover_dates("");
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Order Summary`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    onData={handleCSVDownload}
                    title="Order Handover Summary"
                    btnTitle="Downloads Handover Summary"
                    loading={loadingCSV}
                  />

                  {/* dropdown */}
                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="Handover Name"
                        dropdown={handover_name && handover_name}
                        value={handover_name_lists}
                        onData={handleHandoverName}
                        noShow={true}
                        loading={handover_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <DatePicker
                        className="date-picker"
                        selected={handover_dates}
                        placeholderText="Date"
                        onChange={(date) => handleDate(date)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Courier Name"
                        dropdown={courier_name && courier_name}
                        value={courier_name_list}
                        onData={handleCourier}
                        noShow={true}
                        loading={courier_loading}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* dropdown */}

                  {/* table component starts here */}
                  <div className="table-wrapper-pagination">
                    <div className="table-container-pagination">
                      <div className="header-limit-checkbox">
                        <div></div>
                        <div>
                          <p className="result-out">
                            *Result {page} in{" "}
                            {filtersTrue
                              ? filterTotal_pages
                              : handoverSumaary && handoverSumaary?.total_pages}
                          </p>
                        </div>
                      </div>
                      {filter_Loading ? (
                        <LoaderAlone />
                      ) : (
                        <DataTable
                          orderHandoverSummary={
                            order_summary_list && order_summary_list
                          }
                          header={orderHandoverSummaryTable}
                          TakeId={handleEditHandover}
                          editHandover_loading={edit_handover_loading}
                          onId={takeHandoverId}
                          loadingDownload={downloadLoading}
                          avoidUnsedApiCall={true}
                          hideUserListApi={false}
                        />
                      )}
                    </div>
                  </div>
                  {/* table component ends here */}

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : handoverSumaary && handoverSumaary?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
