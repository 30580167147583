import React, { Fragment, useCallback, useState } from "react";
import Papa from "papaparse";
import DataTable from "../../Components/Table/DataTable";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { Button } from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { useAlert } from "react-alert";

// added comment
const CSVTable = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const [data, setData] = useState([]);

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setData(results.data);
            const requestBody = {
              service_name: "import_sublocation_settings",
              token: token,
              data: results.data,
            };
            axios
              .post(`${BASE_URL}`, requestBody)
              .then((response) => {
                console.log(response?.data);
                if (response && response?.data?.status === 1) {
                  alert.success("Data Saved Successfully");
                } else if (response && response?.data?.status === 0) {
                  alert.error(response && response?.data?.msg);
                }
                setLoadingPDF(false);
              })
              .catch((err) => {
                setLoadingPDF(false);
                console.log(err);
              });
          },
        });
      }
    },
    [token, alert]
  );

  const [loadingPDF, setLoadingPDF] = useState(false);

  const handleExportCSV = useCallback(() => {
    setLoadingPDF(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "export_sublocation_settings",
        token,
      })
      .then((response) => {
        if (response && response?.data?.status === 1) {
          let pdfUrl = response && response?.data?.file;
          window.open(pdfUrl, "_blank", "noopener,noreferrer");
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        }
        setLoadingPDF(false);
      })
      .catch((err) => {
        setLoadingPDF(false);
        console.log(err);
      });
  }, [token, alert]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Upload File`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            <div className="csv-export-row">
              <label className="browser-file-csv">
                Choose File
                <input type="file" accept=".csv" onChange={handleFileChange} />
              </label>
              <div>
                <Button onClick={() => handleExportCSV()}>
                  {loadingPDF ? <Loader /> : "Export CSV"}
                </Button>
              </div>
            </div>
            <div className="mt__4">
              {data?.length > 0 ? (
                <div className="table-wrapper-pagination">
                  <div className="table-container-pagination">
                    <DataTable csvData={data} header={Object.keys(data[0])} />
                  </div>
                </div>
              ) : (
                <NoDataFromApi
                  height={true}
                  title={"Please Choose CSV Files to Populate data..."}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CSVTable;
