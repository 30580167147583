import {
  GET_PICKER_VIEW_DETAILS_FAILURE,
  GET_PICKER_VIEW_DETAILS_REQUEST,
  GET_PICKER_VIEW_DETAILS_SUCCESS,
} from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import {
  ADD_BYPASS_FAILURE,
  ADD_BYPASS_REQUEST,
  ADD_BYPASS_SUCCESS,
  ADD_PERMISSIONS_FAILURE,
  ADD_PERMISSIONS_REQUEST,
  ADD_PERMISSIONS_SUCCESS,
  ADD_PREDEFINED_PROBLEMS_FAILURE,
  ADD_PREDEFINED_PROBLEMS_REQUEST,
  ADD_PREDEFINED_PROBLEMS_RESET,
  ADD_PREDEFINED_PROBLEMS_SUCCESS,
  ADD_TOTE_FAILURE,
  ADD_TOTE_REQUEST,
  ADD_TOTE_RESET,
  ADD_TOTE_SUCCESS,
  APPROVE_REJECT_MARK_LOST_FAILURE,
  APPROVE_REJECT_MARK_LOST_REQUEST,
  APPROVE_REJECT_MARK_LOST_SUCCESS,
  ASSIGN_PICKLIST_TO_USERS_FAILURE,
  ASSIGN_PICKLIST_TO_USERS_REQUEST,
  ASSIGN_PICKLIST_TO_USERS_RESET,
  ASSIGN_PICKLIST_TO_USERS_SUCCESS,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
  ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
  BYPASS_ALL_ORDER_FAILURE,
  BYPASS_ALL_ORDER_REQUEST,
  BYPASS_ALL_ORDER_SUCCESS,
  CANCEL_PICKLIST_FAILURE,
  CANCEL_PICKLIST_REQUEST,
  CANCEL_PICKLIST_RESET,
  CANCEL_PICKLIST_SUCCESS,
  CHANGE_PICKLIST_STATUS_FAILURE,
  CHANGE_PICKLIST_STATUS_REQUEST,
  CHANGE_PICKLIST_STATUS_RESET,
  CHANGE_PICKLIST_STATUS_SUCCESS,
  CLEAR_ERRORS,
  COMPLETE_AUDIT_FAILURE,
  COMPLETE_AUDIT_REQUEST,
  COMPLETE_AUDIT_SUCCESS,
  CREATE_PICKLIST_REQUIREMENT_FAILURE,
  CREATE_PICKLIST_REQUIREMENT_REQUEST,
  CREATE_PICKLIST_REQUIREMENT_RESET,
  CREATE_PICKLIST_REQUIREMENT_SUCCESS,
  DELETE_PREDEFINED_PROBLEMS_FAILURE,
  DELETE_PREDEFINED_PROBLEMS_REQUEST,
  DELETE_PREDEFINED_PROBLEMS_RESET,
  DELETE_PREDEFINED_PROBLEMS_SUCCESS,
  DELETE_TOTE_FAILURE,
  DELETE_TOTE_REQUEST,
  DELETE_TOTE_SUCCESS,
  EDIT_PREDEFINED_PROBLEMS_FAILURE,
  EDIT_PREDEFINED_PROBLEMS_REQUEST,
  EDIT_PREDEFINED_PROBLEMS_SUCCESS,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST,
  GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST,
  GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS,
  GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE,
  GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST,
  GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS,
  GET_ALL_FILTERS_PICKING_MODULE_FAILURE,
  GET_ALL_FILTERS_PICKING_MODULE_REQUEST,
  GET_ALL_FILTERS_PICKING_MODULE_SUCCESS,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST,
  GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS,
  GET_ALL_PREDEFINED_PROBLEMS_FAILURE,
  GET_ALL_PREDEFINED_PROBLEMS_REQUEST,
  GET_ALL_PREDEFINED_PROBLEMS_SUCCESS,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST,
  GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS,
  GET_COURIER_WISE_TABLE_DATA_FAILURE,
  GET_COURIER_WISE_TABLE_DATA_REQUEST,
  GET_COURIER_WISE_TABLE_DATA_SUCCESS,
  GET_DASHBOARD_ALL_DATA_FAILURE,
  GET_DASHBOARD_ALL_DATA_REQUEST,
  GET_DASHBOARD_ALL_DATA_SUCCESS,
  GET_DASHBOARD_USERS_FAILURE,
  GET_DASHBOARD_USERS_REQUEST,
  GET_DASHBOARD_USERS_SUCCESS,
  GET_DEVICE_MANAGEMENT_DATA_FAILURE,
  GET_DEVICE_MANAGEMENT_DATA_REQUEST,
  GET_DEVICE_MANAGEMENT_DATA_SUCCESS,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST,
  GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS,
  GET_MARK_LOST_COUNT_FAILURE,
  GET_MARK_LOST_COUNT_REQUEST,
  GET_MARK_LOST_COUNT_SUCCESS,
  GET_ORDER_LIST_TABLE_DATA_FAILURE,
  GET_ORDER_LIST_TABLE_DATA_REQUEST,
  GET_ORDER_LIST_TABLE_DATA_SUCCESS,
  GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE,
  GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST,
  GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS,
  GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE,
  GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST,
  GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST,
  GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS,
  GET_PICKLIST_VIEW_DETAILS_FAILURE,
  GET_PICKLIST_VIEW_DETAILS_REQUEST,
  GET_PICKLIST_VIEW_DETAILS_SUCCESS,
  GET_PORTAL_WISE_TABLE_DATA_FAILURE,
  GET_PORTAL_WISE_TABLE_DATA_REQUEST,
  GET_PORTAL_WISE_TABLE_DATA_SUCCESS,
  GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE,
  GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST,
  GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS,
  GET_SETTINGS_FAILURE,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_TOTE_LIST_FAILURE,
  GET_TOTE_LIST_REQUEST,
  GET_TOTE_LIST_SUCCESS,
  LIST_CREATE_ORDER_DETAILS_FAILURE,
  LIST_CREATE_ORDER_DETAILS_REQUEST,
  LIST_CREATE_ORDER_DETAILS_SUCCESS,
  MARK_LOST_SYNC_FAILURE,
  MARK_LOST_SYNC_REQUEST,
  MARK_LOST_SYNC_SUCCESS,
  MARK_LOST_TABLE_DATA_FAILURE,
  MARK_LOST_TABLE_DATA_REQUEST,
  MARK_LOST_TABLE_DATA_SUCCESS,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET,
  MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS,
  PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE,
  PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST,
  PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS,
  READ_COMMUNICATION_MODAL_FAILURE,
  READ_COMMUNICATION_MODAL_REQUEST,
  READ_COMMUNICATION_MODAL_RESET,
  READ_COMMUNICATION_MODAL_SUCCESS,
  REASSIGN_PICKLIST_TO_USERS_FAILURE,
  REASSIGN_PICKLIST_TO_USERS_REQUEST,
  REASSIGN_PICKLIST_TO_USERS_RESET,
  REASSIGN_PICKLIST_TO_USERS_SUCCESS,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET,
  REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS,
  REMOVE_BYPASS_FAILURE,
  REMOVE_BYPASS_REQUEST,
  REMOVE_BYPASS_SUCCESS,
  SCAN_AUDIT_FAILURE,
  SCAN_AUDIT_REQUEST,
  SCAN_AUDIT_SUCCESS,
  SECONDARY_ORDER_DETAILS_FAILURE,
  SECONDARY_ORDER_DETAILS_REQUEST,
  SECONDARY_ORDER_DETAILS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  START_AUDIT_FAILURE,
  START_AUDIT_REQUEST,
  START_AUDIT_SUCCESS,
  SYNC_NOT_FOUND_MARK_LOST_FAILURE,
  SYNC_NOT_FOUND_MARK_LOST_REQUEST,
  SYNC_NOT_FOUND_MARK_LOST_RESET,
  SYNC_NOT_FOUND_MARK_LOST_SUCCESS,
  VACANT_TOTE_FAILURE,
  VACANT_TOTE_REQUEST,
  VACANT_TOTE_RESET,
  VACANT_TOTE_SUCCESS,
  WITH_WITHOUT_AUDIT_DATA_FAILURE,
  WITH_WITHOUT_AUDIT_DATA_REQUEST,
  WITH_WITHOUT_AUDIT_DATA_SUCCESS,
} from "../../Constants/PickerMgmt/PickerManagementConstants";

// get Picker Management Reducers
export const getPickerManagementReducers = (
  state = { pickerMgmt: {} },
  action
) => {
  switch (action.type) {
    case GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        pickerMgmt: action.payload,
        status: action.payload.status,
      };
    case GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Picklist Management Reducers
export const getPicklistManagementReducers = (
  state = { picklistMgmt: {} },
  action
) => {
  switch (action.type) {
    case GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        picklistMgmt: action.payload,
        status: action.payload.status,
      };
    case GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Picklist Management Reducers
export const getSecondaryPicklistManagementReducers = (
  state = { secondaryPicklistMgmt: {} },
  action
) => {
  switch (action.type) {
    case GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        secondaryPicklistMgmt: action.payload,
        status: action.payload.status,
      };
    case GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Lost Sub Location Reducers
export const getLostSubLocationReducers = (
  state = { lostSubLocation: {} },
  action
) => {
  switch (action.type) {
    case GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        lostSubLocation: action.payload,
        status: action.payload.status,
      };
    case GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Lost Sub Location Reducers
export const getPickerActivityReducers = (
  state = { pActivity: [] },
  action
) => {
  switch (action.type) {
    case GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        pActivity: action.payload,
        status: action.payload.status,
      };
    case GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Order List Table Reducers
export const getOrderListTableReducers = (
  state = { list_orders: [] },
  action
) => {
  switch (action.type) {
    case GET_ORDER_LIST_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_LIST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        list_orders: action.payload,
        status: action.payload.status,
      };
    case GET_ORDER_LIST_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Portal Wise List Table Reducers
export const getPortalWiseDataReducers = (
  state = { portal_wise_orders: [] },
  action
) => {
  switch (action.type) {
    case GET_PORTAL_WISE_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PORTAL_WISE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        portal_wise_orders: action.payload,
        status: action.payload.status,
      };
    case GET_PORTAL_WISE_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Courier Wise List Table Reducers
export const getCourierWiseDataReducers = (
  state = { courier_wise_orders: [] },
  action
) => {
  switch (action.type) {
    case GET_COURIER_WISE_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_COURIER_WISE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        courier_wise_orders: action.payload,
        status: action.payload.status,
      };
    case GET_COURIER_WISE_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Courier Wise List Table Reducers
export const getDashboardUserReducers = (state = { Users: [] }, action) => {
  switch (action.type) {
    case GET_DASHBOARD_USERS_REQUEST:
      return {
        loading: true,
      };
    case GET_DASHBOARD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        Users: action.payload,
        status: action.payload.status,
      };
    case GET_DASHBOARD_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Tote List Table Reducers
export const getToteListReducers = (state = { tote_list: [] }, action) => {
  switch (action.type) {
    case GET_TOTE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TOTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        tote_list: action.payload,
        status: action.payload.status,
      };
    case GET_TOTE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get picker View Details
export const getPickerViewDetails = (
  state = { picker_details: {} },
  action
) => {
  switch (action.type) {
    case GET_PICKER_VIEW_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_PICKER_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        picker_details: action.payload,
        status: action.payload.status,
      };
    case GET_PICKER_VIEW_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// add tote reducers
export const AddToteReducers = (state = { new_tote: [] }, action) => {
  switch (action.type) {
    case ADD_TOTE_REQUEST:
      return {
        loading: true,
      };
    case ADD_TOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        new_tote: action.payload,
        status: action.payload.status,
        isAdded: true,
      };
    case ADD_TOTE_RESET:
      return {
        ...state,
        loading: false,
        isAdded: false,
      };
    case ADD_TOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// add tote reducers
export const deleteToteReducers = (state = { delete_tote: [] }, action) => {
  switch (action.type) {
    case DELETE_TOTE_REQUEST:
      return {
        loading: true,
      };
    case DELETE_TOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        delete_tote: action.payload,
        status: action.payload.status,
      };
    case DELETE_TOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// create Picklist Requirement reducers
export const createPicklistRequirementReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PICKLIST_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PICKLIST_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.msg,
        status: action.payload.status,
        isCreated: true,
      };
    case CREATE_PICKLIST_REQUIREMENT_RESET:
      return {
        ...state,
        loading: false,
        isCreated: false,
      };
    case CREATE_PICKLIST_REQUIREMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// create Picklist Requirement reducers
export const getAllAssigneeforPicklistReducers = (
  state = { assignee_users: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assignee_users: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Communication Alert Reducers
export const getAllCommunicationAlertReducers = (
  state = { communication_list: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        communication_list: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Communication Alert Count Reducers
export const getAllCommunicationAlertCountReducers = (
  state = { communication_list_count: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        communication_list_count: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Assign Picklist to users Reducers
export const AssignPicklistUsersReducers = (
  state = { assign_Picklist: {} },
  action
) => {
  switch (action.type) {
    case ASSIGN_PICKLIST_TO_USERS_REQUEST:
      return {
        loading: true,
      };
    case ASSIGN_PICKLIST_TO_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        assign_Picklist: action.payload,
        status: action.payload.status,
        isAssigned: true,
      };
    case ASSIGN_PICKLIST_TO_USERS_RESET:
      return {
        ...state,
        loading: false,
        isAssigned: false,
      };
    case ASSIGN_PICKLIST_TO_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Assign Picklist to users Reducers
export const ReAssignPicklistUsersReducers = (
  state = { Reassign_Picklist: {} },
  action
) => {
  switch (action.type) {
    case REASSIGN_PICKLIST_TO_USERS_REQUEST:
      return {
        loading: true,
      };
    case REASSIGN_PICKLIST_TO_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        Reassign_Picklist: action.payload,
        status: action.payload.status,
        isAssigned: true,
      };
    case REASSIGN_PICKLIST_TO_USERS_RESET:
      return {
        ...state,
        loading: false,
        isAssigned: false,
      };
    case REASSIGN_PICKLIST_TO_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Assign Picklist to users Reducers
export const cancelPicklistReducers = (state = { cancel_pick: {} }, action) => {
  switch (action.type) {
    case CANCEL_PICKLIST_REQUEST:
      return {
        loading: true,
      };
    case CANCEL_PICKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cancel_pick: action.payload,
        status: action.payload.status,
        isCancelled: true,
      };
    case CANCEL_PICKLIST_RESET:
      return {
        ...state,
        loading: false,
        isCancelled: false,
      };
    case CANCEL_PICKLIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Dashboard All Data Reducers
export const getDashboardAllDataReducers = (
  state = { dashboard_all_data: {} },
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_ALL_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_DASHBOARD_ALL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard_all_data: action.payload,
        status: action.payload.status,
      };
    case GET_DASHBOARD_ALL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Picker View Details Reducers
export const getPicklistViewDetailReducers = (
  state = { picklist_view: {} },
  action
) => {
  switch (action.type) {
    case GET_PICKLIST_VIEW_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_PICKLIST_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        picklist_view: action.payload,
        status: action.payload.status,
      };
    case GET_PICKLIST_VIEW_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Filter Picking Reducers
export const getAllFiltersPickingReducers = (
  state = { all_filters: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_FILTERS_PICKING_MODULE_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_FILTERS_PICKING_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        all_filters: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_FILTERS_PICKING_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Single order label generator Reducers
export const getAllSingleOrderLabelGeneratorReducers = (
  state = { single_label: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        single_label: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Multi order label generator Reducers
export const getAllMultiOrderLabelGeneratorReducers = (
  state = { multi_label: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        multi_label: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Assign Secondary Order Picklist Reducers
export const AssignSecondaryOrderPicklistReducers = (
  state = { secondary_assignment_picklist: {} },
  action
) => {
  switch (action.type) {
    case ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST:
      return {
        loading: true,
      };
    case ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        secondary_assignment_picklist: action.payload,
        status: action.payload.status,
        isSecAssigned: true,
      };
    case ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET:
      return {
        ...state,
        loading: false,
        isSecAssigned: false,
      };
    case ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// ReAssign Secondary Order Picklist Reducers
export const ReAssignSecondaryOrderPicklistReducers = (
  state = { Resecondary_assignment_picklist: {} },
  action
) => {
  switch (action.type) {
    case REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST:
      return {
        loading: true,
      };
    case REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        Resecondary_assignment_picklist: action.payload,
        status: action.payload.status,
        isReSecAssigned: true,
      };
    case REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET:
      return {
        ...state,
        loading: false,
        isReSecAssigned: false,
      };
    case REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Vacant Tote Reducers
export const VacantToteReducers = (state = { vacant_tote: {} }, action) => {
  switch (action.type) {
    case VACANT_TOTE_REQUEST:
      return {
        loading: true,
      };
    case VACANT_TOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        vacant_tote: action.payload,
        status: action.payload.status,
        isVacant: true,
      };
    case VACANT_TOTE_RESET:
      return {
        ...state,
        loading: false,
        isVacant: false,
      };
    case VACANT_TOTE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Read Communication Count Reducers
export const ReadCommunicationCountReducers = (
  state = { communication_read_count: {} },
  action
) => {
  switch (action.type) {
    case READ_COMMUNICATION_MODAL_REQUEST:
      return {
        loading: true,
      };
    case READ_COMMUNICATION_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        communication_read_count: action.payload,
        status: action.payload.status,
        isRead: true,
      };
    case READ_COMMUNICATION_MODAL_RESET:
      return {
        ...state,
        loading: false,
        isRead: false,
      };
    case READ_COMMUNICATION_MODAL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Change Picklist Status Reducers
export const ChangePicklistStatusReducers = (
  state = { picklisT_status: {} },
  action
) => {
  switch (action.type) {
    case CHANGE_PICKLIST_STATUS_REQUEST:
      return {
        loading: true,
      };
    case CHANGE_PICKLIST_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        picklisT_status: action.payload,
        status: action.payload.status,
        isChanged: true,
      };
    case CHANGE_PICKLIST_STATUS_RESET:
      return {
        ...state,
        loading: false,
        isChanged: false,
      };
    case CHANGE_PICKLIST_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Change Picklist Status Reducers
export const syncNotfoundMarkLostReducers = (
  state = { not_found_recalculate: {} },
  action
) => {
  switch (action.type) {
    case SYNC_NOT_FOUND_MARK_LOST_REQUEST:
      return {
        loading: true,
      };
    case SYNC_NOT_FOUND_MARK_LOST_SUCCESS:
      return {
        ...state,
        loading: false,
        not_found_recalculate: action.payload,
        status: action.payload.status,
        isCalculated: true,
      };
    case SYNC_NOT_FOUND_MARK_LOST_RESET:
      return {
        ...state,
        loading: false,
        isCalculated: false,
      };
    case SYNC_NOT_FOUND_MARK_LOST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Marked Placed Single Label Generator Reducers
export const MarkedPlacedSingleLabelGeneratorReducers = (
  state = { marked_single_placed: {} },
  action
) => {
  switch (action.type) {
    case MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST:
      return {
        loading: true,
      };
    case MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        marked_single_placed: action.payload,
        status: action.payload.status,
        isSingleMarked: true,
      };
    case MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET:
      return {
        ...state,
        loading: false,
        isSingleMarked: false,
      };
    case MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Marked Placed Multi Label Generator Reducers
export const MarkedPlacedMultiLabelGeneratorReducers = (
  state = { marked_multi_placed: {} },
  action
) => {
  switch (action.type) {
    case MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST:
      return {
        loading: true,
      };
    case MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        marked_multi_placed: action.payload,
        status: action.payload.status,
        isMultiMarked: true,
      };
    case MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET:
      return {
        ...state,
        loading: false,
        isMultiMarked: false,
      };
    case MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get All Predefined Problems Reducers
export const GetAllPredefinedProblemsReducers = (
  state = { predefined_problems_data: {} },
  action
) => {
  switch (action.type) {
    case GET_ALL_PREDEFINED_PROBLEMS_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_PREDEFINED_PROBLEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        predefined_problems_data: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_PREDEFINED_PROBLEMS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Predefined Problems Reducers
export const AddPredefinedProblemsReducers = (
  state = { add_predefined_problem: {} },
  action
) => {
  switch (action.type) {
    case ADD_PREDEFINED_PROBLEMS_REQUEST:
      return {
        loading: true,
      };
    case ADD_PREDEFINED_PROBLEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_predefined_problem: action.payload,
        status: action.payload.status,
        isCreated: true,
      };
    case ADD_PREDEFINED_PROBLEMS_RESET:
      return {
        ...state,
        loading: false,
        isCreated: false,
      };
    case ADD_PREDEFINED_PROBLEMS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete Predefined Problems Reducers
export const DeletePredefinedProblemsReducers = (
  state = { delete_predefined_problem: {} },
  action
) => {
  switch (action.type) {
    case DELETE_PREDEFINED_PROBLEMS_REQUEST:
      return {
        loading: true,
      };
    case DELETE_PREDEFINED_PROBLEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        delete_predefined_problem: action.payload,
        status: action.payload.status,
        isDeleted: true,
      };
    case DELETE_PREDEFINED_PROBLEMS_RESET:
      return {
        ...state,
        loading: false,
        isDeleted: false,
      };
    case DELETE_PREDEFINED_PROBLEMS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete Predefined Problems Reducers
export const EditPredefinedProblemsReducers = (
  state = { edit_predefined_problem: {} },
  action
) => {
  switch (action.type) {
    case EDIT_PREDEFINED_PROBLEMS_REQUEST:
      return {
        loading: true,
      };
    case EDIT_PREDEFINED_PROBLEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        edit_predefined_problem: action.payload,
        status: action.payload.status,
      };
    case EDIT_PREDEFINED_PROBLEMS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Picklist Another Details Reducers
export const PicklistAnotherDetailsReducers = (
  state = { picklist_another_details: {} },
  action
) => {
  switch (action.type) {
    case PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        picklist_another_details: action.payload,
        status: action.payload.status,
      };
    case PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Picklist Another Details Reducers
export const SecondaryPicklistDetailsReducers = (
  state = { secondary_picklist_details: {} },
  action
) => {
  switch (action.type) {
    case SECONDARY_ORDER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case SECONDARY_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        secondary_picklist_details: action.payload,
        status: action.payload.status,
      };
    case SECONDARY_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Order Details List Order Reducers
export const ListOrderDetailsReducers = (
  state = { list_order_details_data: [] },
  action
) => {
  switch (action.type) {
    case LIST_CREATE_ORDER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case LIST_CREATE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        list_order_details_data: action.payload,
        status: action.payload.status,
      };
    case LIST_CREATE_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Permission Reducers
export const add_permissionReducers = (
  state = { permission_list_data: [] },
  action
) => {
  switch (action.type) {
    case ADD_PERMISSIONS_REQUEST:
      return {
        loading: true,
      };
    case ADD_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permission_list_data: action.payload,
        status: action.payload.status,
      };
    case ADD_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add By_pass Reducers
export const add_bypassReducers = (state = { by_pass_add: {} }, action) => {
  switch (action.type) {
    case ADD_BYPASS_REQUEST:
      return {
        loading: true,
      };
    case ADD_BYPASS_SUCCESS:
      return {
        ...state,
        loading: false,
        by_pass_add: action.payload,
        status: action.payload.status,
      };
    case ADD_BYPASS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// remove by_pass Reducers
export const remove_bypassReducers = (
  state = { by_pass_remove: {} },
  action
) => {
  switch (action.type) {
    case REMOVE_BYPASS_REQUEST:
      return {
        loading: true,
      };
    case REMOVE_BYPASS_SUCCESS:
      return {
        ...state,
        loading: false,
        by_pass_remove: action.payload,
        status: action.payload.status,
      };
    case REMOVE_BYPASS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Mark Lost Table Data Reducers
export const MarkLostTableArrReducers = (state = { mark_lost: {} }, action) => {
  switch (action.type) {
    case MARK_LOST_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case MARK_LOST_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        mark_lost: action.payload,
        status: action.payload.status,
      };
    case MARK_LOST_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Mark Lost Count Request Reducers
export const MarkLostCountRequestReducers = (
  state = { Request_count: {} },
  action
) => {
  switch (action.type) {
    case GET_MARK_LOST_COUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_MARK_LOST_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        Request_count: action.payload,
        status: action.payload.status,
      };
    case GET_MARK_LOST_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get With & Without Audit Data Reducers
export const getWithWoutDataReducers = (state = { Audit_data: {} }, action) => {
  switch (action.type) {
    case WITH_WITHOUT_AUDIT_DATA_REQUEST:
      return {
        loading: true,
      };
    case WITH_WITHOUT_AUDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        Audit_data: action.payload,
        status: action.payload.status,
      };
    case WITH_WITHOUT_AUDIT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Approve Reject Mark Lost Request Reducers
export const ApproveRejectMarkLostRequestReducers = (
  state = { mark_lost_appRject: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_REJECT_MARK_LOST_REQUEST:
      return {
        loading: true,
      };
    case APPROVE_REJECT_MARK_LOST_SUCCESS:
      return {
        ...state,
        loading: false,
        mark_lost_appRject: action.payload,
        status: action.payload.status,
      };
    case APPROVE_REJECT_MARK_LOST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Start Audit Reducers
export const StartAuditRequestReducers = (
  state = { start_audit: {} },
  action
) => {
  switch (action.type) {
    case START_AUDIT_REQUEST:
      return {
        loading: true,
      };
    case START_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        start_audit: action.payload,
        status: action.payload.status,
      };
    case START_AUDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Scan Audit Request Reducers
export const ScanAuditRequestReducers = (
  state = { scan_audit: {} },
  action
) => {
  switch (action.type) {
    case SCAN_AUDIT_REQUEST:
      return {
        loading: true,
      };
    case SCAN_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        scan_audit: action.payload,
        status: action.payload.status,
      };
    case SCAN_AUDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Complete Audit Reducers
export const CompleteAuditRequestReducers = (
  state = { complete_audit: {} },
  action
) => {
  switch (action.type) {
    case COMPLETE_AUDIT_REQUEST:
      return {
        loading: true,
      };
    case COMPLETE_AUDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        complete_audit: action.payload,
        status: action.payload.status,
      };
    case COMPLETE_AUDIT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Settings Reducers
export const SettingsReducers = (state = { setting_data: {} }, action) => {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return {
        loading: true,
      };
    case SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        setting_data: action.payload,
        status: action.payload.status,
      };
    case SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Setting Data Reducers
export const getSettingsReducers = (
  state = { getsetting_data: {} },
  action
) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST:
      return {
        loading: true,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        getsetting_data: action.payload,
        status: action.payload.status,
      };
    case GET_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Sync Mark Lost Request Reducers
export const syncMarkLostRequestReducers = (
  state = { syncMark_Lost: {} },
  action
) => {
  switch (action.type) {
    case MARK_LOST_SYNC_REQUEST:
      return {
        loading: true,
      };
    case MARK_LOST_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        syncMark_Lost: action.payload,
        status: action.payload.status,
      };
    case MARK_LOST_SYNC_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Bypass All Reducers
export const ByPassAllSecondaryOrderReducers = (
  state = { bypassAll_order: {} },
  action
) => {
  switch (action.type) {
    case BYPASS_ALL_ORDER_REQUEST:
      return {
        loading: true,
      };
    case BYPASS_ALL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        bypassAll_order: action.payload,
        status: action.payload.status,
      };
    case BYPASS_ALL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Device Management Reducers
export const DeviceManagementReducers = (
  state = { device_management: {} },
  action
) => {
  switch (action.type) {
    case GET_DEVICE_MANAGEMENT_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_DEVICE_MANAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        device_management: action.payload,
        status: action.payload.status,
      };
    case GET_DEVICE_MANAGEMENT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
