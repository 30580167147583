import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import ModalContainer from "../../../Components/Modal/Modal";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import Loader from "../../../Components/Loader/Loader";
import { Pagination } from "../../../Components/Pagination/Pagination";

const SubLocationToSubLocationTransfer = (props) => {
  const alert = useAlert();
  const [sessionIds, setSessionIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedSession, setSelectionSession] = useState("0");
  const [sessionData, setSessionData] = useState({});
  const [sublocation, setSublocation] = useState("");
  const [sublocation2, setSublocation2] = useState("");
  const [sublocationData, setSublocationData] = useState([]);
  const [sublocationData2, setSublocationData2] = useState([]);
  const [locationType, setLocationType] = useState("sub_location_2");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [totalqty, setTotalQty] = useState(0);
  const [totalqty2, setTotalQty2] = useState(0);
  const [loading, setLoading] = useState(false);

  const [sourcepage, setSourcePage] = useState(1);
  const [totalSourcePage, setTotalSourcePage] = useState(0);

  useEffect(() => {
    setTotalSourcePage(Math.ceil(sublocationData.length / 6));
  }, [sublocationData.length]);

  let sublocationPageData = [...sublocationData];
  sublocationPageData = sublocationPageData.slice(
    (sourcepage - 1) * 6,
    sourcepage * 6
  );

  const [destpage, setDestPage] = useState(1);
  const [totalDestPage, setTotalDestPage] = useState(0);

  useEffect(() => {
    setTotalDestPage(Math.ceil(sublocationData2.length / 6));
  }, [sublocationData2.length]);

  let sublocationPageData2 = [...sublocationData2];
  sublocationPageData2 = sublocationPageData2.slice(
    (destpage - 1) * 6,
    destpage * 6
  );

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchSublocationDetails = useCallback(
    async (sub_location_code, type) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_sub_location_details",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          const updatedItems = data?.data
            .map((item) => ({
              ...item,
              details: "BoxLoc",
              image: item.url,
              qty: parseInt(item.qty, 10),
            }))
            .map(({ url, ...rest }) => rest);

          const totalQty = updatedItems.reduce(
            (sum, item) => sum + item.qty,
            0
          );
          if (type === 1) {
            setSublocationData(updatedItems);
            setTotalQty(totalQty);
            setError("");
          } else {
            setSublocationData2(updatedItems);
            setTotalQty2(totalQty);
            setError2("");
          }
        } else {
          if (type === 1) {
            setError(data.msg);
            setTotalQty(0);
            setSublocationData([]);
          } else {
            setError2(data.msg);
            setTotalQty2(0);
            setSublocationData2([]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {
      if (name === "sublocation") {
        if (sublocation !== "") {
          fetchSublocationDetails(sublocation, 1);
        } else {
          setError("");
          setSublocationData([]);
          setTotalQty(0);
        }
      } else {
        if (sublocation2 !== "") {
          fetchSublocationDetails(sublocation2, 2);
        } else {
          setSublocationData2("");
          setError2("");
          setTotalQty2(0);
        }
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "sublocation") {
      setSublocation(value);
    } else {
      setSublocation2(value);
    }
  };

  const handleSubmit = async () => {
    try {
      if (error !== "" || error2 !== "") {
        alert.error("Please resolve the error first.");
        return;
      }
      if (selectedSession === "0" || selectedSession === "") {
        alert.error("Please select the session ID.");
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_sub_location",
          token,
          sub_location_code1: sublocation,
          sub_location_code2: sublocation2,
          type: locationType,
          session_id: Number(selectedSession),
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        setSublocation("");
        setSublocation2("");
        setSublocationData([]);
        setSublocationData2([]);
        setTotalQty(0);
        setTotalQty2(0);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const fetchSessionIds = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_all_list_sessions",
          token,
        }
      );
      if (data.status === 1) {
        setSessionIds(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert]);

  useEffect(() => {
    fetchSessionIds();
  }, [fetchSessionIds, props.sessionCounter]);

  const fetchSessionRecord = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_session_record",
          token,
          id: Number(selectedSession),
        }
      );
      if (data.status === 1) {
        setSessionData(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert, selectedSession]);

  const handleSession = (option) => {
    setSelectionSession(option.id);
    props.setSelectedSession(option);
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(props.selectedSession, selectedOption)) {
      setSelectedOption(props.selectedSession);
      setSelectionSession(props.selectedSession.id);
    }
  }, [props.selectedSession, selectedOption]);

  useEffect(() => {
    if (selectedSession !== "0") {
      fetchSessionRecord();
    }
  }, [selectedSession, fetchSessionRecord]);

  const [message, setMessage] = useState("");
  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("NonBoxLoc");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          title={"Alert"}
          message={message}
          handleSubmit={handleSubmit}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Select Session Id`}
              options={
                sessionIds &&
                sessionIds.map((option) => ({
                  value: option.name,
                  label: option.name,
                  id: option.id,
                }))
              }
              className="select"
              id="courierName"
              value={
                Object.keys(selectedOption).length === 0 ? null : selectedOption
              }
              styles={customStylesSKU}
              onChange={(selectedOption) => handleSession(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.UserName}
              label="Created By"
              placeholder="Select Session Id"
              id="created_by"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.createdOn}
              label="Start Date and Time"
              placeholder="Select Session Id"
              id="datetime"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation}
              label="Source Sub Location"
              placeholder="Source Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              field={"sublocation"}
              error={sublocation !== "" && error}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {sublocationData?.length > 0 && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={sublocationPageData}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
        {sublocationData?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`SourceSublocation-${sourcepage}`}
              page={sourcepage}
              isLoading={false}
              totalPage={totalSourcePage}
              onPageChange={(page) => setSourcePage(page)}
            />
          </div>
        )}
      </div>

      <div className="summary-box mt-3">
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation2}
              label="Destination Sub Location"
              placeholder="Destination Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              field={"sublocation2"}
              error={sublocation2 !== "" && error2}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {sublocationData2?.length > 0 && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={sublocationPageData2}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
        {sublocationData2?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`DestSublocation-${destpage}`}
              page={destpage}
              isLoading={false}
              totalPage={totalDestPage}
              onPageChange={(page) => setDestPage(page)}
            />
          </div>
        )}
      </div>

      <div className="mt-3 btn-container">
        <button
          className="submit-btn"
          style={{
            margin: "0",
            maxWidth: "240px",
            backgroundColor: "#26A6FE",
          }}
          disabled={sublocation === "" || sublocation2 === ""}
          onClick={() => {
            setIsOpen(true);
            setModalIndex(19);
            setLocationType("sub_location_2");
            setMessage(
              `Are you sure you want to move <strong>${totalqty}</strong> units from sub-location <strong>${sublocation}</strong> to sub-location <strong>${sublocation2}</strong>, resulting in a total of <strong>${
                Number(totalqty) + Number(totalqty2)
              }</strong> units in <strong>${sublocation2}</strong>?`
            );
          }}
        >
          Submit
          {loading && <Loader />}
        </button>
        {/* <button
          className="submit-btn"
          disabled={sublocation === "" || sublocation2 === ""}
          style={{ margin: "0", maxWidth: "240px" }}
          onClick={() => {
            setIsOpen(true);
            setModalIndex(19);
            setLocationType("sub_location_1");
            setMessage(
              `Are you sure you want to move <strong>${totalqty2}</strong> units from sub-location <strong>${sublocation2}</strong> to sub-location <strong>${sublocation}</strong>, resulting in a total of <strong>${
                Number(totalqty) + Number(totalqty2)
              }</strong> units in <strong>${sublocation}</strong>?`
            );
          }}
        >
          Move Serial Code from Sublocation-2 to Sublocation-1{" "}
          {loading && <Loader />}
        </button> */}
      </div>
    </>
  );
};

export default SubLocationToSubLocationTransfer;
