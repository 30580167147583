import React, { memo, useEffect, useState } from "react";
import "./SideBar.css";
import logo from "../../Assets/Icons/logo.png";
import { NavLink } from "react-router-dom";
import { routes } from "../../DummyArray/NavbarArray/NavbarArray";
import { FaAlignLeft, FaRegTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllCommunicationAlertCountActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useData } from "../../DataContext/DataContext";
import { FaChevronDown } from "react-icons/fa";

const SideBar = ({ handleIsToggle, isIndexNull }) => {
  const { setUser } = useData();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { communication_list_count, loading } = useSelector(
    (state) => state.communicationCount
  );
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const toggleNavbar = () => {
    setIsSideBarVisible(!isSideBarVisible);
    handleIsToggle(!isSideBarVisible);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isIndex, setIsIndex] = useState(null);

  const handleDropdown = (index) => {
    setIsIndex(index);
    if (isIndex === index) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1080) {
      setIsSideBarVisible(false);
    } else {
      setIsSideBarVisible(true);
    }
  }, []);

  useEffect(() => {
    if (token || isIndexNull) {
      dispatch(
        getAllCommunicationAlertCountActions(
          "communication_alerts_count",
          token
        )
      );
    }
  }, [dispatch, token, isIndexNull]);

  let count = communication_list_count && communication_list_count?.data;

  return (
    <>
      {isSideBarVisible ? (
        <FaRegTimesCircle className="menu-icon" onClick={toggleNavbar} />
      ) : (
        <FaAlignLeft className="menu-icon" onClick={toggleNavbar} />
      )}
      <div
        className={`sidebar-menu ${isSideBarVisible ? "visible" : "hidden"}`}
      >
        <div className="logo">
          <img src={logo} alt="picklist-logo" />
        </div>
        <div className="routes">
          {routes &&
            routes.map((r, index) => {
              return (
                <div key={r.title}>
                  <div className="menu-wrapper">
                    <NavLink
                      onClick={() => {
                        localStorage.removeItem("page_Picklist_X");
                        setUser(null);
                        r.subRoutes && handleDropdown(index);
                      }}
                      activeclassname="active"
                      className="menu-item"
                      to={r.path}
                    >
                      <img src={r.icon} alt="" />
                      <p>{r.title}</p>
                      {r.path === "/communication-alert" && (
                        <div className="counts-alert">
                          {loading ? (
                            0
                          ) : (
                            <p>{count && count?.count ? count?.count : 0}</p>
                          )}
                        </div>
                      )}
                    </NavLink>
                    {r.subRoutes && (
                      <>
                        <FaChevronDown
                          onClick={() => handleDropdown(index)}
                          fontSize="0.8rem"
                          style={{
                            marginTop: "0.1rem",
                            transform:
                              isDropdownOpen && isIndex === index
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: ".5s ease-in-out",
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {r.subRoutes &&
                      r.subRoutes.map((k) => (
                        <div key={k.path}>
                          {isDropdownOpen && isIndex === index && (
                            <NavLink
                              onClick={() =>
                                localStorage.getItem("page_Picklist_X")
                              }
                              activeclassname="active"
                              className={`menu-item sub-routes ${
                                isDropdownOpen && isIndex === index
                                  ? "animate"
                                  : ""
                              }`}
                              to={k.path}
                            >
                              {/* <img src={k.icon} alt="" /> */}
                              <p>➡</p>
                              <p>
                                {k.title.length > 25
                                  ? `${k.title.slice(0, 25)}...`
                                  : k.title}
                              </p>
                            </NavLink>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(SideBar);
