import React, { useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import "./OrderHandover.css";
import InputField from "../../Components/InputField/InputField";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import Select from "react-select";
import { customStylesSKU } from "../../Components/DropdownStyles/DropdownStyles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getInventoryListActions,
  getOrderHandoverActions,
  getUniqueHandoverNameActions,
  saveDeliveryPartnerActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loader/Loader";
import { SAVE_DELIVERY_PARTNER_RESET } from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import { clearErrors } from "../../Actions/PickerMgmt/PickerMgmtActions";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useData } from "../../DataContext/DataContext";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MetaData from "../../Components/Helmet/Helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const OrderHandover = () => {
  const { setHandoverData, setCarrierData } = useData();
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { courierName, loading, status } = useSelector(
    (state) => state.carriers
  );
  const { handoverName, loading: handoverNameLoading } = useSelector(
    (state) => state.uniqueHandoverName
  );

  // list inventory state
  const { inventory_list, loading: inventory_loading } = useSelector(
    (state) => state.listofInventory
  );
  const [showDiv, setShowDiv] = useState(false);
  const divRef = useRef(null);

  const [search_keyword, setsearch_keyword] = useState("");

  // Function to handle clicks outside the div
  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShowDiv(false);
      setsearch_keyword("");
    }
  };

  useEffect(() => {
    if (showDiv) {
      // Add event listener when showDiv is true
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when showDiv is false
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDiv]);

  useEffect(() => {
    if (search_keyword.length >= 3) {
      setShowDiv(true);
    } else {
      setShowDiv(false);
    }
  }, [search_keyword, showDiv]);

  const {
    deliveryPartnerData,
    isSaved,
    loading: save_delivery_partner_loading,
  } = useSelector((state) => state.saveDelivery);
  const navigate = useNavigate();

  const [carrier_name, setcarrier_name] = useState("");
  const [carier_id, setcarrier_id] = useState("");
  const [handover_date, sethandover_date] = useState("");
  const [remarks, setremarks] = useState("");

  const handleCourierName = (value) => {
    setcarrier_id(value.id);
    setcarrier_name(value.value);
  };

  const [ids, setids] = useState([]);
  const [inventory_name, setInventory_name] = useState([]);

  // const handleIndex = (id, name, party_id) => {
  //   if (inventory_name.some((i) => i.party_id === party_id)) {
  //     return alert.error("Party Id");
  //   }
  //   if (!ids.includes(id) || !inventory_name.some((item) => item.id === id)) {
  //     setids((prevIds) => [...prevIds, id]);
  //     setInventory_name((prevNames) => [...prevNames, { id, name, party_id }]);
  //   } else {
  //     const filteredIds = ids.filter((i) => i !== id);
  //     const filteredNames = inventory_name.filter((item) => item.id !== id);
  //     setids(filteredIds);
  //     setInventory_name(filteredNames);
  //   }
  // };

  const handleIndex = (id, name, party_id) => {
    const isIdSelected = ids.includes(id);

    if (isIdSelected) {
      // If id is selected, deselect the item
      const updatedIds = ids.filter((i) => i !== id);
      const updatedNames = inventory_name.filter((item) => item.id !== id);

      setids(updatedIds);
      setInventory_name(updatedNames);
    } else {
      // If id is not selected, check for existing party_id
      if (inventory_name.some((item) => item.party_id === party_id)) {
        alert.error("Invoice For Selected Party Already Exists.");
        return;
      }

      // Select the item
      setids((prevIds) => [...prevIds, id]);
      setInventory_name((prevNames) => [...prevNames, { id, name, party_id }]);
    }
  };

  const handleRedirect = () => {
    navigate("/order-summary");
  };

  useEffect(() => {
    dispatch(getOrderHandoverActions("get_cariers", token));
  }, [dispatch, token]);

  let unique_handover_name =
    handoverName && handoverName?.data?.unique_handover_name;
  let carriers = courierName && courierName?.data;

  useEffect(() => {
    if (carier_id && carier_id) {
      dispatch(
        getUniqueHandoverNameActions("get_unique_handover_no", token, carier_id)
      );
    }
  }, [dispatch, carier_id, token]);

  const handleDeliveryPartner = () => {
    if (
      carier_id &&
      unique_handover_name &&
      handover_date &&
      remarks &&
      ids?.length > 0 &&
      inventory_name?.length > 0
    ) {
      dispatch(
        saveDeliveryPartnerActions(
          "save_delivery_partner",
          token,
          carier_id,
          unique_handover_name,
          moment(handover_date).format("yyyy-MM-DD"),
          "delivery_partner",
          remarks,
          ids
        )
      );
    } else {
      alert.error("Please Fill all the Field...");
    }
  };

  let handover_id = deliveryPartnerData && deliveryPartnerData?.data?.id;

  useEffect(() => {
    if (isSaved) {
      if (handover_id && carier_id) {
        const handoverData = { handover_id: handover_id };
        setHandoverData(handoverData);
        const carrierData = { carier_id: carier_id };
        setCarrierData(carrierData);
        navigate(`/order-handover`);
        alert.success("Data Saved Successfully...");
        dispatch({
          type: SAVE_DELIVERY_PARTNER_RESET,
        });
      }
    }
    if (deliveryPartnerData && deliveryPartnerData?.status === 0) {
      alert.error(deliveryPartnerData && deliveryPartnerData?.msg);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    deliveryPartnerData,
    alert,
    handover_id,
    navigate,
    isSaved,
    carier_id,
    setCarrierData,
    setHandoverData,
  ]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setsearch_keyword(inputValue);

    // Check if the last character is a backspace and the input field is empty
    if (
      inputValue === "" &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      // Remove the last item from the ids array
      setids((prevIds) => prevIds.slice(0, -30));
      setInventory_name((prevIds) => prevIds.slice(0, -2000));
    }
  };

  useEffect(() => {
    if (search_keyword.length >= 3) {
      dispatch(
        getInventoryListActions("get_inventories_list", token, search_keyword)
      );
    }
  }, [dispatch, token, search_keyword]);

  // console.log("Inventory List: ", inventory_list);
  // console.log(search_keyword.length);

  // console.log(ids, inventory_name);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  const handleToDate = (date) => {
    sethandover_date(date);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Delivery Partner`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    onData={handleRedirect}
                    title="Order Handover"
                    btnTitle="View Summary"
                  />

                  {/* Tab Module */}
                  <div className="Tab-module">
                    <TabModule
                      handover_id={handover_id && handover_id}
                      ind={0}
                      single={true}
                    />
                  </div>
                  <div className="summary-box">
                    <div className="grid-delivery-partner-info">
                      <div>
                        <label htmlFor="courierName">Courier Name</label>
                        <Select
                          placeholder={`${
                            carrier_name ? carrier_name : "Courier Name"
                          }`}
                          options={
                            carriers &&
                            carriers.map((option) => ({
                              value: option.name,
                              label: option.name,
                              id: option.id,
                            }))
                          }
                          className="select"
                          id="courierName"
                          value={carier_id}
                          styles={customStylesSKU}
                          onChange={(selectedOption) =>
                            handleCourierName(selectedOption)
                          }
                        />
                      </div>
                      <div className="mt-56">
                        <InputField
                          loading={handoverNameLoading}
                          value={unique_handover_name}
                          label="Handover Unique Name"
                          placeholder="Please Select Courier Name..."
                          id="handover_name"
                          // handover={true}
                        />
                      </div>
                      <div className="date">
                        <label htmlFor="date">Current Date</label>
                        {/* <input
                          type="date"
                          id="date"
                          value={handover_date}
                          onChange={(e) => sethandover_date(e.target.value)}
                        /> */}
                        <DatePicker
                          className="date-picker mt__x1"
                          placeholderText="Select Date"
                          selected={handover_date}
                          onChange={(date) => handleToDate(date)}
                        />
                      </div>
                      <div className="date inventory">
                        <label htmlFor="search_inventory">
                          Select Invoices
                        </label>{" "}
                        <br />
                        <input
                          placeholder="Search For Invoices"
                          type="text"
                          id="search_inventory"
                          value={search_keyword}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <button onClick={() => setShowDiv(!showDiv)}>
                          Toggle Div
                        </button> */}
                        <>
                          {showDiv && (
                            <>
                              {search_keyword?.length >= 3 && (
                                <div
                                  ref={divRef}
                                  className="background-white-list"
                                >
                                  {inventory_loading ? (
                                    <Loader />
                                  ) : (
                                    <>
                                      {inventory_list &&
                                      inventory_list?.data?.length > 0 ? (
                                        inventory_list?.data.map((i) => (
                                          <div key={i.id}>
                                            <div>
                                              <input
                                                type="checkbox"
                                                checked={
                                                  ids.includes(i.id)
                                                    ? true
                                                    : false
                                                }
                                                id="checkbox"
                                              />
                                              <label
                                                onClick={() =>
                                                  handleIndex(
                                                    i.id,
                                                    i.name,
                                                    i.party_id
                                                  )
                                                }
                                                htmlFor="checkbox"
                                              ></label>
                                            </div>
                                            <p
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleIndex(
                                                  i.id,
                                                  i.name,
                                                  i.party_id
                                                )
                                              }
                                            >
                                              {i.name}
                                            </p>
                                          </div>
                                        ))
                                      ) : (
                                        <p className="no-search">
                                          No Data Found...
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </div>
                      <div className="list-inventory">
                        {inventory_name &&
                          inventory_name?.map((i) => (
                            <div key={i}>
                              <Button onClick={() => handleIndex(i.id, i.name)}>
                                <CloseIcon />
                              </Button>
                              <p>{i.name}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="textarea">
                      <label htmlFor="Remarks">Remarks</label>
                      <textarea
                        onChange={(e) => setremarks(e.target.value)}
                        value={remarks}
                        id="Remarks"
                        cols="10"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="delivery-cta">
                      <Button
                        disabled={save_delivery_partner_loading ? true : false}
                        onClick={handleDeliveryPartner}
                        type="submit"
                        className="save-delivery-cta"
                      >
                        {save_delivery_partner_loading ? <Loader /> : "Submit"}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHandover;
