import React, { useCallback, useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import DataTable from "../../Table/DataTable";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import { useAlert } from "react-alert";
import LoaderAlone from "../../Loader/LoaderAlone";

const SessionDetails = (props) => {
  const { close } = props;
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_session_bbsc_detail",
          token,
          session_id: Number(props.session_id),
        }
      );
      setLoading(false);
      if (data?.status === 1) {
        setDetails(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [token, props, alert]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return (
    <>
      <HeadingTitle
        colorRed={false}
        title="Session Details"
        closeModal={close}
      />
      <div style={{ maxHeight: "350px", overflow: "auto" }}>
        {loading ? (
          <LoaderAlone />
        ) : (
          <DataTable
            header={["Serial Code", "Sub Location", "Location"]}
            sessionDetails={details}
          />
        )}
      </div>
    </>
  );
};

export default SessionDetails;
