// Picker Management Table Constants
export const GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST =
  "GET_PICKER_MANAGEMENT_TABLE_DATA_REQUEST";
export const GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS =
  "GET_PICKER_MANAGEMENT_TABLE_DATA_SUCCESS";
export const GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE =
  "GET_PICKER_MANAGEMENT_TABLE_DATA_FAILURE";

// Picklist Management Table Constants
export const GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST =
  "GET_PICKLIST_MANAGEMENT_TABLE_DATA_REQUEST";
export const GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS =
  "GET_PICKLIST_MANAGEMENT_TABLE_DATA_SUCCESS";
export const GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE =
  "GET_PICKLIST_MANAGEMENT_TABLE_DATA_FAILURE";

// Secondary Picklist Management Table Constants
export const GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST =
  "GET_SECONDARY_PICKLIST_TABLE_DATA_REQUEST";
export const GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS =
  "GET_SECONDARY_PICKLIST_TABLE_DATA_SUCCESS";
export const GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE =
  "GET_SECONDARY_PICKLIST_TABLE_DATA_FAILURE";

// Lost Sub Location List Table Constants
export const GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST =
  "GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_REQUEST";
export const GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS =
  "GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_SUCCESS";
export const GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE =
  "GET_LOST_SUB_LOCATION_LIST_TABLE_DATA_FAILURE";

// Picker Activity List Table Constants
export const GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST =
  "GET_PICKER_ACTIVITY_TABLE_DATA_REQUEST";
export const GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS =
  "GET_PICKER_ACTIVITY_TABLE_DATA_SUCCESS";
export const GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE =
  "GET_PICKER_ACTIVITY_TABLE_DATA_FAILURE";

// Get Orders List Table Constants
export const GET_ORDER_LIST_TABLE_DATA_REQUEST =
  "GET_ORDER_LIST_TABLE_DATA_REQUEST";
export const GET_ORDER_LIST_TABLE_DATA_SUCCESS =
  "GET_ORDER_LIST_TABLE_DATA_SUCCESS";
export const GET_ORDER_LIST_TABLE_DATA_FAILURE =
  "GET_ORDER_LIST_TABLE_DATA_FAILURE";

// Get Portal Wise List Table Constants
export const GET_PORTAL_WISE_TABLE_DATA_REQUEST =
  "GET_PORTAL_WISE_TABLE_DATA_REQUEST";
export const GET_PORTAL_WISE_TABLE_DATA_SUCCESS =
  "GET_PORTAL_WISE_TABLE_DATA_SUCCESS";
export const GET_PORTAL_WISE_TABLE_DATA_FAILURE =
  "GET_PORTAL_WISE_TABLE_DATA_FAILURE";

// Get Courier Wise List Table Constants
export const GET_COURIER_WISE_TABLE_DATA_REQUEST =
  "GET_COURIER_WISE_TABLE_DATA_REQUEST";
export const GET_COURIER_WISE_TABLE_DATA_SUCCESS =
  "GET_COURIER_WISE_TABLE_DATA_SUCCESS";
export const GET_COURIER_WISE_TABLE_DATA_FAILURE =
  "GET_COURIER_WISE_TABLE_DATA_FAILURE";

// Get Users of Homepage Constants
export const GET_DASHBOARD_USERS_REQUEST = "GET_DASHBOARD_USERS_REQUEST";
export const GET_DASHBOARD_USERS_SUCCESS = "GET_DASHBOARD_USERS_SUCCESS";
export const GET_DASHBOARD_USERS_FAILURE = "GET_DASHBOARD_USERS_FAILURE";

// Get Tote List Constant
export const GET_TOTE_LIST_REQUEST = "GET_TOTE_LIST_REQUEST";
export const GET_TOTE_LIST_SUCCESS = "GET_TOTE_LIST_SUCCESS";
export const GET_TOTE_LIST_FAILURE = "GET_TOTE_LIST_FAILURE";

// Add Tote Constant
export const ADD_TOTE_REQUEST = "ADD_TOTE_REQUEST";
export const ADD_TOTE_SUCCESS = "ADD_TOTE_SUCCESS";
export const ADD_TOTE_FAILURE = "ADD_TOTE_FAILURE";
export const ADD_TOTE_RESET = "ADD_TOTE_RESET";

// Delete Tote Constant
export const DELETE_TOTE_REQUEST = "DELETE_TOTE_REQUEST";
export const DELETE_TOTE_SUCCESS = "DELETE_TOTE_SUCCESS";
export const DELETE_TOTE_FAILURE = "DELETE_TOTE_FAILURE";

// Vacant Tote Constant
export const VACANT_TOTE_REQUEST = "VACANT_TOTE_REQUEST";
export const VACANT_TOTE_SUCCESS = "VACANT_TOTE_SUCCESS";
export const VACANT_TOTE_FAILURE = "VACANT_TOTE_FAILURE";
export const VACANT_TOTE_RESET = "VACANT_TOTE_RESET";

// Create Picklist Constant
export const CREATE_PICKLIST_REQUIREMENT_REQUEST =
  "CREATE_PICKLIST_REQUIREMENT_REQUEST";
export const CREATE_PICKLIST_REQUIREMENT_SUCCESS =
  "CREATE_PICKLIST_REQUIREMENT_SUCCESS";
export const CREATE_PICKLIST_REQUIREMENT_FAILURE =
  "CREATE_PICKLIST_REQUIREMENT_FAILURE";
export const CREATE_PICKLIST_REQUIREMENT_RESET =
  "CREATE_PICKLIST_REQUIREMENT_RESET";

// Get all assignee for picklist Constant
export const GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST =
  "GET_ALL_ASSIGNEE_FOR_PICKLIST_REQUEST";
export const GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS =
  "GET_ALL_ASSIGNEE_FOR_PICKLIST_SUCCESS";
export const GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE =
  "GET_ALL_ASSIGNEE_FOR_PICKLIST_FAILURE";

// Get all Communication Alert List Constant
export const GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST =
  "GET_ALL_COMMUNICATION_ALERT_LIST_REQUEST";
export const GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS =
  "GET_ALL_COMMUNICATION_ALERT_LIST_SUCCESS";
export const GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE =
  "GET_ALL_COMMUNICATION_ALERT_LIST_FAILURE";

// Get all Communication Alert List count Constant
export const GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST =
  "GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_REQUEST";
export const GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS =
  "GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_SUCCESS";
export const GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE =
  "GET_ALL_COMMUNICATION_ALERT_LIST_COUNT_FAILURE";

// Assign Picklist Constant
export const ASSIGN_PICKLIST_TO_USERS_REQUEST =
  "ASSIGN_PICKLIST_TO_USERS_REQUEST";
export const ASSIGN_PICKLIST_TO_USERS_SUCCESS =
  "ASSIGN_PICKLIST_TO_USERS_SUCCESS";
export const ASSIGN_PICKLIST_TO_USERS_FAILURE =
  "ASSIGN_PICKLIST_TO_USERS_FAILURE";
export const ASSIGN_PICKLIST_TO_USERS_RESET = "ASSIGN_PICKLIST_TO_USERS_RESET";

// ReAssign Picklist Constant
export const REASSIGN_PICKLIST_TO_USERS_REQUEST =
  "REASSIGN_PICKLIST_TO_USERS_REQUEST";
export const REASSIGN_PICKLIST_TO_USERS_SUCCESS =
  "REASSIGN_PICKLIST_TO_USERS_SUCCESS";
export const REASSIGN_PICKLIST_TO_USERS_FAILURE =
  "REASSIGN_PICKLIST_TO_USERS_FAILURE";
export const REASSIGN_PICKLIST_TO_USERS_RESET =
  "REASSIGN_PICKLIST_TO_USERS_RESET";

// ReAssign Picklist Constant
export const CANCEL_PICKLIST_REQUEST = "CANCEL_PICKLIST_REQUEST";
export const CANCEL_PICKLIST_SUCCESS = "CANCEL_PICKLIST_SUCCESS";
export const CANCEL_PICKLIST_FAILURE = "CANCEL_PICKLIST_FAILURE";
export const CANCEL_PICKLIST_RESET = "CANCEL_PICKLIST_RESET";

// get Dashboard All Data Constant
export const GET_DASHBOARD_ALL_DATA_REQUEST = "GET_DASHBOARD_ALL_DATA_REQUEST";
export const GET_DASHBOARD_ALL_DATA_SUCCESS = "GET_DASHBOARD_ALL_DATA_SUCCESS";
export const GET_DASHBOARD_ALL_DATA_FAILURE = "GET_DASHBOARD_ALL_DATA_FAILURE";

// get Picker view Details Constant
export const GET_PICKLIST_VIEW_DETAILS_REQUEST =
  "GET_PICKLIST_VIEW_DETAILS_REQUEST";
export const GET_PICKLIST_VIEW_DETAILS_SUCCESS =
  "GET_PICKLIST_VIEW_DETAILS_SUCCESS";
export const GET_PICKLIST_VIEW_DETAILS_FAILURE =
  "GET_PICKLIST_VIEW_DETAILS_FAILURE";

// get All Filters Constant
export const GET_ALL_FILTERS_PICKING_MODULE_REQUEST =
  "GET_ALL_FILTERS_PICKING_MODULE_REQUEST";
export const GET_ALL_FILTERS_PICKING_MODULE_SUCCESS =
  "GET_ALL_FILTERS_PICKING_MODULE_SUCCESS";
export const GET_ALL_FILTERS_PICKING_MODULE_FAILURE =
  "GET_ALL_FILTERS_PICKING_MODULE_FAILURE";

// Single order label generator Constant
export const GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST =
  "GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_REQUEST";
export const GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS =
  "GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_SUCCESS";
export const GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE =
  "GET_ALL_SINGLE_ORDER_LABEL_GENERATOR_FAILURE";

// Multi Order Label Generator Constant
export const GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST =
  "GET_ALL_MULTI_ORDER_LABEL_GENERATOR_REQUEST";
export const GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS =
  "GET_ALL_MULTI_ORDER_LABEL_GENERATOR_SUCCESS";
export const GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE =
  "GET_ALL_MULTI_ORDER_LABEL_GENERATOR_FAILURE";

// Assign Secondary Order Picklist Constants
export const ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST =
  "ASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST";
export const ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS =
  "ASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS";
export const ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET =
  "ASSIGN_SECONDARY_ORDERS_PICKLIST_RESET";
export const ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE =
  "ASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE";

// ReAssign Secondary Order Picklist Constants
export const REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST =
  "REASSIGN_SECONDARY_ORDERS_PICKLIST_REQUEST";
export const REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS =
  "REASSIGN_SECONDARY_ORDERS_PICKLIST_SUCCESS";
export const REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET =
  "REASSIGN_SECONDARY_ORDERS_PICKLIST_RESET";
export const REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE =
  "REASSIGN_SECONDARY_ORDERS_PICKLIST_FAILURE";

// Read Communication Modal Constants
export const READ_COMMUNICATION_MODAL_REQUEST =
  "READ_COMMUNICATION_MODAL_REQUEST";
export const READ_COMMUNICATION_MODAL_SUCCESS =
  "READ_COMMUNICATION_MODAL_SUCCESS";
export const READ_COMMUNICATION_MODAL_FAILURE =
  "READ_COMMUNICATION_MODAL_FAILURE";
export const READ_COMMUNICATION_MODAL_RESET = "READ_COMMUNICATION_MODAL_RESET";

// Change Picklist Status
export const CHANGE_PICKLIST_STATUS_REQUEST = "CHANGE_PICKLIST_STATUS_REQUEST";
export const CHANGE_PICKLIST_STATUS_SUCCESS = "CHANGE_PICKLIST_STATUS_SUCCESS";
export const CHANGE_PICKLIST_STATUS_RESET = "CHANGE_PICKLIST_STATUS_RESET";
export const CHANGE_PICKLIST_STATUS_FAILURE = "CHANGE_PICKLIST_STATUS_FAILURE";

// Sync not found/mark lost
export const SYNC_NOT_FOUND_MARK_LOST_REQUEST =
  "SYNC_NOT_FOUND_MARK_LOST_REQUEST";
export const SYNC_NOT_FOUND_MARK_LOST_SUCCESS =
  "SYNC_NOT_FOUND_MARK_LOST_SUCCESS";
export const SYNC_NOT_FOUND_MARK_LOST_RESET = "SYNC_NOT_FOUND_MARK_LOST_RESET";
export const SYNC_NOT_FOUND_MARK_LOST_FAILURE =
  "SYNC_NOT_FOUND_MARK_LOST_FAILURE";

// Marked Placed Single Label Generator Constants
export const MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST =
  "MARKED_PLACED_SINGLE_LABEL_GENERATOR_REQUEST";
export const MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS =
  "MARKED_PLACED_SINGLE_LABEL_GENERATOR_SUCCESS";
export const MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET =
  "MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET";
export const MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE =
  "MARKED_PLACED_SINGLE_LABEL_GENERATOR_FAILURE";

// Marked Placed Multi Label Generator Constants
export const MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST =
  "MARKED_PLACED_MULTI_LABEL_GENERATOR_REQUEST";
export const MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS =
  "MARKED_PLACED_MULTI_LABEL_GENERATOR_SUCCESS";
export const MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET =
  "MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET";
export const MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE =
  "MARKED_PLACED_MULTI_LABEL_GENERATOR_FAILURE";

// Get All Predefined Problems
export const GET_ALL_PREDEFINED_PROBLEMS_REQUEST =
  "GET_ALL_PREDEFINED_PROBLEMS_REQUEST";
export const GET_ALL_PREDEFINED_PROBLEMS_SUCCESS =
  "GET_ALL_PREDEFINED_PROBLEMS_SUCCESS";
export const GET_ALL_PREDEFINED_PROBLEMS_FAILURE =
  "GET_ALL_PREDEFINED_PROBLEMS_FAILURE";

// Add Predefined Problems
export const ADD_PREDEFINED_PROBLEMS_REQUEST =
  "ADD_PREDEFINED_PROBLEMS_REQUEST";
export const ADD_PREDEFINED_PROBLEMS_SUCCESS =
  "ADD_PREDEFINED_PROBLEMS_SUCCESS";
export const ADD_PREDEFINED_PROBLEMS_FAILURE =
  "ADD_PREDEFINED_PROBLEMS_FAILURE";
export const ADD_PREDEFINED_PROBLEMS_RESET = "ADD_PREDEFINED_PROBLEMS_RESET";

// Delete Predefined Problems
export const DELETE_PREDEFINED_PROBLEMS_REQUEST =
  "DELETE_PREDEFINED_PROBLEMS_REQUEST";
export const DELETE_PREDEFINED_PROBLEMS_SUCCESS =
  "DELETE_PREDEFINED_PROBLEMS_SUCCESS";
export const DELETE_PREDEFINED_PROBLEMS_FAILURE =
  "DELETE_PREDEFINED_PROBLEMS_FAILURE";
export const DELETE_PREDEFINED_PROBLEMS_RESET =
  "DELETE_PREDEFINED_PROBLEMS_RESET";

// Edit Predefined Problems
export const EDIT_PREDEFINED_PROBLEMS_REQUEST =
  "EDIT_PREDEFINED_PROBLEMS_REQUEST";
export const EDIT_PREDEFINED_PROBLEMS_SUCCESS =
  "EDIT_PREDEFINED_PROBLEMS_SUCCESS";
export const EDIT_PREDEFINED_PROBLEMS_FAILURE =
  "EDIT_PREDEFINED_PROBLEMS_FAILURE";

// Picklist Another Depth Details
export const PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST =
  "PICKLIST_ANOTHER_DEPTH_DETAILS_REQUEST";
export const PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS =
  "PICKLIST_ANOTHER_DEPTH_DETAILS_SUCCESS";
export const PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE =
  "PICKLIST_ANOTHER_DEPTH_DETAILS_FAILURE";

// Secondary Order Details
export const SECONDARY_ORDER_DETAILS_REQUEST =
  "SECONDARY_ORDER_DETAILS_REQUEST";
export const SECONDARY_ORDER_DETAILS_SUCCESS =
  "SECONDARY_ORDER_DETAILS_SUCCESS";
export const SECONDARY_ORDER_DETAILS_FAILURE =
  "SECONDARY_ORDER_DETAILS_FAILURE";

// Create Order Details
export const LIST_CREATE_ORDER_DETAILS_REQUEST =
  "LIST_CREATE_ORDER_DETAILS_REQUEST";
export const LIST_CREATE_ORDER_DETAILS_SUCCESS =
  "LIST_CREATE_ORDER_DETAILS_SUCCESS";
export const LIST_CREATE_ORDER_DETAILS_FAILURE =
  "LIST_CREATE_ORDER_DETAILS_FAILURE";

// Permissions Constants
export const ADD_PERMISSIONS_REQUEST = "ADD_PERMISSIONS_REQUEST";
export const ADD_PERMISSIONS_SUCCESS = "ADD_PERMISSIONS_SUCCESS";
export const ADD_PERMISSIONS_FAILURE = "ADD_PERMISSIONS_FAILURE";

// Add By_Pass Constants
export const ADD_BYPASS_REQUEST = "ADD_BYPASS_REQUEST";
export const ADD_BYPASS_SUCCESS = "ADD_BYPASS_SUCCESS";
export const ADD_BYPASS_FAILURE = "ADD_BYPASS_FAILURE";

// Remove By_Pass Constants
export const REMOVE_BYPASS_REQUEST = "REMOVE_BYPASS_REQUEST";
export const REMOVE_BYPASS_SUCCESS = "REMOVE_BYPASS_SUCCESS";
export const REMOVE_BYPASS_FAILURE = "REMOVE_BYPASS_FAILURE";

// Get Mark Lost Table Data Constants
export const MARK_LOST_TABLE_DATA_REQUEST = "MARK_LOST_TABLE_DATA_REQUEST";
export const MARK_LOST_TABLE_DATA_SUCCESS = "MARK_LOST_TABLE_DATA_SUCCESS";
export const MARK_LOST_TABLE_DATA_FAILURE = "MARK_LOST_TABLE_DATA_FAILURE";

// Get Mark Lost Count Request Constants
export const GET_MARK_LOST_COUNT_REQUEST = "GET_MARK_LOST_COUNT_REQUEST";
export const GET_MARK_LOST_COUNT_SUCCESS = "GET_MARK_LOST_COUNT_SUCCESS";
export const GET_MARK_LOST_COUNT_FAILURE = "GET_MARK_LOST_COUNT_FAILURE";

// Get With and Without Audit Data Constants
export const WITH_WITHOUT_AUDIT_DATA_REQUEST =
  "WITH_WITHOUT_AUDIT_DATA_REQUEST";
export const WITH_WITHOUT_AUDIT_DATA_SUCCESS =
  "WITH_WITHOUT_AUDIT_DATA_SUCCESS";
export const WITH_WITHOUT_AUDIT_DATA_FAILURE =
  "WITH_WITHOUT_AUDIT_DATA_FAILURE";

// Mark Lost Without Request Accept Constants
export const APPROVE_REJECT_MARK_LOST_REQUEST =
  "APPROVE_REJECT_MARK_LOST_REQUEST";
export const APPROVE_REJECT_MARK_LOST_SUCCESS =
  "APPROVE_REJECT_MARK_LOST_SUCCESS";
export const APPROVE_REJECT_MARK_LOST_FAILURE =
  "APPROVE_REJECT_MARK_LOST_FAILURE";

// Start Audit Constants
export const START_AUDIT_REQUEST = "START_AUDIT_REQUEST";
export const START_AUDIT_SUCCESS = "START_AUDIT_SUCCESS";
export const START_AUDIT_FAILURE = "START_AUDIT_FAILURE";

// Scan Audit Constants
export const SCAN_AUDIT_REQUEST = "SCAN_AUDIT_REQUEST";
export const SCAN_AUDIT_SUCCESS = "SCAN_AUDIT_SUCCESS";
export const SCAN_AUDIT_FAILURE = "SCAN_AUDIT_FAILURE";

// Complete Audit Constants
export const COMPLETE_AUDIT_REQUEST = "COMPLETE_AUDIT_REQUEST";
export const COMPLETE_AUDIT_SUCCESS = "COMPLETE_AUDIT_SUCCESS";
export const COMPLETE_AUDIT_FAILURE = "COMPLETE_AUDIT_FAILURE";

// Settings Constants
export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const SETTINGS_FAILURE = "SETTINGS_FAILURE";

export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";

// Sync Mark Lost Constants
export const MARK_LOST_SYNC_REQUEST = "MARK_LOST_SYNC_REQUEST";
export const MARK_LOST_SYNC_SUCCESS = "MARK_LOST_SYNC_SUCCESS";
export const MARK_LOST_SYNC_FAILURE = "MARK_LOST_SYNC_FAILURE";

// By Pass Order Constants
export const BYPASS_ALL_ORDER_REQUEST = "BYPASS_ALL_ORDER_REQUEST";
export const BYPASS_ALL_ORDER_SUCCESS = "BYPASS_ALL_ORDER_SUCCESS";
export const BYPASS_ALL_ORDER_FAILURE = "BYPASS_ALL_ORDER_FAILURE";

// Export SubLocation Settings Constants
export const EXPORT_SUBLOCATION_SETTINGS_REQUEST =
  "EXPORT_SUBLOCATION_SETTINGS_REQUEST";
export const EXPORT_SUBLOCATION_SETTINGS_SUCCESS =
  "EXPORT_SUBLOCATION_SETTINGS_SUCCESS";
export const EXPORT_SUBLOCATION_SETTINGS_FAILURE =
  "EXPORT_SUBLOCATION_SETTINGS_FAILURE";

// Get Device Management Data Constants
export const GET_DEVICE_MANAGEMENT_DATA_REQUEST =
  "GET_DEVICE_MANAGEMENT_DATA_REQUEST";
export const GET_DEVICE_MANAGEMENT_DATA_SUCCESS =
  "GET_DEVICE_MANAGEMENT_DATA_SUCCESS";
export const GET_DEVICE_MANAGEMENT_DATA_FAILURE =
  "GET_DEVICE_MANAGEMENT_DATA_FAILURE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
