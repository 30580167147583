import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "../../Components/Table/DataTable";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { Pagination } from "../../Components/Pagination/Pagination";
import Header from "../../Components/Header/Header";
import { DeviceManagement_HeaderARR } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { DeviceManagementActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";

const DeviceManagement = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  //   Device Management State
  const { device_management, loading } = useSelector(
    (state) => state.DeviceList
  );

  const [devices_management, setDeviceManagement] = useState([]);

  const [IsActive, setIsActive] = useState(false);
  const [filterTotal_pages, setFilter_total_pages] = useState(0);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  useEffect(() => {
    dispatch(DeviceManagementActions("picker_devices", token, 1, 10));
  }, [dispatch, token]);

  // Handle Pagination code starts here

  const [isLoading, setIsLoading] = useState(false);

  const fetchPagination = useCallback(
    (page, limit) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "picker_devices",
          page,
          token,
          limit,
        })
        .then((response) => {
          setIsActive(true);
          // console.log("RESPONSE DATA: ", response?.data?.total_pages);
          setFilter_total_pages(response?.data?.total_pages);
          setDeviceManagement(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [token]
  );

  const handlePage = useCallback(
    async (page) => {
      await setPage(page);
      fetchPagination(page, 10);
    },
    [fetchPagination]
  );
  // Handle Pagination code ends here

  useEffect(() => {
    if (device_management && device_management?.status === 1) {
      setDeviceManagement(device_management && device_management?.data);
    }
  }, [device_management]);

  return (
    <Fragment>
      <MetaData title={`Device Management`} />
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            <>
              <Headline title="Device Management" hide={true} />
              {/* tote table */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  {loading ? (
                    <LoaderAlone />
                  ) : (
                    <DataTable
                      header={DeviceManagement_HeaderARR}
                      device_management={
                        devices_management && devices_management
                      }
                    />
                  )}
                </div>
              </div>
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenDeviceManagement-${page}`}
                  page={page}
                  isLoading={isLoading}
                  totalPage={
                    IsActive
                      ? filterTotal_pages
                      : device_management && device_management?.total_pages
                  }
                  onPageChange={handlePage}
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DeviceManagement;
