import React from "react";
import Loader from "../Loader/Loader";
import { Button } from "@material-ui/core";
import "./Pagination.css";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

export const Pagination = ({
  page,
  isLoading,
  totalPage,
  onPageChange,
  uniqueKey,
  onHandleCheckbox,
}) => {
  const handlePageClick = useCallback(
    (page) => {
      onPageChange(page, true);
      onHandleCheckbox && onHandleCheckbox();
    },
    [onPageChange, onHandleCheckbox]
  );

  let maxvisiblePages = 5;

  const [visiblePages, setVisiblePage] = useState([]);

  const renderPagination = useCallback(() => {
    const totalPages = Math.ceil(totalPage);
    const pageOffset = Math.floor(maxvisiblePages / 2);
    let startpage = page - pageOffset;
    let endpage = page + pageOffset;

    if (startpage <= 0) {
      startpage = 1;
      endpage = Math.min(totalPages, maxvisiblePages);
    }

    if (endpage > totalPages) {
      endpage = totalPages;
      startpage = Math.max(1, totalPages - maxvisiblePages + 1);
    }

    const pageNumbers = [];

    for (let i = startpage; i <= endpage; i++) {
      pageNumbers.push(
        <Button
          key={`${uniqueKey}-page-${i}`}
          onClick={() => handlePageClick(i)}
          id={i}
          className={page === i ? "active-index" : "page-numbers"}
        >
          {page === i && isLoading ? <Loader /> : i}
        </Button>
      );
    }

    setVisiblePage(pageNumbers);

    return pageNumbers;
  }, [handlePageClick, isLoading, maxvisiblePages, page, totalPage, uniqueKey]);

  useEffect(() => {
    renderPagination();
  }, [renderPagination]);

  return (
    <div className="pagination">
      {visiblePages.length > 0 && (
        <button
          className="prev_page"
          onClick={() => handlePageClick(page - 1)}
          disabled={page === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={{ width: "1rem" }}
          >
            <path d="M6 8L2 12L6 16" />
            <path d="M2 12H22" />
          </svg>
        </button>
      )}
      <div key={uniqueKey}>{visiblePages}</div>
      {visiblePages.length > 0 && (
        <button
          className="next_page"
          onClick={() => handlePageClick(page + 1)}
          disabled={page === totalPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={{ width: "1rem" }}
          >
            <path d="M18 8L22 12L18 16" />
            <path d="M2 12H22" />
          </svg>
        </button>
      )}
    </div>
  );
};
