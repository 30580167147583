import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import MetaData from "../../Components/Helmet/Helmet";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFiltersPickingModuleActions,
  getSettingsRequestActions,
  SettingsRequestActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loader/Loader";
import Dropdown from "../../Components/Dropdown/Dropdown";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import WindowedSelect from "react-windowed-select";
import { customStyles } from "../../Components/DropdownStyles/DropdownStyles";

const selection_arr = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

const Settings = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState(false);
  const [value, setValue] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Redux selectors
  const { setting_data, loading } = useSelector(
    (state) => state.SettingsRequest
  );
  const { getsetting_data, loading: getSetting_loading } = useSelector(
    (state) => state.settingsGet
  );
  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  const isAuthenticated = JSON.parse(localStorage.getItem("user"));
  const token = isAuthenticated?.token;

  const sub_locations_list = useMemo(
    () =>
      all_filters?.picklist_sublocations?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) || [],
    [all_filters]
  );
  // Handlers
  const handleIsToggle = (val) => {
    setIsToggle((prev) => !prev);
  };

  const handleSelection = (val) => {
    setValue(val?.id);
  };

  const handleSelectionChange = useCallback((selected) => {
    setSelectedOptions(selected);
  }, []);

  const handleSubmit = () => {
    setIsUpdated(true);
    const arr = [{ name: "bypass_secondary_picking_validation", value }];

    if (selectedOptions.length > 0) {
      const selectedValues = selectedOptions
        .map((option) => option.value)
        .join(",");
      arr.push({
        name: "disabled_sublocations_for_picklist_creation",
        value: selectedValues,
      });
    }

    dispatch(SettingsRequestActions("update_settings", token, arr));
  };

  // Effects
  useEffect(() => {
    if (setting_data && setting_data?.status === 1 && isUpdated) {
      alert.success("Settings Value Updated");
      setIsUpdated(false);
    } else if (setting_data && setting_data?.status === 0 && isUpdated) {
      alert.error(setting_data?.msg);
      setIsUpdated(false);
    }
  }, [setting_data, isUpdated, alert]);

  useEffect(() => {
    if (token) {
      dispatch(getSettingsRequestActions("get_settings", token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (getsetting_data && getsetting_data?.status === 1) {
      alert.success("Settings Fetched Successfully!");
      setValue(getsetting_data.data[0]?.value);

      const fetchedIds = getsetting_data.data[1]?.value?.split(",") || [];
      console.log("FETCHED IDS: ", fetchedIds);
      const selectedItems = sub_locations_list.filter(({ value }) =>
        fetchedIds.includes(value.toString())
      );

      console.log("SELECTED ITEMS: ", selectedItems);
      setSelectedOptions(selectedItems);
    } else if (getsetting_data && getsetting_data?.status === 0) {
      alert.error(getsetting_data?.msg);
    }
  }, [getsetting_data, alert, sub_locations_list]);

  // xxxxxxxxxxxxxx

  useEffect(() => {
    if (token) {
      dispatch(
        getAllFiltersPickingModuleActions("filters", token, [
          "picklist_sublocations",
        ])
      );
    }
  }, [dispatch, token]);

  console.log("SELECTED OPTIONS: ", selectedOptions);

  return (
    <div
      className={`grid-sidebar-row ${
        isToggle ? "active-grid-sidebar-row" : ""
      }`}
    >
      <SideBar handleIsToggle={handleIsToggle} />
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <MetaData title={"ByPass Secondary Picking"} />
          <Headline title="Settings" hide={true} />
          <div className="Tab-module">
            <TabModule singleOne={true} />
          </div>
          <div className="summary-box">
            {getSetting_loading || allFilter_loading ? (
              <LoaderAlone />
            ) : (
              <Fragment>
                <div className="summary-box-grid-row settings-grid-row">
                  <div>
                    <label htmlFor="bypasssecondary">
                      Bypass Secondary Picking validation if all order items are
                      not picked in secondary picking
                    </label>
                    <br />
                    <Dropdown
                      dropdown={selection_arr}
                      onData={handleSelection}
                      placeholder="Bypass Secondary Picking validation if all order items are not picked in secondary picking"
                      value={
                        parseInt(value) === 1
                          ? "Yes"
                          : parseInt(value) === 0
                          ? "No"
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="summary-box-grid-row settings-grid-row mt-5x">
                  <div>
                    <label htmlFor="bypasssecondary">
                      Disabled SubLocation
                    </label>
                    <br />
                    <WindowedSelect
                      placeholder="Choose SubLocation"
                      options={sub_locations_list}
                      onChange={handleSelectionChange}
                      value={selectedOptions}
                      isMulti
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      // isLoading={allFilter_loading}
                    />
                  </div>
                </div>
                <div className="settings-cta-container">
                  <Button onClick={handleSubmit}>
                    {loading ? <Loader /> : "Submit"}
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
