import React, { Fragment, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import MetaData from "../../Components/Helmet/Helmet";
import ScannerIcon from "../../Assets/Icons/Order Handover/scan.png";
import DataTable from "../../Components/Table/DataTable";
import {
  ByPassOrderSummaryDATA_ARR,
  ByPassOrderSummaryHeader,
  PendingOrderDATA_ARR,
  PendingOrderHeaderARR,
} from "../../DummyArray/TableArray/TableData";
import { Button } from "@material-ui/core";

const ByPassOrderMapping = () => {
  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };
  return (
    <Fragment>
      <MetaData title={`Bypass Order Mapping`} />
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            <Fragment>
              <Headline title="Bypass Order Mapping" hide={true} />

              {/* Scanning Input Container starts here */}
              <div className="scanning-input-row">
                <label htmlFor="AWBno">Order Id or AWB Number Scan</label>
                <br />
                <div className="scanning-input-container">
                  <input type="text" id="AWBno" />
                  <img src={ScannerIcon} alt="" />
                </div>
              </div>
              {/* Scanning Input Container ends here */}

              {/* DataTable component starts here */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <DataTable
                    header={ByPassOrderSummaryHeader}
                    bypass_orderMapping={ByPassOrderSummaryDATA_ARR}
                  />
                </div>
              </div>
              {/* DataTable component ends here */}

              {/* Scanning Input Container starts here */}
              <div className="scanning-input-row">
                <label htmlFor="AWBno">Map Wrong SKU Serial Codes</label>
                <br />
                <div className="scanning-input-container">
                  <input type="text" id="AWBno" />
                  <img src={ScannerIcon} alt="" />
                </div>
              </div>
              {/* Scanning Input Container ends here */}

              {/* Pending Order Table component starts here */}
              {/* DataTable component starts here */}
              <h4 className="bypass-order-mapping-header">Pending Orders</h4>
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <DataTable
                    header={PendingOrderHeaderARR}
                    Pending_order_Bypass={PendingOrderDATA_ARR}
                  />
                </div>
              </div>
              {/* DataTable component ends here */}
              {/* Pending Order Table component ends here */}

              {/* Total Wrong SKU Sent Table component starts here */}
              <h4 className="bypass-order-mapping-header">
                Total Wrong SKU sent
              </h4>
              {/* DataTable component starts here */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <DataTable
                    header={PendingOrderHeaderARR}
                    Pending_order_Bypass={PendingOrderDATA_ARR}
                  />
                </div>
              </div>
              {/* Total Wrong SKU Sent ends here */}
              {/* Pending Order Table component ends here */}

              <div className="bypass-order-mapping-cta">
                <Button>Submit</Button>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ByPassOrderMapping;
