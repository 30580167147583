import React from "react";
import "./InputField.css";
import ScanIcon from "../../Assets/Icons/Order Handover/scan.png";

const InputField = (props) => {
  return (
    <div className="input-field-delivery" style={{ position: "relative" }}>
      {props.handover ? (
        <>
          <label htmlFor="">{props.label}</label>
          <input
            placeholder={`${props.loading ? "Loading..." : props.placeholder}`}
            type="text"
            readOnly
            className={props.darked ? "bg-dark" : ""}
            defaultValue={props.value}
            name={props.id}
            id={props.id}
          />
        </>
      ) : props.scan ? (
        <>
          <label htmlFor="">{props.label}</label>
          <input
            placeholder={`${props.loading ? "Loading..." : props.placeholder}`}
            type="text"
            readOnly={props.readOnly === false ? false : true}
            className={props.darked ? "bg-dark" : ""}
            value={props.value ? props.value : ""}
            name={props.id}
            onKeyDown={(e) => props.onKeyDown(e, props.field)}
            onChange={(e) => props.onChange(props.field, e.target.value)}
            id={props.id}
            style={{
              marginBottom: props.field === "qty" && "0px",
              paddingRight: "45px",
            }}
          />
          {props.error && <p className="errorpara">{props.error}</p>}
          {props.scan && (
            <img
              src={ScanIcon}
              alt="scan"
              style={{
                width: "30px",
                position: "absolute",
                right: "5px",
                top: "40px",
              }}
            />
          )}
        </>
      ) : (
        <>
          <label htmlFor="">{props.label}</label>
          <input
            placeholder={props.loading ? "Loading..." : props.placeholder || ""}
            type="text"
            readOnly={props.readOnly === false ? false : true}
            className={props.darked ? "bg-dark" : ""}
            value={props.value || props.value === 0 ? props.value : ""}
            name={props.id}
            onChange={(e) => props.onChange(props.field, e.target.value)}
            id={props.id}
            style={{ marginBottom: props.field === "qty" && "0px" }}
          />
          {props.error && <p className="errorpara">{props.error}</p>}
        </>
      )}
    </div>
  );
};

export default InputField;
