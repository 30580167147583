import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import { useCallback, useEffect, useState } from "react";
import ModalContainer from "../../../Components/Modal/Modal";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import { useAlert } from "react-alert";
import { Pagination } from "../../../Components/Pagination/Pagination";

const SubLocationToBoxTransfer = (props) => {
  const alert = useAlert();
  const [sessionIds, setSessionIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedSession, setSelectionSession] = useState("0");
  const [sessionData, setSessionData] = useState({});
  const [boxType, setBoxType] = useState("");
  const [boxName, setBoxName] = useState("");
  const [sublocation, setSublocation] = useState("");
  const [sublocation2, setSublocation2] = useState("");
  const [boxName2, setBoxName2] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [sublocationData, setSublocationData] = useState([]);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [totalqty, setTotalQty] = useState(0);
  const [loading, setLoading] = useState(false);

  const [sourcepage, setSourcePage] = useState(1);
  const [totalSourcePage, setTotalSourcePage] = useState(0);

  useEffect(() => {
    setTotalSourcePage(Math.ceil(sublocationData.length / 6));
  }, [sublocationData.length]);

  let sublocationPageData = [...sublocationData];
  sublocationPageData = sublocationPageData.slice(
    (sourcepage - 1) * 6,
    sourcepage * 6
  );

  const [boxpage, setBoxPage] = useState(1);
  const [totalBoxPage, setTotalBoxPage] = useState(0);

  useEffect(() => {
    const productsArray = Object.entries(box1Data?.products || {}).map(
      ([product_id, product]) => ({
        product_id,
        details: "Box",
        box_id: box1Data?.box_info?.id,
        ...product,
      })
    );
    setTotalBoxPage(Math.ceil(productsArray.length / 6));
  }, [box1Data]);

  let boxPageData = Object.entries(box1Data?.products || {}).map(
    ([product_id, product]) => ({
      product_id,
      details: "Box",
      box_id: box1Data?.box_info?.id,
      ...product,
    })
  );
  boxPageData = boxPageData.slice((boxpage - 1) * 6, boxpage * 6);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          setBox1Data(data.data);
          setError("");
        } else {
          setBox1Data({});
          setError(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const fetchSublocationDetails = useCallback(
    async (sub_location_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_sub_location_details",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          const updatedItems = data?.data
            .map((item) => ({
              ...item,
              details: "BoxLoc",
              image: item.url,
              qty: parseInt(item.qty, 10),
            }))
            .map(({ url, ...rest }) => rest); // Remove the 'url' field

          const totalQty = updatedItems.reduce(
            (sum, item) => sum + item.qty,
            0
          );
          setSublocationData(updatedItems);
          setTotalQty(totalQty);
          setError2("");
        } else {
          setSublocationData([]);
          setTotalQty(0);
          setError2(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const fetchBoxNo = useCallback(
    async (sub_location_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "ajax_new_box_label",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          setBoxName2(data?.data?.unique_box_no);
          setError3("");
        } else {
          setBoxName2("");
          setError3(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {
      if (name === "boxName") {
        if (boxName !== "") {
          fetchBoxDetails(boxName);
        } else {
          setBox1Data({});
          setError("");
        }
      } else if (name === "sublocation") {
        if (sublocation !== "") {
          fetchSublocationDetails(sublocation);
        } else {
          setError2("");
          setSublocationData([]);
          setTotalQty(0);
        }
      } else {
        if (sublocation2 !== "") {
          fetchBoxNo(sublocation2);
        } else {
          setBoxName2("");
          setError3("");
        }
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else if (name === "sublocation") {
      setSublocation(value);
    } else {
      setSublocation2(value);
    }
  };

  const fetchSessionIds = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_all_list_sessions",
          token,
        }
      );
      if (data.status === 1) {
        setSessionIds(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert]);

  useEffect(() => {
    fetchSessionIds();
  }, [fetchSessionIds, props.sessionCounter]);

  const fetchSessionRecord = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_session_record",
          token,
          id: Number(selectedSession),
        }
      );
      if (data.status === 1) {
        setSessionData(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert, selectedSession]);

  const handleSession = (option) => {
    setSelectionSession(option.id);
    props.setSelectedSession(option);
  };

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(props.selectedSession, selectedOption)) {
      setSelectedOption(props.selectedSession);
      setSelectionSession(props.selectedSession.id);
    }
  }, [props.selectedSession, selectedOption]);

  useEffect(() => {
    if (selectedSession !== "0") {
      fetchSessionRecord();
    }
  }, [selectedSession, fetchSessionRecord]);

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const handleSubmitNew = async () => {
    try {
      if (error3 !== "" || error2 !== "") {
        alert.error("Please resolve the error first.");
        return;
      }
      if (selectedSession === "0" || selectedSession === "") {
        alert.error("Please select the session ID.");
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_box",
          token,
          sub_location_code: sublocation,
          box_no: boxName2,
          sub_location_id: sublocation2,
          type: "new",
          session_id: selectedSession,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        fetchBoxLabel(boxName2);
        setBoxName("");
        setBoxName2("");
        setSublocation("");
        setSublocation2("");
        setBox1Data({});
        setSublocationData([]);
        setBoxType("");
        setTotalQty(0);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const handleSubmitExisting = async () => {
    try {
      if (error !== "" || error2 !== "") {
        alert.error("Please resolve the error first.");
        return;
      }
      if (selectedSession === "0" || selectedSession === "") {
        alert.error("Please select the session ID.");
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_box",
          token,
          sub_location_code: sublocation,
          box_no: boxName,
          type: "box",
          session_id: selectedSession,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        fetchBoxLabel(boxName);
        setBoxName("");
        setBoxName2("");
        setSublocation("");
        setSublocation2("");
        setBox1Data({});
        setSublocationData([]);
        setBoxType("");
        setTotalQty(0);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [message, setMessage] = useState("");
  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          title={"Alert"}
          message={message}
          handleSubmit={
            boxType === "New" ? handleSubmitNew : handleSubmitExisting
          }
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Select Session Id`}
              options={
                sessionIds &&
                sessionIds.map((option) => ({
                  value: option.name,
                  label: option.name,
                  id: option.id,
                }))
              }
              className="select"
              id="courierName"
              styles={customStylesSKU}
              value={
                Object.keys(selectedOption).length === 0 ? null : selectedOption
              }
              onChange={(selectedOption) => handleSession(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.UserName}
              label="Created By"
              placeholder="Select Session Id"
              id="created_by"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.createdOn}
              label="Start Date and Time"
              placeholder="Select Session Id"
              id="datetime"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation}
              label="Scan Sub Location"
              placeholder="Scan Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              field={"sublocation"}
              error={sublocation !== "" && error2}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {sublocationData?.length > 0 && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={sublocationPageData}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
        {sublocationData?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`Sublocation1-${sourcepage}`}
              page={sourcepage}
              isLoading={false}
              totalPage={totalSourcePage}
              onPageChange={(page) => setSourcePage(page)}
            />
          </div>
        )}

        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{ margin: "0", maxWidth: "200px" }}
            onClick={() => setBoxType("New")}
          >
            Create New Box
          </button>
          <button
            className="submit-btn"
            style={{
              margin: "0",
              maxWidth: "200px",
              backgroundColor: "#26A6FE",
            }}
            onClick={() => setBoxType("Existing")}
          >
            Existing Box
          </button>
        </div>
      </div>

      {boxType === "New" && (
        <div className="summary-box mt-3">
          <div className="grid-delivery-partner-info">
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={sublocation2}
                label="Scan Sub Location"
                placeholder="Scan Sub Location"
                id="scanbox"
                scan={true}
                readOnly={false}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                field={"sublocation2"}
                error={sublocation2 !== "" && error3}
              />
            </div>
            <div className="mt-56">
              <InputField
                value={boxName2}
                label="Box Number"
                placeholder="Scan Sublocation First"
                darked={true}
              />
            </div>
          </div>

          <div className="mt-3 btn-container">
            <button
              className="submit-btn"
              style={{ margin: "0", maxWidth: "200px" }}
              disabled={sublocation === "" || sublocation2 === ""}
              onClick={() => {
                setIsOpen(true);
                setModalIndex(19);
                setMessage(
                  `Box <strong>${boxName2}</strong> has <strong>${totalqty}</strong> units from the sub-location <strong>${sublocation}</strong>. Do you want to continue?`
                );
              }}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            {/* <button
              className="submit-btn"
              style={{
                backgroundColor: "#26A6FE",
                margin: "0",
                maxWidth: "200px",
              }}
              disabled={boxName2 === ""}
              onClick={() => fetchBoxLabel(boxName2)}
            >
              Create Box Label
            </button> */}
          </div>
        </div>
      )}

      {boxType === "Existing" && (
        <div className="summary-box mt-3">
          <div className="grid-delivery-partner-info">
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={boxName}
                label="Scan Box Id"
                placeholder="Scan Box Id"
                id="scanbox"
                scan={true}
                readOnly={false}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                field={"boxName"}
                error={boxName !== "" && error}
              />
            </div>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <KeyPairs
              data={[
                {
                  key: "Location",
                  value: box1Data?.box_info?.Location
                    ? box1Data?.box_info?.Location
                    : "Scan Box First",
                },
                {
                  key: "Sub Location",
                  value: box1Data?.box_info?.SubLocation
                    ? box1Data?.box_info?.SubLocation
                    : "Scan Box First",
                },
                {
                  key: "Current Quantity",
                  value:
                    box1Data?.box_info?.current_qty ||
                    box1Data?.box_info?.current_qty === 0
                      ? box1Data?.box_info?.current_qty
                      : "Scan Box First",
                },
                {
                  key: "Initial Quantity",
                  value:
                    box1Data?.box_info?.qty || box1Data?.box_info?.qty === 0
                      ? box1Data?.box_info?.qty
                      : "Scan Box First",
                },
                {
                  key: "Complete Location",
                  value: box1Data?.box_info?.SubLocation
                    ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                    : "Scan Box First",
                },
              ]}
            />
          </div>
          <div className="table-container-pagination overflow-hidden">
            <div className="overflow-auto">
              {box1Data?.products &&
                Object.keys(box1Data?.products)?.length > 0 && (
                  <DataTable
                    header={["SKU Code", "Qty", "Action"]}
                    boxboxtransfer={boxPageData}
                    onData={handleModal}
                    onDetail={setModalDetail}
                    onHeadingIndex={setModalBoxId}
                    assign_Picklist_id={setModalProductId}
                  />
                )}
            </div>
          </div>
          {box1Data?.products &&
            Object.keys(box1Data?.products)?.length > 0 && (
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`Boxproduct-${boxpage}`}
                  page={boxpage}
                  isLoading={false}
                  totalPage={totalBoxPage}
                  onPageChange={(page) => setBoxPage(page)}
                />
              </div>
            )}

          <div className="mt-3 btn-container">
            <button
              className="submit-btn"
              style={{ margin: "0", maxWidth: "200px" }}
              disabled={sublocation === "" || boxName === ""}
              onClick={() => {
                setIsOpen(true);
                setModalIndex(19);
                setMessage(
                  `The existing Box <strong>${boxName}</strong> has <strong>${
                    box1Data?.box_info?.current_qty
                  }</strong> units. With an additional <strong>${totalqty}</strong> units from the sub-location <strong>${sublocation}</strong>, the box will now have a total of <strong>${
                    Number(totalqty) + Number(box1Data?.box_info?.current_qty)
                  }</strong> units.`
                );
              }}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            {/* <button
              className="submit-btn"
              style={{
                margin: "0",
                maxWidth: "200px",
                backgroundColor: "#26A6FE",
              }}
              disabled={boxName === ""}
              onClick={() => {
                fetchBoxLabel(boxName);
              }}
            >
              Create Box Label
            </button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SubLocationToBoxTransfer;
