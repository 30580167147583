import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Screens/Home/Home";
import NotFound from "./Components/NotFound/NotFound";
import PickerView from "./Screens/PickerView/PickerView";
import PickerManagement from "./Screens/PickerManagement/PickerManagement";
import PickList from "./Screens/PickList/PickList";
import PickListView from "./Screens/PickListView/PickListView";
import SecondaryPicklist from "./Screens/SecondaryPicklist/SecondaryPicklist";
import PortalManagement from "./Screens/PortalManagement/PortalManagement";
import CourierManagement from "./Screens/CourierManagement/CourierManagement";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Activity from "./Screens/Activity/Activity";
import LostSubLocation from "./Screens/LostSubLocation/LostSubLocation";
import LabelGenerator from "./Screens/LabelGenerator/SingleLabelGenerator";
import ToteManagement from "./Screens/ToteManagement/ToteManagement";
import DeliveryPartner from "./Screens/OrderHandover/DeliveryPartner";
import Login from "./Screens/Login/Login";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import OrderSummary from "./Screens/OrderSummary/OrderSummary";
import CreateOrder from "./Screens/CreateOrder/CreateOrder";
import OrderHandover from "./Screens/OrderHandover/OrderHandover";
import { DataProvider } from "./DataContext/DataContext";
import CommunicationAlert from "./Screens/CommunicationAlert/CommunicationAlert";
import CreateReverseHandover from "./Screens/ReverseOrderHandover/CreateReverseHandover";
import ReverseHandoverSummary from "./Screens/ReverseHandoverSummary/ReverseHandoverSummary";
import ScanOrders from "./Screens/ReverseOrderHandover/ScanOrders";
import MultiLabelGenerator from "./Screens/LabelGenerator/MultiLabelGenerator";
import PredefinedProblems from "./Screens/PredefinedProblems/PredefinedProblems";
import Bypass from "./Screens/ByPass/Bypass";
// import PackingMaterial from "./Screens/PackingMaterial/PackingMaterial";
import AddIdealConsumption from "./Screens/PackingMaterial/AddIdealConsumption";
import ConsumptionReport from "./Screens/PackingMaterial/ConsumptionReport";
import MarkLostRequest from "./Screens/LostSubLocation/MarkLostRequest";
import AcceptRequestWithAudit from "./Screens/LostSubLocation/AcceptRequestWithAudit";
import AdvanceSubLocationTransfer from "./Screens/LostSubLocation/AdvanceSubLocationTransfer";
import ContentInThirdBox from "./Screens/LostSubLocation/ContentInThirdBox";
// import ContentInNewBox from "./Screens/LostSubLocation/ContentInNewBox";
import AdvanceSubLocationTransferSummary from "./Screens/LostSubLocation/AdvanceSubLocationTransferSummary";
import SessionSummary from "./Screens/LostSubLocation/SessionSummary";
import BoxQuantityAudit from "./Screens/BoxAudit/BoxQuantityAudit";
import BoxUnitQuantityAudit from "./Screens/BoxAudit/BoxUnitQuantityAudit";
import BoxAuditTransactionSummary from "./Screens/BoxAudit/BoxAuditTransactionSummary";
import Settings from "./Screens/Settings/Settings";
import CSVTable from "./Screens/PapaParse/CSVTable";
import BypassorderSumarry from "./Screens/ByPass/BypassorderSumarry";
import ByPassOrderMapping from "./Screens/ByPass/ByPassOrderMapping";
import DeviceManagement from "./Screens/DeviceManagement/DeviceManagement";

const App = () => {
  // const [handoverData, setHandoverData] = useState(null);
  // const [carrierData, setCarrierData] = useState(null);

  return (
    <DataProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<ProtectedRoutes />}>
            <Route element={<Home />} path="/" />
            <Route element={<PickerView />} path="/user-details/:user_id" />
            <Route element={<PickerManagement />} path="/picker-management" />
            <Route element={<PickList />} path="/picklist" />
            <Route element={<PickListView />} path="/picklist-view/:id" />
            <Route element={<SecondaryPicklist />} path="/secondary-picklist" />
            <Route
              element={<CommunicationAlert />}
              path="/communication-alert"
            />
            <Route element={<PortalManagement />} path="/portals-management" />
            <Route element={<CourierManagement />} path="/courier-management" />
            <Route element={<Activity />} path="/activity" />
            <Route
              element={<CreateReverseHandover />}
              path="/create-reverse-handover"
            />
            <Route element={<LostSubLocation />} path="/lost-sublocation" />
            <Route
              element={<LabelGenerator />}
              path="/single-label-generator"
            />
            <Route
              element={<MultiLabelGenerator />}
              path="/multi-label-generator"
            />
            <Route element={<ToteManagement />} path="/tote-management" />
            <Route
              element={
                <DeliveryPartner
                // setHandoverData={setHandoverData}
                // setCarrierData={setCarrierData}
                // handoverData={handoverData}
                // carrierData={carrierData}
                />
              }
              path="/delivery-partner"
            />
            <Route
              element={
                <OrderHandover
                // setHandoverData={setHandoverData}
                // setCarrierData={setCarrierData}
                // handoverData={handoverData}
                // carrierData={carrierData}
                />
              }
              path="/order-handover"
            />
            <Route element={<OrderSummary />} path="/order-summary" />
            <Route
              element={<ReverseHandoverSummary />}
              path="/reverse-handover-summary"
            />
            <Route element={<CreateOrder />} path="/create-order" />
            <Route element={<ScanOrders />} path="/scan-orders" />
            <Route
              element={<PredefinedProblems />}
              path="/predefined-problems"
            />
            <Route element={<AddIdealConsumption />} path="/packing-material" />
            <Route
              element={<AddIdealConsumption />}
              path="/add-ideal-consumptions"
            />
            <Route element={<ConsumptionReport />} path="/consumption-report" />
            <Route element={<Bypass />} path="/bypass" />
            <Route element={<MarkLostRequest />} path="/mark-lost-request" />
            <Route
              element={<AcceptRequestWithAudit />}
              path="/accept-request-audit/:product_id/:subLocation_id"
            />
            <Route
              element={<AdvanceSubLocationTransfer />}
              path="/advance-sublocation-transfer"
            />
            <Route
              element={<ContentInThirdBox />}
              path="/content-in-third-box/:boxname/:qty/:boxname2/:qty2/:session"
            />

            <Route
              element={<AdvanceSubLocationTransferSummary />}
              path="/advance-sublocation-transfer-summary"
            />
            <Route element={<SessionSummary />} path="/session-summary" />
            <Route element={<BoxQuantityAudit />} path="/box-qty-audit" />
            <Route
              element={<BoxUnitQuantityAudit />}
              path="/box-unit-qty-audit"
            />
            <Route
              element={<BoxAuditTransactionSummary />}
              path="/box-audit-transaction-summary"
            />
            <Route element={<Settings />} path="/settings" />
            <Route element={<CSVTable />} path="/upload-csv" />
            <Route
              element={<BypassorderSumarry />}
              path="/bypass-order-summary"
            />
            <Route
              element={<ByPassOrderMapping />}
              path="/bypass-order-mapping"
            />
            <Route element={<DeviceManagement />} path="/device-management" />
          </Route>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </Router>
    </DataProvider>
  );
};

export default App;
