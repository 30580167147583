import React, { useMemo, useRef, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import { sublocationHeader } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllFiltersPickingModuleActions,
  getLostSubLocationActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useCallback } from "react";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import moment from "moment";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const LostSubLocation = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { lostSubLocation, loading, status } = useSelector(
    (state) => state.lostSublocation
  );

  let service_name = "lost_sublocations_list";

  //   pagination code starts here
  const [page, setPage] = useState(1);

  const [lost_sub_location, setLost_sub_location] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, { service_name, page, token })
        .then((response) => {
          setLost_sub_location(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [service_name, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [filtersTrue, pagination, fetch]
  );

  const getLostSubLocation = useCallback(() => {
    lostSubLocation &&
      setLost_sub_location(lostSubLocation && lostSubLocation?.data);
  }, [lostSubLocation]);

  useEffect(() => {
    getLostSubLocation();
  }, [getLostSubLocation]);

  // filter code starts here
  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [picker_name, setPicker_name] = useState("");

  const [date_entry, setdate_entry] = useState("");

  const [zone_id, setZone_id] = useState("");
  const [zone_name, setZone_name] = useState("");

  const [aisle_id, setaisle_id] = useState("");
  const [aisle_name, setaisle_name] = useState("");

  const [sublocation_id, setsublocation_id] = useState("");
  const [sublocation_name, setsublocation_name] = useState("");

  const [product_id, setProduct_id] = useState("");
  const [product_name, setProduct_name] = useState("");

  const [picklist_id, setpicklist_id] = useState("");

  const handlePickers = (val) => {
    setFilterTrue(true);
    setUser_id(val?.id);
    setPicker_name(val?.value);
    handlePage(1, false);
  };

  const handleDate = (date) => {
    setFilterTrue(true);
    setdate_entry(date);
    handlePage(1, false);
  };

  const handleZone = (val) => {
    setFilterTrue(true);
    setZone_id(val?.id);
    setZone_name(val?.name);
    handlePage(1, false);
  };

  const handleAisle = (val) => {
    setFilterTrue(true);
    setaisle_id(val?.id);
    setaisle_name(val?.name);
    handlePage(1, false);
  };

  const handleSubLocation = (val) => {
    setFilterTrue(true);
    setsublocation_id(val?.id);
    setsublocation_name(val?.value);
    handlePage(1, false);
  };

  const handleSku = (val) => {
    setFilterTrue(true);
    setProduct_id(val?.id);
    setProduct_name(val?.name);
    handlePage(1, false);
  };

  const handlePicklistId = (e) => {
    setFilterTrue(true);
    setpicklist_id(e.target.value);
    handlePage(1, false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  let date = date_entry !== "" ? moment(date_entry).format("yyyy-MM-DD") : null;

  let filters = useMemo(() => {
    const dataToSend = {
      user_id,
      date,
      zone_id,
      aisle_id,
      sublocation_id,
      product_id,
      picklist_id,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    user_id,
    date,
    zone_id,
    aisle_id,
    sublocation_id,
    product_id,
    picklist_id,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "lost_sublocations_list",
        token,
        page,
        filters,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setLost_sub_location(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert]);
  // filter code ends here

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (filtersTrue === false && pagination === 0) {
      dispatch(getLostSubLocationActions(service_name, 1, token));
    }
  }, [dispatch, filtersTrue, pagination, service_name, token, fetchFilters]);

  // All Filter data for dropdown
  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers", "zones", "aisles", "sublocations", "sku"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let pickers_list = all_filters && all_filters?.pickers;
  let zones_list = all_filters && all_filters?.zones;
  let aisles_list = all_filters && all_filters?.aisles;
  let sub_locations_list = all_filters && all_filters?.sublocations;
  let sku_list = all_filters && all_filters?.sku;

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  const resteFilter = () => {
    setUser_id("");
    setPicker_name("");
    setdate_entry("");
    setZone_id("");
    setZone_name("");
    setaisle_id("");
    setaisle_name("");
    setsublocation_id("");
    setsublocation_name("");
    setProduct_id("");
    setProduct_name("");
    setpicklist_id("");
  };

  const [loadingCSV, setLoadingCsv] = useState(false);

  const handleLogs = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_lost_sublocations_list",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     user_id ||
  //     picker_name ||
  //     date_entry ||
  //     zone_id ||
  //     zone_name ||
  //     aisle_id ||
  //     aisle_name ||
  //     sublocation_id ||
  //     sublocation_name ||
  //     product_id ||
  //     product_name ||
  //     picklist_id
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   user_id,
  //   picker_name,
  //   date_entry,
  //   zone_id,
  //   zone_name,
  //   aisle_id,
  //   aisle_name,
  //   sublocation_id,
  //   sublocation_name,
  //   product_id,
  //   product_name,
  //   picklist_id,
  //   handlePage,
  // ]);

  const scrollContainerRef = useRef(null);

  const handleScroll = (scrollOffset) => {
    scrollContainerRef.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Lost SubLocation`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    title="Lost Sublocation"
                    handleData={handleLogs}
                    loadingCSV={loadingCSV}
                    width_new={true}
                    btnTitle="Download Log"
                  />

                  {/* dropdown grid container */}
                  <div className="dropdown-grid-row dropwon">
                    <div>
                      <Dropdown
                        placeholder="User Id"
                        dropdown={pickers_list && pickers_list}
                        value={picker_name}
                        onData={handlePickers}
                        showId={true}
                        loading={allFilter_loading}
                        noShow={false}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="Date"
                        selected={date_entry}
                        onChange={(date) => handleDate(date)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Zone"
                        dropdown={zones_list && zones_list}
                        value={zone_name}
                        onData={handleZone}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Aisle"
                        dropdown={aisles_list && aisles_list}
                        value={aisle_name}
                        onData={handleAisle}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Sub Location"
                        dropdown={sub_locations_list && sub_locations_list}
                        value={sublocation_name}
                        onData={handleSubLocation}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="SKU"
                        dropdown={sku_list && sku_list}
                        value={product_name}
                        onData={handleSku}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        name="picklist_id"
                        id="picklist_id"
                        value={picklist_id}
                        onChange={(e) => handlePicklistId(e)}
                        placeholder="Picklist Id"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Button
                    className="back-icon-cta"
                    onClick={() => handleScroll(-100)}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    className="forward-icon-cta"
                    onClick={() => handleScroll(100)}
                  >
                    <ArrowForwardIosIcon />
                  </Button>

                  {/* table */}
                  <div
                    className="table-wrapper-pagination"
                    ref={scrollContainerRef}
                  >
                    <div className="table-container-pagination">
                      {filter_Loading ? (
                        <LoaderAlone />
                      ) : (
                        <DataTable
                          header={sublocationHeader}
                          activityData={lost_sub_location && lost_sub_location}
                          Actportal={true}
                        />
                      )}
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : lostSubLocation && lostSubLocation?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LostSubLocation;
