export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    whiteSpace: "nowrap", // Allow text to wrap within the option
    overflow: "hidden", // Allow long words to break into multiple lines
    textOverflow: "ellipsis",
    fontSize: "12px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "12px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#E0E0E0",
    border: "none",
    boxShadow: "none",
    fontSize: "12px",
    minHeight: "48px",
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins_SemiBold",
    fontSize: "12px", // Replace 'Your desired font' with the font you want to use
    maxWidth: "300px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    // Add other menu styles if needed
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const customStylesSKU = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#6C6C6C",
    backgroundColor: state.isSelected ? "#fffff" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#fffff",
    border: "1px solid #E6E6E6",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "11px",
    "&:hover": {
      border: "1px solid #E6E6E6 !important",
    },
  }),
  // menu: (provided, state) => ({
  //   ...provided,
  //   maxHeight: "200px",
  // }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#6C6C6C" }),
};

export const RadiuscustomStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#E0E0E0",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "23px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const userIdArr = [101, 102, 103, 104, 105, 106, 107, 108, 109, 110];
