import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import { useCallback, useEffect, useState } from "react";
import ModalContainer from "../../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import { useAlert } from "react-alert";
import { Pagination } from "../../../Components/Pagination/Pagination";

const BoxToBoxTransfer = (props) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [sessionIds, setSessionIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedSession, setSelectionSession] = useState("0");
  const [sessionData, setSessionData] = useState({});
  const [boxName, setBoxName] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [box2Name, setBox2Name] = useState("");
  const [box2Data, setBox2Data] = useState({});

  const [boxpage, setBoxPage] = useState(1);
  const [totalBoxPage, setTotalBoxPage] = useState(0);

  useEffect(() => {
    const productsArray = Object.entries(box1Data?.products || {}).map(
      ([product_id, product]) => ({
        product_id,
        details: "Box",
        box_id: box1Data?.box_info?.id,
        ...product,
      })
    );
    setTotalBoxPage(Math.ceil(productsArray.length / 6));
  }, [box1Data]);

  let boxPageData = Object.entries(box1Data?.products || {}).map(
    ([product_id, product]) => ({
      product_id,
      details: "Box",
      box_id: box1Data?.box_info?.id,
      ...product,
    })
  );
  boxPageData = boxPageData.slice((boxpage - 1) * 6, boxpage * 6);

  const [boxpage2, setBoxPage2] = useState(1);
  const [totalBoxPage2, setTotalBoxPage2] = useState(0);

  useEffect(() => {
    const productsArray = Object.entries(box2Data?.products || {}).map(
      ([product_id, product]) => ({
        product_id,
        details: "Box",
        box_id: box2Data?.box_info?.id,
        ...product,
      })
    );
    setTotalBoxPage2(Math.ceil(productsArray.length / 6));
  }, [box2Data]);

  let boxPageData2 = Object.entries(box2Data?.products || {}).map(
    ([product_id, product]) => ({
      product_id,
      details: "Box",
      box_id: box2Data?.box_info?.id,
      ...product,
    })
  );
  boxPageData2 = boxPageData2.slice((boxpage2 - 1) * 6, boxpage2 * 6);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name, type) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          if (type === 1) {
            setBox1Data(data.data);
            setError("");
          } else {
            setBox2Data(data.data);
            setError2("");
          }
        } else {
          if (type === 1) {
            setBox1Data({});
            setError(data.msg);
          } else {
            setBox2Data({});
            setError2(data.msg);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const fetchSessionIds = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_all_list_sessions",
          token,
        }
      );
      if (data.status === 1) {
        setSessionIds(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert]);

  useEffect(() => {
    fetchSessionIds();
  }, [fetchSessionIds, props.sessionCounter]);

  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (!isEqual(props.selectedSession, selectedOption)) {
      setSelectedOption(props.selectedSession);
      setSelectionSession(props.selectedSession.id);
    }
  }, [props.selectedSession, selectedOption]);

  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {
      if (name === "boxName") {
        if (boxName !== "") {
          fetchBoxDetails(boxName, 1);
        } else {
          setBox1Data({});
          setError("");
        }
      } else {
        console.log(box2Name);
        if (box2Name !== "") {
          fetchBoxDetails(box2Name, 2);
        } else {
          setBox2Data({});
          setError2("");
        }
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else {
      setBox2Name(value);
    }
  };

  const fetchSessionRecord = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_session_record",
          token,
          id: Number(selectedSession),
        }
      );
      if (data.status === 1) {
        setSessionData(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, alert, selectedSession]);

  const handleSession = (option) => {
    setSelectionSession(option.id);
    props.setSelectedSession(option);
  };

  useEffect(() => {
    if (selectedSession !== "0") {
      fetchSessionRecord();
    }
  }, [selectedSession, fetchSessionRecord]);

  const handleClear = () => {
    setBox1Data({});
    setBox2Data({});
    setBoxName("");
    setBox2Name("");
  };

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(18);
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalProductId, setModalProductId] = useState("");
  const [message, setMessage] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          message={message}
          box_no_1={boxName}
          box_no_2={box2Name}
          session_id={selectedSession}
          handleClear={handleClear}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Select Session Id`}
              options={
                sessionIds &&
                sessionIds.map((option) => ({
                  value: option.name,
                  label: option.name,
                  id: option.id,
                }))
              }
              className="select"
              id="courierName"
              value={
                Object.keys(selectedOption).length === 0 ? null : selectedOption
              }
              styles={customStylesSKU}
              onChange={(selectedOption) => handleSession(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.UserName}
              label="Created By"
              placeholder="Select Session Id"
              id="created_by"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              value={sessionData?.createdOn}
              label="Start Date and Time"
              placeholder="Select Session Id"
              id="datetime"
              darked={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={boxName}
              label="Scan First Box"
              placeholder="Scan First Box"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              field={"boxName"}
              error={boxName !== "" && error}
              // handover={true}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Current Quantity",
                value:
                  box1Data?.box_info?.current_qty ||
                  box1Data?.box_info?.current_qty === 0
                    ? box1Data?.box_info?.current_qty
                    : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value:
                  box1Data?.box_info?.qty || box1Data?.box_info?.qty === 0
                    ? box1Data?.box_info?.qty
                    : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box1Data?.box_info?.SubLocation
                  ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box1Data?.products && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={boxPageData}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
        {box1Data?.products && Object?.keys(box1Data?.products)?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`Boxproduct-${boxpage}`}
              page={boxpage}
              isLoading={false}
              totalPage={totalBoxPage}
              onPageChange={(page) => setBoxPage(page)}
            />
          </div>
        )}
      </div>
      <div className="summary-box mt-3">
        <div className="grid-delivery-partner-info">
          <InputField
            value={box2Name}
            label="Scan Second Box"
            placeholder="Scan Second Box"
            id="scanbox"
            scan={true}
            readOnly={false}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            field={"box2Name"}
            error={box2Name !== "" && error2}
          />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Current Quantity",
                value:
                  box2Data?.box_info?.current_qty ||
                  box2Data?.box_info?.current_qty === 0
                    ? box2Data?.box_info?.current_qty
                    : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value:
                  box2Data?.box_info?.qty || box2Data?.box_info?.qty === 0
                    ? box2Data?.box_info?.qty
                    : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box2Data?.box_info?.SubLocation
                  ? `${box2Data?.box_info?.SubLocation}>>${box2Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box2Data?.products &&
              Object?.keys(box2Data?.products)?.length > 0 && (
                <DataTable
                  header={["SKU Code", "Qty", "Action"]}
                  boxboxtransfer={boxPageData2}
                  onData={handleModal}
                  onDetail={setModalDetail}
                  onHeadingIndex={setModalBoxId}
                  assign_Picklist_id={setModalProductId}
                />
              )}
          </div>
        </div>
        {box2Data?.products && Object?.keys(box2Data?.products)?.length > 0 && (
          <div className="rendering-pagination">
            <Pagination
              uniqueKey={`Boxproduct2-${boxpage2}`}
              page={boxpage2}
              isLoading={false}
              totalPage={totalBoxPage2}
              onPageChange={(page) => setBoxPage2(page)}
            />
          </div>
        )}
      </div>
      <div className="mt-3 btn-container">
        <button
          className="submit-btn"
          onClick={() => {
            setIsOpen(true);
            setModalIndex(16);
            setMessage(
              `Are you sure you want to move ${
                box1Data?.box_info?.current_qty
              } units from Box ${boxName} to Box ${box2Name}, resulting in a total of ${
                Number(box2Data?.box_info?.current_qty) +
                Number(box1Data?.box_info?.current_qty)
              } units in Box ${box2Name}?`
            );
          }}
          disabled={
            boxName === "" ||
            box2Name === "" ||
            selectedSession === "0" ||
            selectedSession === ""
          }
          style={{ backgroundColor: "#26A6FE", margin: "0", maxWidth: "200px" }}
        >
          Put Content of First Box into Second Box
        </button>
        <button
          className="submit-btn"
          onClick={() => {
            setIsOpen(true);
            setModalIndex(17);
            setMessage(
              `Are you sure you want to move ${
                box2Data?.box_info?.current_qty
              } units from Box ${box2Name} to Box ${boxName}, resulting in a total of ${
                Number(box2Data?.box_info?.current_qty) +
                Number(box1Data?.box_info?.current_qty)
              } units in Box ${boxName}?`
            );
          }}
          style={{ margin: "0", maxWidth: "200px" }}
          disabled={
            boxName === "" ||
            box2Name === "" ||
            selectedSession === "0" ||
            selectedSession === ""
          }
        >
          Put Content of Second Box into First Box
        </button>
        <button
          className="submit-btn"
          onClick={() =>
            navigate(
              `/content-in-third-box/${boxName}/${box1Data?.box_info?.current_qty}/${box2Name}/${box2Data?.box_info?.current_qty}/${selectedSession}`
            )
          }
          style={{ backgroundColor: "#916FDF", margin: "0", maxWidth: "200px" }}
          disabled={
            boxName === "" ||
            box2Name === "" ||
            selectedSession === "0" ||
            selectedSession === ""
          }
        >
          Put Content of Both Boxes into Third Box
        </button>
      </div>
    </>
  );
};

export default BoxToBoxTransfer;
