import React, { useCallback, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./FirstSecondBox.css";
import { Button } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";
// import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { useAlert } from "react-alert";
// import { DeletePredefinedProblemActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
// import { useEffect } from "react";
// import { useState } from "react";
// import { DELETE_PREDEFINED_PROBLEMS_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
// import { deleteToteActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
// import { useEffect } from "react";

const FirstSecondBox = (props) => {
  const { close } = props;
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "move_box_to_box",
          token,
          box_no_1: props.box_no_1,
          box_no_2: props.box_no_2,
          type: "box2",
          session_id: props.session_id,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        fetchBoxLabel(props.box_no_2);
        props.handleClear();
        closeModal();
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  return (
    <>
      <HeadingTitle
        colorRed={true}
        title="Put Content of First Box into Second Box"
        closeModal={close}
      />

      <div className="delete-tote-message">
        <p>{props.message}</p>

        <Button type="submit" onClick={closeModal}>
          No
        </Button>
        <Button type="submit" onClick={handleSubmit}>
          {loading ? <Loader /> : "Yes"}
        </Button>
      </div>
    </>
  );
};

export default FirstSecondBox;
