import React, { useCallback, useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import DataTable from "../../Components/Table/DataTable";
import Select from "react-select";
import Loader from "../../Components/Loader/Loader";
import InputField from "../../Components/InputField/InputField";
import { customStylesSKU } from "../../Components/DropdownStyles/DropdownStyles";
import { useNavigate, Link, useParams } from "react-router-dom";
import ArrowIcon from "../../Assets/Icons/SideBar-Icons/arrow-left.png";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import ModalContainer from "../../Components/Modal/Modal";
import { useAlert } from "react-alert";
import { Pagination } from "../../Components/Pagination/Pagination";

const ContentInThirdBox = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { boxname, qty, boxname2, qty2, session } = useParams();
  const [existing, setExisting] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleBoxType = (value) => {
    if (value.value === "Existing Box") {
      setExisting(true);
    } else {
      setExisting(false);
    }
  };

  const [sublocation, setSubLocation] = useState("");
  const [boxNo, setBoxNo] = useState("");
  const [box3Name, setBox3Name] = useState("");
  const [box3Data, setBox3Data] = useState({});
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const [boxpage, setBoxPage] = useState(1);
  const [totalBoxPage, setTotalBoxPage] = useState(0);

  useEffect(() => {
    const productsArray = Object.entries(box3Data?.products || {}).map(
      ([product_id, product]) => ({
        product_id,
        details: "Box",
        box_id: box3Data?.box_info?.id,
        ...product,
      })
    );
    setTotalBoxPage(Math.ceil(productsArray.length / 6));
  }, [box3Data]);

  let boxPageData = Object.entries(box3Data?.products || {}).map(
    ([product_id, product]) => ({
      product_id,
      details: "Box",
      box_id: box3Data?.box_info?.id,
      ...product,
    })
  );
  boxPageData = boxPageData.slice((boxpage - 1) * 6, boxpage * 6);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          setBox3Data(data.data);
          setError("");
        } else {
          setBox3Data({});
          setError(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const fetchBoxNo = useCallback(
    async (sub_location_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "ajax_new_box_label",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          setBoxNo(data?.data?.unique_box_no);
          setError2("");
        } else {
          setBoxNo("");
          setError2(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {
      if (name === "box3Name") {
        if (box3Name !== "") {
          fetchBoxDetails(box3Name, 1);
        } else {
          setBox3Data({});
          setError("");
        }
      } else {
        if (sublocation !== "") {
          fetchBoxNo(sublocation);
        } else {
          setBoxNo("");
          setError2("");
        }
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "box3Name") {
      setBox3Name(value);
    } else {
      setSubLocation(value);
    }
  };

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(18);
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          details={modalDetail}
          closeModal={closeModal}
          box_id={modalBoxId}
          product_id={modalProductId}
        />
      );
    }
    return null;
  };

  const handleSubmitExisting = async () => {
    try {
      if (error !== "") {
        alert.error("Please resolve the error first.");
        return;
      }
      if (session === "0" || session === "") {
        alert.error("Please select the session ID.");
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "move_box_to_box",
          token,
          box_no_1: boxname,
          box_no_2: boxname2,
          box_no_3: box3Name,
          type: "box3",
          session_id: session,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        fetchBoxLabel(box3Name);
        alert.success("Submitted Successfully");
        navigate(-1);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const handleSubmitNew = async () => {
    try {
      if (error2 !== "") {
        alert.error("Please resolve the error first.");
        return;
      }
      if (session === "0" || session === "") {
        alert.error("Please select the session ID.");
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "move_box_to_box",
          token,
          box_no_1: boxname,
          box_no_2: boxname2,
          type: "new",
          sub_location_id: sublocation,
          box_no_3: boxNo,
          session_id: session,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        fetchBoxLabel(boxNo);
        navigate(-1);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <>
      {renderModal()}
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData
          title={existing ? `Content Into Third Box` : "Content Into New Box"}
        />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-picker-container">
            <div className="header-flex-row">
              <div>
                <Link onClick={() => navigate(-1)} className="go-back-row">
                  <div>
                    <img src={ArrowIcon} alt="" />
                  </div>
                  <div>
                    <h1 className="heading">
                      {existing
                        ? "Put Content of Both Boxes in Third Box"
                        : "Put Content of Both Boxes in New Box"}
                    </h1>
                  </div>
                </Link>
              </div>
            </div>

            <div className="summary-box mt-3">
              <div className="grid-delivery-partner-info">
                <div>
                  <label htmlFor="courierName">Select Box Type</label>
                  <Select
                    placeholder={`Existing Box`}
                    options={[
                      {
                        value: "Existing Box",
                        label: "Existing Box",
                      },
                      {
                        value: "New Box",
                        label: "New Box",
                      },
                    ]}
                    className="select"
                    //value={carier_id}
                    styles={customStylesSKU}
                    onChange={(selectedOption) => handleBoxType(selectedOption)}
                  />
                </div>
              </div>

              {existing ? (
                <>
                  <div className="grid-delivery-partner-info mt-3">
                    <div className="mt-56">
                      <InputField
                        // loading={handoverNameLoading}
                        value={box3Name}
                        label="Scan Box"
                        placeholder="Scan Box"
                        id="scanbox"
                        scan={true}
                        readOnly={false}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        field={"box3Name"}
                        error={box3Name !== "" && error}
                      />
                    </div>
                  </div>

                  <div className="table-container-pagination overflow-hidden">
                    <div className="overflow-auto">
                      {box3Data?.products &&
                        Object?.keys(box3Data?.products)?.length > 0 && (
                          <DataTable
                            header={["SKU Code", "Qty", "Action"]}
                            boxboxtransfer={boxPageData}
                            onData={handleModal}
                            onDetail={setModalDetail}
                            onHeadingIndex={setModalBoxId}
                            assign_Picklist_id={setModalProductId}
                          />
                        )}
                    </div>
                  </div>
                  {box3Data?.products &&
                    Object?.keys(box3Data?.products)?.length > 0 && (
                      <div className="rendering-pagination">
                        <Pagination
                          uniqueKey={`Boxproduct-${boxpage}`}
                          page={boxpage}
                          isLoading={false}
                          totalPage={totalBoxPage}
                          onPageChange={(page) => setBoxPage(page)}
                        />
                      </div>
                    )}
                  <div className="mt-3 text-center ">
                    {box3Data?.box_info && (
                      <p className="para box-info">
                        The existing box has{" "}
                        <strong>{box3Data?.box_info?.current_qty}</strong>{" "}
                        units. New boxes, Box <strong>{boxname}</strong> will
                        contribute <strong>{qty}</strong> units, and Box
                        <strong> {boxname2}</strong> will contribute{" "}
                        <strong>{qty2}</strong> units. Now, the existing Box{" "}
                        <strong>{box3Name}</strong> will have a total of{" "}
                        <strong>
                          {" "}
                          {Number(qty) +
                            Number(qty2) +
                            Number(box3Data?.box_info?.current_qty)}{" "}
                        </strong>
                        units.
                      </p>
                    )}
                  </div>

                  <div className="mt-3 btn-container">
                    <button
                      className="submit-btn"
                      onClick={handleSubmitExisting}
                      style={{ margin: "0", maxWidth: "200px" }}
                      disabled={box3Name === ""}
                    >
                      {loading ? <Loader /> : "Submit"}
                    </button>
                    {/* <button
                      className="submit-btn"
                      style={{
                        backgroundColor: "#26A6FE",
                        margin: "0",
                        maxWidth: "200px",
                      }}
                      disabled={box3Name === ""}
                      onClick={() => fetchBoxLabel(box3Name)}
                    >
                      Create Box Label
                    </button> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="grid-delivery-partner-info mt-3">
                    <div className="mt-56">
                      <InputField
                        // loading={handoverNameLoading}
                        value={sublocation}
                        label="Scan Sub Location"
                        placeholder="Scan Sub Location"
                        id="scanbox"
                        scan={true}
                        readOnly={false}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        field={"sublocation"}
                        error={sublocation !== "" && error2}
                      />
                    </div>
                    <div className="mt-56">
                      <InputField
                        value={boxNo}
                        label="Box Number"
                        placeholder="Scan Sub Location First"
                        id="scanbox"
                        darked={true}
                      />
                    </div>
                  </div>

                  <div className="mt-3 text-center ">
                    {boxNo && (
                      <p className="para box-info">
                        Box Number <strong>{boxNo}</strong>: The result shows{" "}
                        <strong>{qty}</strong> units from Box{" "}
                        <strong>{boxname}</strong> and <strong>{qty2}</strong>{" "}
                        units from Box <strong>{boxname2}</strong>, making a
                        total of <strong>{Number(qty) + Number(qty2)}</strong>{" "}
                        units.
                      </p>
                    )}
                  </div>

                  <div className="mt-3 btn-container">
                    <button
                      className="submit-btn"
                      style={{ margin: "0", maxWidth: "200px" }}
                      disabled={
                        sublocation === "" ||
                        boxNo === "" ||
                        session === "0" ||
                        session === ""
                      }
                      onClick={handleSubmitNew}
                    >
                      {loading ? <Loader /> : "Submit"}
                    </button>
                    {/* <button
                      className="submit-btn"
                      style={{
                        backgroundColor: "#26A6FE",
                        margin: "0",
                        maxWidth: "200px",
                      }}
                      disabled={boxNo === ""}
                      onClick={() => fetchBoxLabel(boxNo)}
                    >
                      Create Box Label
                    </button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentInThirdBox;
