import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import Dropdown from "../../Components/Dropdown/Dropdown";
import axios from "axios";
import DatePicker from "react-datepicker";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../Components/Modal/Modal";
import moment from "moment";

const SessionSummary = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [dataloading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeloading, setCloseLoading] = useState(false);
  const [closingId, setClosingId] = useState("0");
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [userIds, setUserIds] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const [filters, setFilters] = useState({
    user_id: "",
    id: "",
    created_from: "",
    created_to: "",
  });

  const handlePage = useCallback((page) => {
    setPage(page);
  }, []);

  const handleDateFrom = (date) => {
    setFilters({
      ...filters,
      created_from: date ? moment(date).format("yyyy-MM-DD") : "",
    });
    handlePage(1);
  };

  const handleDateTo = (date) => {
    setFilters({
      ...filters,
      created_to: date ? moment(date).format("yyyy-MM-DD") : "",
    });
    handlePage(1);
  };

  const handleUserId = (option) => {
    setSelectedUser(option);
    setFilters({ ...filters, user_id: option?.id });
    handlePage(1);
  };

  const resetFilter = () => {
    setFilters({
      user_id: "",
      id: "",
      created_from: "",
      created_to: "",
    });
    setSelectedUser();
  };

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  let token = localStorage.getItem("Web-api-v2-token");

  const handleNewSession = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "create_new_session",
          token,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Created Successfully");
        navigate(`/advance-sublocation-transfer?session_id=${data.session_id}`);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const controllerRef = useRef(null);

  const fetchSessions = useCallback(async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      setDataLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_all_user_session",
          token,
          page,
          conditions: filters,
        },
        { signal: controller.signal }
      );
      setDataLoading(false);
      if (data.status === 1) {
        setData(data.data);
        setTotalPages(data?.total_pages);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Previous request canceled", err?.message);
      } else {
        setDataLoading(false);
        alert.error("Something went wrong");
      }
    }
  }, [alert, token, page, filters]);

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "get_users",
          token,
        }
      );

      if (data.status === 1) {
        setUserIds(data.data);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  }, [alert, token]);

  useEffect(() => {
    fetchSessions();
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [fetchSessions]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleCloseSession = async (id) => {
    try {
      setClosingId(id);
      setCloseLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "close_session",
          token,
          id,
        }
      );
      setCloseLoading(false);
      if (data.status === 1) {
        alert.success("Closed Successfully");
        fetchSessions();
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setCloseLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(20);
  const [modalSessionId, setModalSessionId] = useState("0");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          modalSessionId={modalSessionId}
        />
      );
    }
    return null;
  };

  return (
    <Fragment>
      {renderModal()}
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Session Summary`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Headline
              title="Session Summary"
              hide={true}
              createBtn={"Create New Session"}
              handleNewSession={handleNewSession}
              loading={loading}
            />
            {/* Header Title Component row ends here */}

            <div className="dropdown-grid-row dropwon">
              <div className="date_handover">
                <input
                  type="number"
                  name="session_id"
                  id="session_id"
                  min={0}
                  onChange={(e) => {
                    setFilters({ ...filters, id: e.target.value });
                    handlePage(1);
                  }}
                  placeholder="Enter Session ID"
                  value={filters?.id}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Created By"
                  dropdown={
                    userIds &&
                    Object.entries(userIds).map(([id, name]) => ({
                      id: Number(id),
                      name,
                    }))
                  }
                  onData={handleUserId}
                  value={selectedUser}
                />
              </div>
              <div>
                <DatePicker
                  className="date-picker"
                  placeholderText="Creation Date From"
                  selected={filters?.created_from}
                  onChange={(date) => handleDateFrom(date)}
                />
              </div>
              <div>
                <DatePicker
                  className="date-picker"
                  placeholderText="Creation Date To"
                  selected={filters?.created_to}
                  onChange={(date) => handleDateTo(date)}
                />
              </div>

              <div>
                <div className="clear-filter">
                  <Button onClick={resetFilter}>Reset Filter</Button>
                </div>
              </div>
            </div>

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination overflow-hidden">
                <div className="overflow-auto">
                  {dataloading ? (
                    <LoaderAlone />
                  ) : (
                    <DataTable
                      header={[
                        "Session ID",
                        "Created By",
                        "Created On",
                        "Total Transaction in this session",
                        "Action",
                      ]}
                      sessionSummary={data}
                      handleCloseSession={handleCloseSession}
                      closeloading={closeloading}
                      closingId={closingId}
                      onData={handleModal}
                      setModalSessionId={setModalSessionId}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="rendering-pagination">
              <Pagination
                uniqueKey={`SessionSummary-${page}`}
                page={page}
                isLoading={dataloading}
                totalPage={totalPages}
                onPageChange={handlePage}
              />
            </div>
            {/* Table Component ends here */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SessionSummary;
