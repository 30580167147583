import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import Headline from "../../Components/HeadlineSpecial/Headline";
import "./LostSubLocation.css";
import BoxToBoxTransfer from "./TabContents/BoxToBoxTransfer";
import BoxQuantityAddition from "./TabContents/BoxQuantityAddition";
import BoxToSubLocationTransfer from "./TabContents/BoxToSublocationTransfer";
import SubLocationToBoxTransfer from "./TabContents/SubLocationToBoxTransfer";
import SubLocationToSubLocationTransfer from "./TabContents/SubLocationToSubLocationTransfer";
import { useAlert } from "react-alert";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { useLocation } from "react-router-dom";

const AdvanceSubLocationTransfer = () => {
  const alert = useAlert();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const [isToggle, setIsToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionCounter, setSessionCounter] = useState(0);
  const [selectedSession, setSelectedSession] = useState({});

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  let token = localStorage.getItem("Web-api-v2-token");

  const handleNewSession = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "create_new_session",
          token,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Created Successfully");
        setSessionCounter((prev) => prev + 1);
        setSelectedSession({
          value: data?.session_id,
          label: data?.session_id,
          id: Number(data?.session_id),
        });
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "Box to Box Transfer",
    "Box Quantity Additions",
    "Box to Sub Location Transfer",
    "Sub Location to Box Transfer",
    "Sub Location to Sub Location Transfer",
  ];

  const tabContent = [
    <BoxToBoxTransfer
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      sessionCounter={sessionCounter}
    />,
    <BoxQuantityAddition
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      sessionCounter={sessionCounter}
    />,
    <BoxToSubLocationTransfer
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      sessionCounter={sessionCounter}
    />,
    <SubLocationToBoxTransfer
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      sessionCounter={sessionCounter}
    />,
    <SubLocationToSubLocationTransfer
      selectedSession={selectedSession}
      setSelectedSession={setSelectedSession}
      sessionCounter={sessionCounter}
    />,
  ];

  useEffect(() => {
    if (sessionId) {
      setSelectedSession({
        value: sessionId,
        label: sessionId,
        id: Number(sessionId),
      });
    }
  }, [sessionId]);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Advance Sub Location Transfer`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header isToggle={isToggle} />
        <div className="wrapper-picker-container">
          <Headline
            title="Advance Sub Location Transfer"
            hide={true}
            createBtn={"Create New Session"}
            handleNewSession={handleNewSession}
            loading={loading}
          />

          {/* Tab Module Component starts here */}
          <div className="Tab-module">
            <nav
              className="update-tab-module-grid-row-2"
              aria-label="Tabs"
              role="tablist"
              aria-orientation="horizontal"
            >
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`tab-button ${
                    activeTab === index ? "active" : ""
                  }`}
                  id={`card-type-tab-item-${index + 1}`}
                  aria-selected={activeTab === index}
                  role="tab"
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          <div>
            {tabContent.map((content, index) => (
              <div
                id={`card-type-tab-${activeTab}`}
                role="tabpanel"
                aria-labelledby={`card-type-tab-item-${activeTab}`}
                className={activeTab === index ? "" : "hidden"}
              >
                {content}
              </div>
            ))}
          </div>
          {/* Tab Module Component ends here */}
        </div>
      </div>
    </div>
  );
};

export default AdvanceSubLocationTransfer;
