export const TableData = [
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
  {
    id: "F-1314",
    title: "Dummy Title",
    createdOn: "01/Jun/2023 10:07 am",
    portal: "Myntra",
    picklistType: "Single",
    TotalQty: "10",
    Picked: "6",
    Pending: "4",
    Picklist_Provide_type: "Automatic",
    status: "Active",
    action: "Btn",
  },
];

export const secondaryOrders = [
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
  {
    orderNo: 1234567890,
    AWBNo: 5293314357,
    TotalQty: 50,
    PickedQty: 30,
    CourierName: "Delivery",
    PortalName: "Myntra",
  },
];

export const PickerManagementArr = [
  {
    UserId: 101,
    Username: "Darlene Robertson",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse7.png"),
  },
  {
    UserId: 102,
    Username: "Guy Hawkins",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse8.png"),
  },
  {
    UserId: 103,
    Username: "Ronald Richards",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse9.png"),
  },
  {
    UserId: 104,
    Username: "Savannah Nguyen",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse10.png"),
  },
  {
    UserId: 105,
    Username: "Eleanor Pena",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    UserId: 106,
    Username: "Devon Lane",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse12.png"),
  },
  {
    UserId: 107,
    Username: "Ralph Edwards",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    UserId: 108,
    Username: "Albert Flores",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse7.png"),
  },
  {
    UserId: 109,
    Username: "Dianne Russell",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse9.png"),
  },
  {
    UserId: 110,
    Username: "Jenny Wilson",
    TotalPicked: 70,
    Pending: 30,
    Required: 10,
    currentSpeed: "5 Min",
    lastScan: "11:45 AM",
    idleSince: "12:05 PM",
    uri: require("../../Assets/Icons/Users/Ellipse12.png"),
  },
];

export const PaginatedHeaderTable = [
  // "User Id",
  "Username",
  "Total Picked",
  "Pending",
  "Required",
  "Current Speed",
  "Last Scan",
  "Idle Since",
  "Action",
];

export const orderTable = [
  "ID",
  <>
    Order <br /> Code
  </>,
  "Portal",
  <>
    Purchase <br /> On
  </>,
  <>
    Created <br /> on
  </>,
  <>
    Tracking <br /> Number
  </>,
  <>
    Label <br /> Generated
  </>,
  <>
    Courier <br /> Name
  </>,
  "Picklist",
  "Action",
];

export const PickListTableArr = [
  {
    UserId: 101,
    Username: "Savannah",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse7.png"),
  },
  {
    UserId: 102,
    Username: "Arlene McCoy",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse8.png"),
  },
  {
    UserId: 103,
    Username: "Not Assigned",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
  },
  {
    UserId: 104,
    Username: "Robert Fox",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse10.png"),
  },
  {
    UserId: 105,
    Username: "Not Assigned",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
  },
  {
    UserId: 106,
    Username: "Not Assigned",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
  },
  {
    UserId: 107,
    Username: "Arlene McCoy",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    UserId: 108,
    Username: "Leslie Alexander",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse7.png"),
  },
  {
    UserId: 109,
    Username: "Robert Fox",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse8.png"),
  },
  {
    UserId: 110,
    Username: "Cody Fisher",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse9.png"),
  },
  {
    UserId: 111,
    Username: "Devon Lane",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse10.png"),
  },
  {
    UserId: 112,
    Username: "Savannah",
    Picklist_id_Type: "Pick-175 Single",
    QuantityRequired: 100,
    QuantityPicked: 60,
    QuantityPending: 40,
    Portal: "Myntra",
    CreateDate: "01/Jun/2023 10:07 am",
    PicklistProvideType: "Automatic",
    LostMarkedSublocation: "G22, Shell4, Rack No.4, Aisle 4, Zone ABC",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
];

export const PickListManagementHeaderArr = [
  <>
    User <br /> Id
  </>,
  "Username",
  <>
    Picklist Id / <br /> Type / <br /> Created By
  </>,
  <>
    Qty. <br /> Required
  </>,
  <>
    Qty. <br /> Picked
  </>,
  <>
    Qty. <br /> Pending
  </>,
  <>
    Found <br /> Qty.
  </>,
  "Portal",
  <>
    Create <br /> Date
  </>,
  <>
    Picklist <br /> Provide <br /> Type
  </>,
  <>
    Lost <br /> Marked <br /> Sublocation
  </>,
  "Action",
  "Status",
];

export const BypassOrderSummaryTableArr = [
  <>
    Order <br />
    Number
  </>,
  <>
    AWB <br />
    Number
  </>,
  <>
    Portal <br />
    Name
  </>,
  <>
    Courier <br />
    Name
  </>,
  <>
    Order <br />
    Type
  </>,
  <>
    Bypass <br />
    By
  </>,
  <>
    Bypass <br />
    Date
  </>,
  <>
    Current <br />
    Status
  </>,
  <>
    Total SKU’s <br />
    Qty
  </>,
  <>
    Correctly <br />
    SKU’s Sent
  </>,
  <>
    Wrong <br />
    SKU’s Sent
  </>,
  <>
    Label <br />
    Generate
  </>,
];

export const BypassOrderSummaryTableData = [
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by_images: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
  {
    orderNo: "OR12345",
    AWBNo: "AWB5895",
    portalName: "Myntra",
    courierName: "Delhivery",
    order_Type: "Single",
    bypass_by: require("../../Assets/Icons/Icon_Bypass.png"),
    bypass_by_name: "Kristin_101",
    bypass_date: "23 May 2023",
    bypass_time: "10:15:08 AM",
    current_status: "Picked",
    sku: require("../../Assets/Icons/sku.png"),
    sku_count: "50",
    correctly_sku_sent: "40",
    wrong_sku_sent: "10",
    label_generate: "Yes",
  },
];

export const orderHandoverSummaryTable = [
  <>
    Handover <br /> Name
  </>,
  "Date",
  <>
    Courier <br /> Name
  </>,
  <>
    Created <br /> by
  </>,
  <>
    Total <br /> Orders
  </>,
  <>
    Total <br /> Items
  </>,
  <>
    Total <br /> Bags
  </>,
  "Action",
];

export const dateArr = [
  "Today",
  "Yesterday",
  "Past 24 Hours",
  "This Week",
  "This Month",
];

export const home_date = [
  { id: "today", name: "Today" },
  { id: "yesterday", name: "Yesterday" },
  { id: "current_month", name: "Current Month" },
  { id: "last_month", name: "Last Month" },
];

export const PickListViewHeadArr = [
  "Product Picture",
  "SKU",
  "Parent SKU",
  "Required Qty",
  "Picked Qty",
  "Pending Qty",
  "Action",
];

export const order_item_header = [
  "Order Id",
  "Order Code",
  "Tracking No",
  "SKU",
  "Party SKU",
  "Old SKU",
  "Facility Code",
  "Sub Order Code",
  "Courier Name",
];

export const picklist_details_header = [
  "Id",
  "Qty",
  <>
    Found <br /> Qty
  </>,
  <>
    Lost <br /> Qty
  </>,
  <>
    Scan <br /> Qty
  </>,
  "SKU",
  <>
    Sub <br /> Location
  </>,
];

export const allowed_status_arr = ["Hold", "Closed"];
export const allowed_status_arr_if_completed = [];

export const Assigned_list = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

export const tote_status = [
  { id: 0, name: "Vacant" },
  { id: 1, name: "InUse" },
];

export const mark_lost_not_found = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

export const mark_lost_arr = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

export const PickListViewData = [
  {
    ProductPicture: require("../../Assets/Icons/Users/product1.png"),
    SKU: "SKU",
    ParentSKU: "5293314357",
    RequiredQty: 10,
    PickedQty: 6,
    PendingQty: 4,
  },
  {
    ProductPicture: require("../../Assets/Icons/Users/product2.png"),
    SKU: "SKU",
    ParentSKU: "5293314357",
    RequiredQty: 10,
    PickedQty: 6,
    PendingQty: 4,
  },
  {
    ProductPicture: require("../../Assets/Icons/Users/product3.png"),
    SKU: "SKU",
    ParentSKU: "5293314357",
    RequiredQty: 10,
    PickedQty: 6,
    PendingQty: 4,
  },
  {
    ProductPicture: require("../../Assets/Icons/Users/product4.png"),
    SKU: "SKU",
    ParentSKU: "5293314357",
    RequiredQty: 10,
    PickedQty: 6,
    PendingQty: 4,
  },
  {
    ProductPicture: require("../../Assets/Icons/Users/product5.png"),
    SKU: "SKU",
    ParentSKU: "5293314357",
    RequiredQty: 10,
    PickedQty: 6,
    PendingQty: 4,
  },
];

export const secondaryPickListHeader = [
  <>
    Select <br /> Order <br /> Ids
  </>,
  "Username",
  <>
    Portal <br /> Name
  </>,
  <>
    Courier <br /> Name
  </>,
  <>
    AWD <br /> No.
  </>,
  <>
    Order <br /> No.
  </>,
  <>
    Qty. <br /> Required
  </>,
  <>
    Qty. <br /> Picked
  </>,
  <>
    Qty. <br /> Pending
  </>,
  <>
    Tote <br /> Id
  </>,
  <>
    Purchase <br /> Date
  </>,
  "Status",
  "ERP Status",
  "ByPassed",
  "Action",
];

export const SecondaryPicklistData = [
  {
    userId: "101",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "102",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    userId: "103",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "104",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    userId: "105",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "106",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    userId: "107",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "108",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    userId: "109",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "110",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
  {
    userId: "111",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    userId: "112",
    Username: "Cameron",
    PortalName: "Myntra",
    CourierName: "Delivery",
    AWDNo: 5293314357,
    OrderNo: 123456789,
    QuantityRequired: 100,
    QuantityPicked: 70,
    QuantityPending: 30,
    ToteId: 13141997,
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
  },
];

export const PortalManagementHeader = [
  "Portal Name",
  "Total Orders Count",
  "Total Order Picked",
  "Total Orders Label Generate",
  "Total Order Handed Over",
  "Multi Order Count",
  "Multi Order Units Count",
  // "Action",
];

export const PortalManagementArr = [
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    PortalName: "Myntra",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "900/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
];

export const productWiseHeader = [
  "Portal Name",
  "Total Orders Count",
  "Total Order Picked",
  "PendingOrders",
  "Total Serial Codes",
  "Picked Serial Codes",
  "Serial Code Label Generate",
  "Serial Code Handover",
  "Action",
];

export const orderWiseHeader = [
  "Courier Name",
  "Total Orders Count",
  "Total Order Picked",
  "Total Orders Label Generate",
  "Total Order Handed Over",
  "Multi Order Count",
  "Multi Order Units Count",
];
export const cProductWiseHeader = [
  "Courier Name",
  "Total Orders Count",
  "Total Order Picked",
  "PendingOrders",
  "Total Serial Codes",
  "Picked Serial Codes",
  "Serial Code Label Generate",
  "Serial Code Handover",
  "Action",
];

export const combinationofOrderWiseandProductWise = [
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Express",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Express",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Express",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
  {
    CourierName: "Delhivery",
    TotalOrdersCount: 1000,
    TotalOrderPicked: "800/1000",
    TotalOrdersLabelGenerate: "600/800",
    TotalOrderHandedOver: "400/600",
    MultiOrderCount: "100/600",
    MultiOrderUnitsCount: "300/600",
    PendingOrders: "600/800",
    TotalSerialCodes: 12000,
    PickedSerialCodes: 4235,
    SerialCodeLabelGenerate: 7765,
    SerialCodeHandover: 5000,
  },
];

export const activityHeader = [
  "Username",
  <>
    Picklist <br /> Name
  </>,
  <>
    Serial <br /> Code
  </>,
  <>
    SKU <br /> Code
  </>,
  <>
    Sub <br /> location
  </>,
  "Aisle",
  "Zone",
  "TimeStamp",
];
export const sublocationHeader = [
  "Username",
  <>PicklistName</>,
  "SKU",
  <>
    Sub <br /> location
  </>,
  "Aisle",
  "Zone",
  <>
    Lost <br /> Qty
  </>,
  <>
    System
    <br /> Lost
    <br /> Qty
  </>,
];

export const MarkLostRequestHeader = [
  "SubLocation",
  <>SKU</>,
  <>
    Requested <br /> By
  </>,
  <>
    Requested On <br /> (Current Request)
  </>,
  <>
    Request <br />
    Count
  </>,
  "Aging",
  "Action",
];

export const RequestCountHeader = [
  "Request Id",
  "Request By",
  "Request On",
  "Aging",
  "Picklist ID",
  "Picklist Name",
  <>
    Requested <br /> Lost Qty
  </>,
];

export const WithoutAuditHeader = [
  "SKU Code",
  "Location",
  <>
    Total Current <br /> Qty
  </>,
  <>
    Current Qty of <br /> Lost Request SKU
  </>,
  <>
    Qty of Lost Request <br /> SKU (at the time of request)
  </>,
  "Action",
];

export const AdvanceSubLocationTransferSummaryHeader = [
  "Session ID",
  <>
    Created <br /> By
  </>,
  <>
    Created <br /> Date
  </>,
  <>
    Transaction <br /> Type
  </>,
  <>
    Box-1 <br />
    Name
  </>,
  <>
    Sub Location <br />
    Name
  </>,
  <>
    Box-2 <br />
    Name
  </>,
  <>
    Sub Location <br />
    Name
  </>,
  <>
    Description <br />
    Box Name
  </>,
  <>
    Description <br />
    Sublocation
  </>,
  "Action",
];

export const AuditSummaryHeader = [
  <>
    Audit <br /> Transaction No
  </>,
  "Location",
  "Boxes",
  "Sublocation",
  "Type",
  <>
    Created <br /> By
  </>,
  <>
    Created <br /> Date
  </>,
  <>
    Box Qty <br />
    Audit Pass %
  </>,
  <>
    Box Unit Qty <br />
    Audit Pass %
  </>,
];

export const activityData = [
  {
    UserId: 101,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 102,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 103,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 104,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 105,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 106,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 107,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 108,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 109,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 110,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 111,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
  {
    UserId: 112,
    Username: "Ronald Richards",
    PicklistId: "Pick-175",
    SerialCode: 12345678,
    SKUCode: 5293314357,
    Sublocation: "G22, Shell 4",
    Aisle: "Aisle 36",
    Zone: "XYZ",
    TimeStamp: "23 May 2023 10:15:08 AM",
    uri: require("../../Assets/Icons/Users/Ellipse11.png"),
    lastQty: 16,
    systemQty: 100,
  },
];

export const toteOrderWiseHeader = [
  "Tote Code",
  "Current Picklist Id",
  "Current Picklist Type",
  "CurrentUsername",
  "CurrentSublocation",
  "Current Total Qty",
  "CurrentStatus",
  "Action",
];

export const predefinedProblems_header = [
  "Id",
  "Problem",
  "Created By",
  "Modified By",
  "Created At",
  "Updated At",
  "Action",
];

export const toteData = [
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
  {
    ToteId: 5293314357,
    CurrentPicklistId: 123456789,
    CurrentPicklistType: "Single",
    CurrentUsername: "Jane Cooper",
    CurrentSublocation: "G22, Shell 4",
    CurrentTotalQty: 50,
    CurrentStatus: "Active",
    uri: require("../../Assets/Icons/Users/Ellipse13.png"),
  },
];

export const personsArray = [
  {
    id: 1,
    uri: require("../../Assets/Icons/User-Assinged/user-1.png"),
    name: "Leslie Alexander",
  },
  {
    id: 2,
    uri: require("../../Assets/Icons/User-Assinged/user-2.png"),
    name: "Theresa Webb",
  },
  {
    id: 3,
    uri: require("../../Assets/Icons/User-Assinged/user-3.png"),
    name: "Savannah Nguyen",
  },
  {
    id: 4,
    uri: require("../../Assets/Icons/User-Assinged/user-4.png"),
    name: "Devon Lane",
  },
  {
    id: 5,
    uri: require("../../Assets/Icons/User-Assinged/user-5.png"),
    name: "Bessie Cooper",
  },
  {
    id: 6,
    uri: require("../../Assets/Icons/User-Assinged/user-6.png"),
    name: "Darrell Steward",
  },
  {
    id: 7,
    uri: require("../../Assets/Icons/User-Assinged/user-7.png"),
    name: "Jane Cooper",
  },
];

export const scanned_item_list_header = [
  <>
    Handover <br /> No
  </>,
  "BagName",
  // "OrderNo",
  "AWB NO",
  <>
    Invoice <br /> Name
  </>,
  <>
    Handover <br /> Status
  </>,
  <>
    Reversal <br /> Handover Status
  </>,
  "Created",
  "CreatedBy",
  "Modified",
  "ModifiedBy",
  "Action",
];

export const CommunicationAlert_header = [
  "Username",
  "PickList Name",
  "Portal",
  "Date & Time",
  "Action",
];

export const reverse_handover_order_header = [
  <>
    Reverse <br /> Handover <br /> Name
  </>,
  <>
    Total <br /> Orders
  </>,
  <>
    Total <br /> Orders <br /> Items
  </>,
  "Date",
  <>
    Create <br /> by
  </>,
  "Action",
];

export const reverse_handover_scanned_item_list_header = [
  "Courier Name",
  "AWB Number",
  "Order Number",
  "Forward Handover Number",
  "Create By",
  "Created On",
  "Modify By",
  "Modify On",
  "Qty.",
];

export const picklist_order_type_list = [
  { id: 1, name: "Single" },
  { id: 2, name: "Multi" },
];

export const single_label_generator_heading = [
  <>
    Product <br /> pictures
  </>,
  <>
    Order <br /> number
  </>,
  <>
    Picking <br /> Sequence
  </>,
  <>
    SKU <br /> Code
  </>,
  <>
    Parent <br /> SKU <br /> Code
  </>,
  <>
    Serial <br /> Number
  </>,
  "Qty.",
  <>
    Picked <br /> By
  </>,
];

export const multi_label_generator_heading = [
  "Productpictures",
  "Picked By",
  "Picked On",
  "Tote Id",
  "SerialCode",
  "Scanstatus",
  "SKU Code",
];

export const order_details = [
  <>
    Sub Order <br /> Code
  </>,
  <>Party Sku</>,
  <>Old Sku</>,
  <>Product</>,
  <>
    Serial <br /> Code
  </>,
];

export const tote_wise_header_arr = [
  <>
    Tote <br /> Code
  </>,
  <>
    Sub <br /> Location
  </>,
  <>Qty.</>,
  <>SKU</>,
  <>Location</>,
];

export const serial_code_wise_header_arr = [
  <>
    Tote <br /> Code
  </>,
  <>
    Sub <br /> Location
  </>,
  <>SKU</>,
  <>
    Old <br /> SKU
  </>,
  <>
    Party <br /> SKU
  </>,
  <>
    Serial <br /> Code
  </>,
  <>Location</>,
  <>
    Sub Order <br /> Code
  </>,
];

export const urls = [
  {
    id: 1,
    label: "Master",
    url: process.env.REACT_APP_BASE_URI_1,
  },
  {
    id: 2,
    label: "Test",
    url: process.env.REACT_APP_BASE_URI_2,
  },
  // {
  //   id: 3,
  //   label: "Live-Test (2)",
  //   url: process.env.REACT_APP_BASE_URI_3,
  // },
  {
    id: 3,
    label: "Stagging",
    url: process.env.REACT_APP_BASE_URI_4,
  },
  {
    id: 4,
    label: "Live",
    url: process.env.REACT_APP_BASE_URI_5,
  },
];

export const MarkLost_Data_arr = [
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
  {
    location: "G221>>Shell4>>Rack No.4 Aisle4>>Zone ABC",
    product_group: {
      title: "T29_BLWH",
      img: require("../../Assets/no-image.jpg"),
    },
    requested_by: {
      title: "Rohit_101",
      img: require("../../Assets/Icons/User-Assinged/user-1.png"),
    },
    requested_on: "06-Jul-2023",
    request_count: 2,
    aging: "3 Days",
  },
];

export const ByPassOrderSummaryHeader = [
  "SKU",
  "Product Group",
  "Total Qty",
  "Picked Qty",
  "Pending Qty",
];

export const ByPassOrderSummaryDATA_ARR = [
  {
    sku: "SK2567MJL58",
    product_group: "T29_BLWH",
    total_qty: "50",
    picked_qty: "35",
    pending_qty: "15",
  },
  {
    sku: "SK2567MJL58",
    product_group: "T29_BLWH",
    total_qty: "50",
    picked_qty: "35",
    pending_qty: "15",
  },
  {
    sku: "SK2567MJL58",
    product_group: "T29_BLWH",
    total_qty: "50",
    picked_qty: "35",
    pending_qty: "15",
  },
  {
    sku: "SK2567MJL58",
    product_group: "T29_BLWH",
    total_qty: "50",
    picked_qty: "35",
    pending_qty: "15",
  },
  {
    sku: "SK2567MJL58",
    product_group: "T29_BLWH",
    total_qty: "50",
    picked_qty: "35",
    pending_qty: "15",
  },
];

export const PendingOrderHeaderARR = ["Picture", "SKU", "Qty"];

export const PendingOrderDATA_ARR = [
  {
    picture: require("../../Assets/Icons/sku.png"),
    sku: "SK2567MJL58",
    qty: "5",
  },
  {
    picture: require("../../Assets/Icons/sku.png"),
    sku: "SK2567MJL58",
    qty: "5",
  },
  {
    picture: require("../../Assets/Icons/sku.png"),
    sku: "SK2567MJL58",
    qty: "5",
  },
  {
    picture: require("../../Assets/Icons/sku.png"),
    sku: "SK2567MJL58",
    qty: "5",
  },
  {
    picture: require("../../Assets/Icons/sku.png"),
    sku: "SK2567MJL58",
    qty: "5",
  },
];

export const DeviceManagement_HeaderARR = [
  <>
    Device <br />
    Id
  </>,
  <>
    Last Logged <br />
    in User
  </>,
  <>
    Last <br />
    Operation
  </>,
  <>
    Inactive <br />
    Since
  </>,
  <>
    Last Network <br />
    Name
  </>,
  <>
    Last Homing <br />
    Time
  </>,
  <>
    Last Homing <br />
    Beacon Id
  </>,
  <>
    Last Battery <br />% Reporting
  </>,
];
