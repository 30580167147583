import React, { useCallback, useEffect } from "react";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import { customStylesSKU } from "../../Components/DropdownStyles/DropdownStyles";
import Select from "react-select";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useRef } from "react";
import InputField from "../../Components/InputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  ReverseHandoverByIdAction,
  closeHandoverBagActions,
  closeHandoverOrderActions,
  createHandoverBagActions,
  getAllHandoverBagNameActions,
  getCourierNameByIdActions,
  getCurrentBagQtyActions,
  getInvoiceListForMatchActions,
  getOrderAwbHandoverActions,
  getScanItemListActions,
  getTotalBagsScanActions,
  printLabelBagActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useData } from "../../DataContext/DataContext";
import { useNavigate } from "react-router-dom";
import {
  CLOSE_HANDOVER_BAG_RESET,
  CLOSE_HANDOVER_ORDER_RESET,
  CREATE_NEW_HANDOVER_BAG_RESET,
  GET_HANDOVER_ORDER_AWB_RESET,
} from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import DataTable from "../../Components/Table/DataTable";
import { scanned_item_list_header } from "../../DummyArray/TableArray/TableData";
import ModalContainer from "../../Components/Modal/Modal";
import { Button } from "@material-ui/core";
import Loader from "../../Components/Loader/Loader";
import qz from "qz-tray";
import QzTray from "../../Components/QZTray/QzTray";
import axios from "axios";
// import { BASE_URL } from "../../Base_URL/BaseURL";
// import secureLocalStorage from "react-secure-storage";
import MetaData from "../../Components/Helmet/Helmet";

// Added Comment
const OrderHandover = () => {
  const navigate = useNavigate();
  // let pass = secureLocalStorage.getItem("MNSEXESPS");
  // let user_name = secureLocalStorage.getItem("MXESNUSERN");

  const { handoverData, carrierData, order_Summary } = useData();
  let handover_id = handoverData && handoverData.handover_id;
  let carier_id = carrierData && carrierData.carier_id;
  let summary = order_Summary && order_Summary?.order;

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { courier_By_Id, loading } = useSelector((state) => state.courierById);
  const { Scan_item_list, loading: scan_item_loading } = useSelector(
    (state) => state.getScanItemList
  );
  const { bag_qty, loading: bagQtyLoading } = useSelector(
    (state) => state.bagQty
  );

  const { all_bags_name, loading: bagNameLoading } = useSelector(
    (state) => state.allBagsName
  );

  const { total_bags_scanned, loading: total_bags_scanned_loading } =
    useSelector((state) => state.totalBagsScan);

  const {
    create_handover,
    loading: create_handover_loading,
    isPosted,
    status,
  } = useSelector((state) => state.createHandover);

  // reverse handover state
  const { reverse_handover, loading: reverseLoading } = useSelector(
    (state) => state.reverseHandover
  );

  // close bag state
  const {
    close_bag,
    loading: closeBagLoading,
    // isClosed,
  } = useSelector((state) => state.closeBag);

  const alert = useAlert();

  const inputRef = useRef(null);

  let bagsName = all_bags_name && all_bags_name?.data;

  const [bagName, setBag_name] = useState("");
  const [bag_id, setbag_id] = useState("");

  const handleBagName = (option) => {
    // console.log(option.id);
    // console.log("Handle Bag", option);
    dispatch(getCurrentBagQtyActions("get_bag_qty", token, option.id));
    setBag_name(option.value);
    setbag_id(option.id);
  };

  const handleInput = () => {
    if (!bagsName && inputRef.current && inputRef.current.disabled) {
      alert.error("Please Add Bag First...");
    } else if (bag_id === "" && inputRef.current && inputRef.current.disabled) {
      alert.error("Please Select Bag Name...");
    }
  };

  useEffect(() => {
    if (carier_id && carier_id !== null) {
      dispatch(getCourierNameByIdActions("get_carier_by_id", token, carier_id));
    }
    if (handover_id && handover_id !== null) {
      dispatch(
        getScanItemListActions("get_scanned_items_list", token, handover_id)
      );
    }
  }, [dispatch, carier_id, token, handover_id]);

  let courier_name =
    courier_By_Id &&
    courier_By_Id?.data?.length > 0 &&
    courier_By_Id?.data[0]?.name;

  // let total_order_scan =
  //   Scan_item_list &&
  //   Scan_item_list?.data &&
  //   Scan_item_list?.data?.scanned_item_list;

  const handleBagCreation = useCallback(() => {
    if (handover_id && handover_id !== "") {
      dispatch(
        createHandoverBagActions("create_new_handover_bag", token, handover_id)
      );
    } else {
      navigate("/delivery-partner");
      alert.error("It Looks Like Something Went Wrong...");
    }
  }, [dispatch, handover_id, token, alert, navigate]);

  useEffect(() => {
    if (
      create_handover &&
      create_handover?.status === 1 &&
      handover_id &&
      handover_id !== null
    ) {
      dispatch(
        getAllHandoverBagNameActions(
          "get_all_handover_bag_list",
          token,
          handover_id
        )
      );
    }
    if (summary && summary) {
      dispatch(
        getAllHandoverBagNameActions(
          "get_all_handover_bag_list",
          token,
          handover_id
        )
      );
    }
    // eslint-disable-next-line
  }, [create_handover, handover_id, dispatch, summary]);

  // useEffect(() => {
  //   if (bag_id && bag_id !== "") {
  //     dispatch(getCurrentBagQtyActions("get_bag_qty", token, bag_id));
  //   }
  // }, [dispatch, token, bag_id]);

  // const [bag_qtys, setBag_qtys] = useState(0);

  // useEffect(() => {
  //   if (bag_qty && bag_qty?.data?.count) {
  //     setBag_qtys(bag_qty && bag_qty?.data?.count);
  //   }
  // }, [bag_qty]);

  const showAlert = useCallback(() => {
    if (status && status === 1 && isPosted) {
      alert.success("Bag Created SuccessFully!");
      dispatch({
        type: CREATE_NEW_HANDOVER_BAG_RESET,
      });
    }
  }, [dispatch, status, isPosted, alert]);

  useEffect(() => {
    showAlert();
  }, [showAlert]);

  useEffect(() => {
    if (
      !handover_id &&
      handover_id === null &&
      !carier_id &&
      carier_id === null
    ) {
      navigate("/delivery-partner");
    }
  }, [handover_id, carier_id, navigate]);

  useEffect(() => {
    if (handover_id && handover_id !== "") {
      dispatch(
        getTotalBagsScanActions("get_total_bags_scanned", token, handover_id)
      );
    }
  }, [dispatch, handover_id, token]);

  let total_bags_scan =
    total_bags_scanned && total_bags_scanned?.data?.total_bags_scanned;

  const [scanned_code, setscanned_code] = useState("");

  const { order_awb_no, loading: scan_load } = useSelector(
    (state) => state.orderAwb
  );

  const [isHit, setIshit] = useState(false);

  const handleScanedCode = (val) => {
    setIsIndex(null);
    setscanned_code(val);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIshit(true);
      if (bag_id !== "" && carier_id !== "") {
        dispatch(
          getOrderAwbHandoverActions(
            "handover_order_awb_no",
            token,
            bag_id,
            carier_id,
            scanned_code,
            handover_id,
            bagName
          )
        );
      }
    }
  };

  // console.log("Bag_Name: ", bagName);

  const [scanned_item_list, setScanned_item_list] = useState([]);

  const fetchScannedItemList = useCallback(() => {
    Scan_item_list &&
      setScanned_item_list(Scan_item_list?.data?.scanned_item_list);
  }, [Scan_item_list]);

  useEffect(() => {
    fetchScannedItemList();
  }, [fetchScannedItemList]);

  const [selectedPrinter, setSelectedPrinter] = useState("");

  const sendDatafromChild = (data) => {
    setSelectedPrinter(data);
    localStorage.setItem("Printer", data);
  };

  const [isIndex, setIsIndex] = useState(null);
  // const [isScanned, setIsScanned] = useState();
  // const [isMessage, setIsMessage] = useState("");
  // const handleModal = (index, scanned, msg) => {
  //   setIsIndex(index);
  //   setIsScanned(scanned);
  //   setIsMessage(msg);
  // };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          printer={printer}
          sendDataFromParent={sendDatafromChild}
        />
      );
    }
    return null;
  };

  // console.log("AWB STA: ", order_awb_no);

  useEffect(() => {
    // if (
    //   order_awb_no &&
    //   order_awb_no?.data?.status === 1 &&
    //   order_awb_no &&
    //   order_awb_no?.data?.record?.message === ""
    // ) {
    //   alert.success("Order Handover Successfully");
    //   // if (handover_id && handover_id !== null) {
    //   dispatch(
    //     getScanItemListActions("get_scanned_items_list", token, handover_id)
    //   );
    //   dispatch(getCurrentBagQtyActions("get_bag_qty", token, bag_id));
    //   // }
    //   dispatch({
    //     type: GET_HANDOVER_ORDER_AWB_RESET,
    //   });
    // }
    if (
      order_awb_no &&
      order_awb_no?.status === 1 &&
      order_awb_no &&
      order_awb_no?.msg !== "" &&
      isHit
    ) {
      // handleModal(3, 0, order_awb_no && order_awb_no?.msg);
      alert.success(order_awb_no && order_awb_no?.msg);
      setscanned_code("");
      // alert.success(order_awb_no && order_awb_no?.data?.record?.message);
      // if (handover_id && handover_id !== null) {
      dispatch(
        getScanItemListActions("get_scanned_items_list", token, handover_id)
      );
      dispatch(getCurrentBagQtyActions("get_bag_qty", token, bag_id));
      // }
      dispatch({
        type: GET_HANDOVER_ORDER_AWB_RESET,
      });
      setIshit(false);
    }
    if (order_awb_no && order_awb_no?.status === 0 && isHit) {
      // handleModal(3, 1, order_awb_no && order_awb_no?.msg);
      alert.error(order_awb_no && order_awb_no?.msg);
      // alert.error(order_awb_no && order_awb_no?.data?.record?.message);
      setScanned_item_list([]);
      setscanned_code("");
      dispatch({
        type: GET_HANDOVER_ORDER_AWB_RESET,
      });
      setIshit(false);
    }
    // if (order_awb_no && order_awb_no?.status === 0 && isHit) {
    //   handleModal(3, 1, order_awb_no && order_awb_no?.msg);
    //   // alert.error(order_awb_no && order_awb_no?.data?.record?.message);
    //   setScanned_item_list([]);
    //   dispatch({
    //     type: GET_HANDOVER_ORDER_AWB_RESET,
    //   });
    //   setIshit(false);
    // }
  }, [
    order_awb_no,
    alert,
    dispatch,
    handover_id,
    token,
    bag_id,
    isHit,
    bagName,
  ]);

  const [fetchInvoice_loading] = useState(false);
  // let url = BASE_URL && BASE_URL?.replace(/\/[^/]*$/, "");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setFetch_Invoice_loading(true);
  //     try {
  //       const batchBundleSerialCodes =
  //         order_awb_no &&
  //         order_awb_no?.data?.bbsc_data?.batch_bundle_serial_codes;

  //       for (const code of batchBundleSerialCodes) {
  //         const apiData = {
  //           data: {
  //             box_no: bagName,
  //             code,
  //             inventory_id:
  //               order_awb_no && order_awb_no?.data?.bbsc_data?.inventory_id,
  //             sub_status: 3,
  //           },
  //           service_name: "inventory_scanable_outgoing_save",
  //           password: pass,
  //           username: user_name,
  //         };

  //         const response = await axios.post(`${url}/web-api`, apiData);

  //         if (
  //           response &&
  //           response?.data?.status === 1 &&
  //           response?.data?.msg === ""
  //         ) {
  //           alert.success("Serial Code Out Success");
  //         } else if (
  //           response &&
  //           response?.data?.status === 1 &&
  //           response?.data?.msg !== ""
  //         ) {
  //           alert.success(response && response?.data?.msg);
  //         } else if (response?.data?.status === 0) {
  //           alert.error(response && response?.data?.msg);
  //         }
  //         setFetch_Invoice_loading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setFetch_Invoice_loading(false);
  //     }
  //   };

  //   if (order_awb_no && order_awb_no?.status === 1 && isHit === true) {
  //     fetchData();
  //   }
  // }, [bagName, order_awb_no, pass, user_name, isHit, url, alert]);

  // console.log(order_awb_no, "Sss");

  useEffect(() => {
    if (scanned_code === "") {
      setScanned_item_list([]);
    }
  }, [scanned_code]);

  const [isReversed, setIsReversed] = useState(false);

  const handlehandoverid = (handovered_item_id) => {
    setIsReversed(true);
    dispatch(
      ReverseHandoverByIdAction(
        "reverse_handover_item",
        token,
        handovered_item_id
      )
    );
    // setTimeout(() => {

    // }, 500);
  };

  useEffect(() => {
    if (reverse_handover && reverse_handover?.status === 1 && isReversed) {
      alert.success("Reversed Successfully...");
      setIsReversed(false);
      dispatch(getCurrentBagQtyActions("get_bag_qty", token, bag_id));
      dispatch(
        getScanItemListActions("get_scanned_items_list", token, handover_id)
      );
    }
    if (reverse_handover && reverse_handover?.status === 0 && isReversed) {
      alert.error(reverse_handover && reverse_handover?.msg);
      setIsReversed(false);
    }
  }, [
    reverse_handover,
    alert,
    isReversed,
    token,
    bag_id,
    dispatch,
    handover_id,
  ]);

  // const [printFirst, setPrintFirst] = useState(true);

  const [close_bags, setClose_bags] = useState(false);

  // label bag state
  const { label_bag } = useSelector((state) => state.labelBag);

  const handleCloseBag = async () => {
    // console.log("Props Data: ", i);
    // if (selectedPrinter !== "") {
    if (bag_id !== "") {
      setClose_bags(true);
      await dispatch(
        closeHandoverBagActions("close_handover_bag", token, bag_id)
      );
      dispatch(printLabelBagActions("print_bag_lable_pdf", token, bag_id));
    } else if (!bag_id) {
      alert.error("Please Select Bag Name...");
    } else {
      return;
    }
    // }
  };

  useEffect(() => {
    if (close_bag && close_bag?.status === 1 && close_bags) {
      setClose_bags(false);
      if (handover_id && handover_id !== "") {
        dispatch(
          getAllHandoverBagNameActions(
            "get_all_handover_bag_list",
            token,
            handover_id
          )
        );
        dispatch(
          getTotalBagsScanActions("get_total_bags_scanned", token, handover_id)
        );
      } else {
        alert.error("Something Went Wrong...");
      }
      alert.success("Bag Closed Successfully...");
      dispatch({
        type: CLOSE_HANDOVER_BAG_RESET,
      });
      setbag_id("");
    } else if (close_bag && close_bag?.status === 0 && close_bags) {
      setClose_bags(false);
      alert.error(close_bag && close_bag?.msg);
    }
  }, [dispatch, token, handover_id, close_bags, alert, close_bag]);

  const {
    close_handover_order,
    isRedirect,
    loading: closeHandover_loading,
  } = useSelector((state) => state.closeHandoverOrder);

  const HandleCloseHandover = () => {
    if (handover_id && handover_id !== "") {
      dispatch(closeHandoverOrderActions("close_handover", token, handover_id));
    } else {
      alert.error("Something Went Wrong...");
    }
  };

  useEffect(() => {
    if (close_handover_order && close_handover_order?.status === 0) {
      alert.error(close_handover_order && close_handover_order?.msg);
    } else if (
      close_handover_order &&
      close_handover_order?.status === 1 &&
      isRedirect
    ) {
      alert.success("Handover Closed Successfully...");
      navigate("/order-summary");
      dispatch({
        type: CLOSE_HANDOVER_ORDER_RESET,
      });
    }
  }, [close_handover_order, dispatch, navigate, alert, isRedirect]);

  const [printer, setPrinter] = useState([]);

  const handleData = () => {
    if (qz.websocket.isActive()) {
      return qz.websocket.disconnect().then(() => {
        qz.websocket
          .connect()
          .then(() => {
            return qz.printers.find();
          })
          .then((printers) => {
            if (!printer.includes(printers)) {
              setPrinter(printers);
            }
          })
          .then(() => {
            setIsIndex(7);
          })
          .catch((err) => {
            alert.error(err.message);
          });
      });
    }
  };

  useEffect(() => {
    qz.security.setCertificatePromise(function (resolve, reject) {
      // Preferred method - from server
      fetch("digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      })
        .then(function (response) {
          if (response.ok) {
            response.text().then(resolve);
          } else {
            response.text().then(reject);
          }
        })
        .catch(reject);

      // Alternate method 1 - anonymous
      // resolve();  // remove this line in live environment

      // Alternate method 2 - direct
      resolve(
        "-----BEGIN CERTIFICATE-----\n" +
          "MIID+TCCAuGgAwIBAgIUFJx1QkZbDOHgHa+ec24vw3o1txIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYoxCzAJBgNVBAYTAklOMQ8wDQYDVQQIDAZQdW5qYWIxETAPBgNVBAcMCEx1\n" +
          "ZGhpYW5hMQ8wDQYDVQQKDAZTZWVrZXgxEzARBgNVBAsMClNldmVucm9ja3MxDDAK\n" +
          "BgNVBAMMA0dydjEjMCEGCSqGSIb3DQEJARYUZ3J2LndlYmRldkBnbWFpbC5jb20w\n" +
          "IBcNMjMwOTIzMDUwNjMxWhgPMjA1NTAzMTgwNTA2MzFaMIGKMQswCQYDVQQGEwJJ\n" +
          "TjEPMA0GA1UECAwGUHVuamFiMREwDwYDVQQHDAhMdWRoaWFuYTEPMA0GA1UECgwG\n" +
          "U2Vla2V4MRMwEQYDVQQLDApTZXZlbnJvY2tzMQwwCgYDVQQDDANHcnYxIzAhBgkq\n" +
          "hkiG9w0BCQEWFGdydi53ZWJkZXZAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
          "AAOCAQ8AMIIBCgKCAQEAjit0o5WvhQInxccqj7/Xtqgroq6X61gWI/gSQRfpbdMM\n" +
          "6U1MqGW8qzzR5bX+VBURXcdEFokpcxY3/oz17VjPQ0D/bMensZ+EqQubQqmM56zS\n" +
          "BdMlrITCoSbT+cNvA0gB4U4d8UQ58vzZHY0zj6lNFjCZuDfHO+DG+rD9EwjngZzU\n" +
          "+rS8jOb2y7xMu5tuncku/EeEESv1LndGhAmRAfidOUDAL4zbTdLQvz9zP8xcGzas\n" +
          "UhsTBfQ9r4e1b2LKgyIm08oY/eg2qjGWyfPfbrXrzsWpnyJwdHldHMUAAnvdcCxH\n" +
          "bcXSl1upy1wAq1WO4OpPkRlnRLqC09qGWnRAyU4fUQIDAQABo1MwUTAdBgNVHQ4E\n" +
          "FgQU/AtLKKkV14bzAAu99sUVO64c3DYwHwYDVR0jBBgwFoAU/AtLKKkV14bzAAu9\n" +
          "9sUVO64c3DYwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAAA8B\n" +
          "aLZoOZ1w8SVNVDlpSSgq4+Py917Wp1Vp7D6GN6QL0PIO0Y+k6osFXQuWCNzjjSYp\n" +
          "BO7xnr4eHxSlJ9xh4s8d5RlVhCG+F8oRNeIAFsUjj4RLy/kvJWnJI5jcmjZCKhsS\n" +
          "zKGAiki6D4vhePGU+kzezOubGN0YJC5pVpwvTdJLXdiu77IwfTYSMRuxOu9wvhhk\n" +
          "oq/HKpBIYDr5b6frej6H9/o4mCN92qZulhF0bsYhDkvE/4S9cwDaX0i9bV7Ojt17\n" +
          "FCabjHwxjgycLavqJiXCFyfRoF8CyckAVzqMyuVcCP8/3wCM603KB41+LP+c6Hds\n" +
          "D1DnIqq9EEUabJvMyQ==\n" +
          "-----END CERTIFICATE-----\n"
      );
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        // Preferred method - from server
        axios
          .get(
            "https://erp.sevenrocks.in/app/webroot/qz-local/sign-message.php",
            {
              params: { request: toSign },
              headers: { "Content-Type": "text/plain" },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });

        // Alternate method - unsigned
        // resolve(); // remove this line in the live environment
      };
    });
  }, []);

  // signed certificate for qz tray

  useEffect(() => {
    // Check if a connection is already open
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(() => {
        return qz.printers.find().catch((err) => alert.error(err.message));
      });
    } else {
      // Handle the case when a connection is already open
      // You can choose to do nothing, show a message, or perform other actions here
      // alert.error("A connection with QZ Tray is already open.");
    }
  }, [alert]);

  const handlePdf = () => {};

  // console.log("Total Bags Scan: ", bag_qty);

  // const { label_bag, loading: label_loading } = useSelector(
  //   (state) => state.labelBag
  // );

  // const [giveSignal, setGiveSignal] = useState(false);

  // const handleLabelPrint = () => {
  //   setGiveSignal(true);
  //   dispatch(printLabelBagActions("print_bag_lable_pdf", token, bag_id));
  // };

  // useEffect(() => {
  //   if (label_bag && label_bag?.status === 1 && giveSignal) {
  //     setGiveSignal(false);
  //     // alert.success(label_bag && label_bag?.msg);
  //   } else if (label_bag && label_bag?.status === 0 && giveSignal) {
  //     setGiveSignal(false);
  //     alert.error(label_bag && label_bag?.msg);
  //   }
  // }, [label_bag, alert, giveSignal]);

  // console.log("Label Bag: ", label_bag);

  // let label_print = label_bag && label_bag?.data;

  // Invoice state
  const { invoice_list_handover, loading: invoice_loading } = useSelector(
    (state) => state.invoice_data_list
  );

  useEffect(() => {
    dispatch(
      getInvoiceListForMatchActions(
        "get_handover_inventory",
        token,
        handover_id
      )
    );
  }, [dispatch, token, handover_id]);

  const findInventoryName = (id) => {
    const foundObject =
      invoice_list_handover &&
      invoice_list_handover?.data?.length > 0 &&
      invoice_list_handover?.data.find((obj) => obj.id === id);
    return foundObject ? foundObject.name : "";
  };

  const updateScanned_list =
    scanned_item_list &&
    scanned_item_list?.map((i) => ({
      ...i,
      inventory_name: findInventoryName(i?.ScannedHandoverOrder?.inventory_id),
    }));

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData
        title={`${
          loading
            ? "Loading..."
            : courier_name && courier_name + " - Order Handover"
        }`}
      />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <Headline
            bagLoading={create_handover_loading}
            addBag={handleBagCreation}
            title="Order Handover"
            hide={true}
            newContainer={true}
            newClass={true}
            closeBag={handleCloseBag}
            closeBag_loading={closeBagLoading}
            handleData={handleData}
            selectedPrinter={selectedPrinter}
            bag_id={bag_id}
            closePrint={label_bag && label_bag?.data}
          />
          {/* Tab Module */}
          <div className={`${fetchInvoice_loading && "tab-module-flex-row"}`}>
            <div className="Tab-module">
              <TabModule ind={1} single={true} />
            </div>
            {fetchInvoice_loading && (
              <div className="please-wait">
                <p>Please Wait...</p>
                <Loader invoice={true} />
              </div>
            )}
          </div>
          <div className="summary-box">
            {loading && scan_item_loading ? (
              <LoaderAlone />
            ) : (
              <div className="grid-delivery-partner-info">
                <div className="date" onClick={handleInput}>
                  <label htmlFor="order_number">AWB Number</label>
                  <input
                    disabled={bag_id === "" || !bagsName ? true : false}
                    type="text"
                    id="order_number"
                    ref={inputRef}
                    value={scanned_code}
                    onChange={(e) => handleScanedCode(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div>
                  <label htmlFor="courierName">Bag Name</label>
                  <Select
                    noOptionsMessage={() => "Please Add Bag First..."}
                    placeholder={
                      bagNameLoading
                        ? "Loading..."
                        : bagName
                        ? bagName
                        : "Bag Name"
                    }
                    options={
                      bagsName &&
                      bagsName.map((option) => ({
                        value: option.name,
                        label: option.name,
                        id: option.id,
                      }))
                    }
                    className="select"
                    id="courierName"
                    value={bagName}
                    styles={customStylesSKU}
                    onChange={(selectedOption) => handleBagName(selectedOption)}
                  />
                </div>
                <div>
                  <InputField
                    darked={true}
                    label="Courier Name"
                    value={courier_name && courier_name}
                  />
                </div>
                <div>
                  <InputField
                    darked={true}
                    handover={true}
                    label="Total Bags Scan"
                    loading={total_bags_scanned_loading}
                    value={total_bags_scan}
                  />
                </div>
                <div className="date">
                  <label htmlFor="current_bag_qty">Current Bag Qty</label>
                  <input
                    placeholder={
                      bagQtyLoading ? "Loading..." : "Please Select Bag Name"
                    }
                    type="text"
                    readOnly
                    className="bg-dark"
                    id="current_bag_qty"
                    value={bag_qty && bag_qty?.count}
                  />
                </div>
                <div>
                  <InputField
                    loading={scan_item_loading}
                    darked={true}
                    // handover={true}
                    label="Total Order Scan"
                    value={scanned_item_list && scanned_item_list?.length}
                  />
                </div>
              </div>
            )}
            <div className="button-label-grid">
              <Button
                disabled={closeHandover_loading ? true : false}
                onClick={HandleCloseHandover}
              >
                {closeHandover_loading ? <Loader /> : "Close Handover"}
              </Button>
              <QzTray
                selectedPrinter={selectedPrinter}
                btnTitle="Download Bag Manifest"
                handlePdf={handlePdf}
                // multi_pdf={multi_label_data && multi_label_data.invoice}
                // serial_code={serial_code}
                isok={true}
                handover_id={handover_id}
              />
              {/* <Button>Download Bag Manifest</Button> */}
            </div>
          </div>

          {/* table */}

          {scan_item_loading || scan_load || invoice_loading ? (
            <LoaderAlone />
          ) : (
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                <p className="result-out">
                  {/* *Result {page} in {pickerMgmt && pickerMgmt?.total_pages} */}
                </p>
                <DataTable
                  scannedItems={updateScanned_list && updateScanned_list}
                  header={scanned_item_list_header}
                  IdData={handlehandoverid}
                  loading_reverse={reverseLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default OrderHandover;
